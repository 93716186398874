import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Modal,
    OverlayTrigger,
    Spinner,
    Tooltip
} from "react-bootstrap";

import Loading from "../Loading";
import TagPill from "../tagPill";
import numberFormatter from "../formatters/NumberFormatter";

function AddProductModalKeyboardShortcutsHint() {
    return (
        <p className="d-none d-lg-block">
            <b>Tip!</b> Gebruik
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="address">Pijltje omhoog</Tooltip>
            }>
                <kbd className="mx-2">
                    <i className="fas fa-arrow-alt-up"/>
                </kbd>
            </OverlayTrigger>
            en
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="address">Pijltje omlaag</Tooltip>
            }>
                <kbd className="mx-2">
                    <i className="fas fa-arrow-alt-down"/>
                </kbd>
            </OverlayTrigger>
            om een product te selecteren en
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="address">Enter</Tooltip>
            }>
                <kbd className="mx-2">
                    <i className="fas fa-level-down fa-rotate-90 fa-fw"/>
                </kbd>
            </OverlayTrigger>
            om op te slaan.
        </p>
    )
}

class BaseAddProductModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: null,
            filteredProducts: null,
            saving: false,
            error: null,
            errorSave: null,

            searchValue: "",
            selectedProduct: null,
            amount: ""
        }
        this.onShow = this.onShow.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onProductSelected = this.onProductSelected.bind(this);
        this.onSearchValueChange = this.onSearchValueChange.bind(this);
        this.onAmountChange = this.onAmountChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDidPressEnter = this.onDidPressEnter.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        this.searchInput = React.createRef();
        this.amountInput = React.createRef();
        this.activeProductListItem = React.createRef();
    }

    abstractSave(productId, amount, onComplete, onError) {
        console.error("abstractSave from BaseAddProductModal not implemented!");
    }

    abstractGetTitle() {
        console.error("abstractGetTitle from BaseAddProductModal not implemented!");
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((!prevProps.show && this.props.show) || (!prevState.products && this.state.products)) {
            setTimeout(() => {
                if(this.searchInput.current) {
                    this.searchInput.current.focus();
                }
            }, 100);
        } else if(prevState.selectedProduct !== this.state.selectedProduct) {
            if(this.amountInput.current) {
                this.amountInput.current.select();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);
    }

    onShow() {
        this.setState({ saving: false, selectedProduct: null, searchValue: "", amount: "" });
        if(!this.state.products) {
            this.getProducts();
        } else {
            this.setState((prevState) => {
                return { filteredProducts: this.filterProducts(prevState.products, null) }
            })
        }
        document.addEventListener("keydown", this.onKeyDown);
    }

    onHide() {
        this.props.handleClose();
        document.removeEventListener("keydown", this.onKeyDown);
    }

    onKeyDown(event) {
        if(event.keyCode === 38) { // ArrowDown
            event.preventDefault();
            this.handleArrowKeyPress("up");
        } else if(event.keyCode === 40) { // ArrowDown
            event.preventDefault();
            this.handleArrowKeyPress("down");
        }
    }

    handleArrowKeyPress(direction) {
        this.setState((prevState) => {
            const products = prevState.filteredProducts;
            if(products.length === 0) {
                return {};
            }
            const selectedProductId = prevState.selectedProduct;
            if(selectedProductId == null && direction === "down") {
                if(direction === "down" && this.searchInput.current && this.searchInput.current === document.activeElement) {
                    this.searchInput.current.blur();
                    return { selectedProduct: products[0].id };
                }
                return {};
            }
            const index = products.findIndex((findProduct) => findProduct.id === selectedProductId);

            if(direction === "up") {
                if(index <= 0) {
                    if(this.searchInput.current) {
                        this.searchInput.current.focus();
                    }
                    return { selectedProduct: null };
                }
                const newSelectedProduct = products[index - 1];
                return { selectedProduct: newSelectedProduct.id };
            } else if(direction === "down") {
                if(index + 1 >= products.length) {
                    return {};
                }
                const newSelectedProduct = products[index + 1];
                return { selectedProduct: newSelectedProduct.id };
            }
        }, () => {
            if(this.activeProductListItem.current) {
                this.activeProductListItem.current.scrollIntoView({ block: "center" });
            }
        });
    }

    onDidPressEnter(event) {
        if(event.keyCode === 13) {
            this.onSave(event);
        }
    }

    getProducts() {
        this.setState({ products: null, filteredProducts: null });
        axios.get("/getProducts")
            .then((response) => {
                if(response.data.valid) {
                    const products = response.data.data;
                    this.setState({ products, filteredProducts: this.filterProducts(products, this.state.searchValue) });
                } else {
                    this.setState({ error: "Er ging iets fout bij het laden van de producten. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het laden van de producten." });
                console.error(error);
            });
    }

    onSave(event) {
        if(event) {
            event.preventDefault();
        }
        if(!this.state.selectedProduct) {
            this.setState({ errorSave: "Selecteer een product." });
            return;
        }
        if(!parseInt(this.state.amount)) {
            this.setState({ errorSave: "Ongeldig aantal. Vul een nummer zonder decimalen in." });
            return;
        }
        this.setState({ saving: true, errorSave: null });
        this.abstractSave(this.state.selectedProduct, parseInt(this.state.amount), () => {
            this.props.handleClose();
        }, (error) => {
            this.setState({ saving: false, errorSave: error });
        })
    }

    filterProducts(products, searchValue) {
        if(!searchValue || searchValue.trim().length === 0) {
            return products;
        }
        const searchValueParts = searchValue.trim().toLowerCase().split(" ");
        return products.filter((product) => {
            for(const part of searchValueParts) {
                if(!this.matchProduct(product, part)) {
                    return false;
                }
            }
            return true;
        });
    }

    matchProduct(product, filter) {
        if(product.name.toLowerCase().includes(filter)) {
            return true;
        }
        if(product.type.name.toLowerCase().includes(filter)) {
            return true;
        }
        if(product.brand.name.toLowerCase().includes(filter)) {
            return true;
        }
        return false;
    }

    onProductSelected(productId) {
        this.setState({ selectedProduct: productId });
    }

    onSearchValueChange(event) {
        const searchValue = event.target.value;
        this.setState({ searchValue, filteredProducts: this.filterProducts(this.state.products, searchValue) });
    }

    onAmountChange(event) {
        this.setState({ amount: event.target.value });
    }

    doesProductExistAlready(product) {
        return this.props.existingProducts.find((existingProduct) => existingProduct.id === product.id) !== undefined;
    }

    getSelectedProduct() {
        return this.state.products ? this.state.products.find((product) => product.id === this.state.selectedProduct) : null;
    }

    canSubmit() {
        const selectedProduct = this.getSelectedProduct();
        return selectedProduct && this.state.amount.length > 0 && !this.doesProductExistAlready(selectedProduct);
    }

    render() {
        const selectedProduct = this.getSelectedProduct();
        return (
            <Modal show={ this.props.show } onHide={ this.onHide } onShow={ this.onShow } size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{ this.abstractGetTitle() }</Modal.Title>
                </Modal.Header>
                <form className="mb-0" onSubmit={ this.onSave }>
                    <Modal.Body>
                        { this.state.error ? (
                            <Alert variant="danger">{ this.state.error }</Alert>
                        ) : this.state.products === null ? (
                            <Loading/>
                        ) : (
                            <React.Fragment>
                                { this.state.errorSave && (
                                    <Alert variant="danger">{ this.state.errorSave }</Alert>
                                )}
                                <div className="row">
                                    <div className="col-md-5">
                                        <Form.Control
                                            ref={ this.searchInput }
                                            type="search"
                                            value={ this.state.searchValue }
                                            onChange={ this.onSearchValueChange }
                                            className="mb-3"
                                            placeholder="Zoeken"
                                            disabled={ this.state.saving }
                                        />
                                        <ul className="list-group list-group-border list-group-scroll" style={{
                                            height: "500px"
                                        }}>
                                            { this.state.filteredProducts.map((product) => {
                                                const active = product.id === this.state.selectedProduct
                                                let listGroupItemClassNames = ["list-group-item", "list-group-item-hover", "pointer-cursor"];
                                                if(active) {
                                                    listGroupItemClassNames.push("active");
                                                }
                                                return (
                                                    <li
                                                        key={ product.id }
                                                        className={ listGroupItemClassNames.join(" ") }
                                                        onClick={ this.onProductSelected.bind(this, product.id) }
                                                        ref={ active ? this.activeProductListItem : undefined }
                                                    >
                                                        <span className="float-right ml-3">
                                                            { active ? (
                                                                <TagPill variant="light">{ product.type.name }</TagPill>
                                                            ) : (
                                                                <TagPill color={ product.type.color }>{ product.type.name }</TagPill>
                                                            )}
                                                        </span>
                                                        { product.brand.name + " " + product.name }
                                                    </li>
                                                )
                                            } )}
                                            { this.state.filteredProducts.length === 0 && (
                                                <li className="list-group-item text-center d-flex justify-content-center text-muted flex-column h-100">
                                                    <h4><i className="fas fa-search"/></h4>
                                                    <p>
                                                        Geen producten die matchen met je zoekopdracht.
                                                    </p>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="col">
                                        { !selectedProduct ? (
                                            <div className="d-flex flex-column h-100 text-center text-muted">
                                                <div className="flex-grow-1 d-flex flex-column justify-content-center">
                                                    <h3><i className="fas fa-warehouse"/></h3>
                                                    <h5>Selecteer een product</h5>
                                                </div>
                                                <div>
                                                    <AddProductModalKeyboardShortcutsHint/>
                                                </div>
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                <h4>
                                                    { selectedProduct.brand.name + " " + selectedProduct.name }
                                                    <small className="ml-2">
                                                        <TagPill color={ selectedProduct.type.color }>{ selectedProduct.type.name }</TagPill>
                                                    </small>
                                                </h4>
                                                <p>Voorradig: { numberFormatter({ number: selectedProduct.amount }) }</p>
                                                { this.doesProductExistAlready(selectedProduct) ? (
                                                    <Alert variant="warning">Product is al toegevoegd aan installatie.</Alert>
                                                ) : (
                                                    <Form.Group controlId="amount">
                                                        <Form.Label>Aantal</Form.Label>
                                                        <Form.Control
                                                            ref={ this.amountInput }
                                                            type="number"
                                                            value={ this.state.amount }
                                                            onChange={ this.onAmountChange }
                                                            disabled={ this.state.saving }
                                                            onKeyUp={ this.onDidPressEnter }
                                                        />
                                                    </Form.Group>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        ) }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.saving }>
                            Annuleer
                        </Button>
                        <Button variant="primary" onClick={ this.onSave } disabled={ this.state.saving || !this.canSubmit() }>
                            { this.state.saving && (
                                <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                            )}
                            Opslaan
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

}

export default BaseAddProductModal;
