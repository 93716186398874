import {
    useEffect
} from "react";
import {
    useHistory
} from "react-router-dom";
import ReactGA from "react-ga";

const useTracking = (
    trackingIds
) => {
    const { listen } = useHistory()

    useEffect(() => {
        return listen((location) => {
            if (!trackingIds) {
                console.log("Tracking not enabled, as `trackingIds` was not given.");
                return;
            }
            ReactGA.pageview(location.pathname, trackingIds);
        })
    }, [trackingIds, listen])
}

export default useTracking;
