function getCenterCoordinate(coordinates) {
    let minLat = null;
    let maxLat = null;
    let minLong = null;
    let maxLong = null;

    if(coordinates) {
        for(const coordinate of coordinates) {
            const latitude = coordinate.latitude;
            const longitude = coordinate.longitude;
            if(!latitude || !longitude) {
                continue;
            }
            if(!minLat || latitude < minLat) {
                minLat = latitude;
            }
            if(!maxLat || latitude > maxLat) {
                maxLat = latitude;
            }
            if(!minLong || longitude < minLong) {
                minLong = longitude;
            }
            if(!maxLong || longitude > maxLong) {
                maxLong = longitude;
            }
        }
    }

    if(!minLat || !maxLat || !minLong || !maxLong) {
        return {
            centerLatitude: null, centerLongitude: null,
            latitudeSpan: null, longitudeSpan: null
        };
    }

    const centerLatitude = (minLat + maxLat) / 2;
    const centerLongitude = (minLong + maxLong) / 2;
    const latitudeSpan = coordinates.length === 1 ? 0.007 : Math.abs(maxLat - minLat) * 1.3;
    const longitudeSpan = coordinates.length === 1 ? 0.007 : Math.abs(maxLong - minLong) * 1.3;
    return {
        centerLatitude, centerLongitude,
        latitudeSpan, longitudeSpan
    };
}

export default getCenterCoordinate;
