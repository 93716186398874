import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

class RestockOrderDetailDeliverBulkProductModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null
        }
        this.onSubmit = this.bulkMarkAsDelivered.bind(this);
        this.onShow = this.onShow.bind(this);
    }

    onShow() {
        this.setState({ error: null, loading: false });
    }

    bulkMarkAsDelivered() {
        this.setState({ loading: true, error: null });
        axios.post("/setRestockOrderProductsAsDelivered", {
            restockOrderId: this.props.restockOrderId
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.onDelivered(response.data.expected, response.data.delivered);
                    this.props.handleClose();
                } else {
                    this.setState({ loading: false, error: "Er ging iets fout bij het als afgeleverd markeren. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ loading: false, error: "Er ging iets fout bij het als afgeleverd markeren." });
                console.error(error);
            });
    }

    render() {
        return (
            <React.Fragment>
                <Modal size="lg" show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                    <Modal.Header closeButton>
                        <Modal.Title>Alles als afgeleverd markeren</Modal.Title>
                    </Modal.Header>
                    <form className="mb-0">
                        <Modal.Body>
                            { this.state.error !== null && (
                                <Alert variant="danger">{ this.state.error }</Alert>
                            )}
                            <p className="mb-0">
                                Weet je zeker dat je alle verwachte producten wilt markeren als afgeleverd?
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                                Annuleer
                            </Button>
                            <Button variant="primary" onClick={ this.onSubmit } disabled={ this.state.loading }>
                                { this.state.loading && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Alles afgeleverd markeren
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
        )
    }
}

export default RestockOrderDetailDeliverBulkProductModal;
