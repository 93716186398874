import React from "react";

import ChartCard from "../components/ChartCard";

import ReminderCountPerUserChart from "../charts/ReminderCountPerUserChart";
import RemindersCreatedCountChart from "../charts/RemindersCreatedCountChart";
import RemindersCreatedCountPerUserChart from "../charts/RemindersCreatedCountPerUserChart";
import RemindersSolvedCountPerUserChart from "../charts/RemindersSolvedCountPerUserChart";

function ReminderStatistics({ startDate, endDate }) {
    return (
        <React.Fragment>
            <ChartCard title="Aantal herinneringen toegewezen per dag voor elke gebruiker">
                <ReminderCountPerUserChart
                    startDate={ startDate }
                    endDate={ endDate }
                />
            </ChartCard>
            <ChartCard title="Aantal herinneringen aangemaakt per dag totaal">
                <RemindersCreatedCountChart
                    startDate={ startDate }
                    endDate={ endDate }
                />
            </ChartCard>
            <ChartCard title="Aantal herinneringen aangemaakt per dag per gebruiker">
                <RemindersCreatedCountPerUserChart
                    startDate={ startDate }
                    endDate={ endDate }
                />
            </ChartCard>
            <ChartCard title="Aantal herinneringen opgelost per dag per gebruiker">
                <RemindersSolvedCountPerUserChart
                    startDate={ startDate }
                    endDate={ endDate }
                />
            </ChartCard>
        </React.Fragment>
    )
}

export default React.memo(ReminderStatistics);
