import React from "react";
import axios from "axios";
import {
    Annotation
} from "@zandor300/react-apple-mapkitjs";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import AppleMapsMap from "../../components/apple-maps/AppleMapsMap";
import Loading from "../../components/Loading";

class OutagesMap extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            outages: null,
            error: null
        }
    }

    componentDidMount() {
        this.getOutages();
    }

    getOutages() {
        axios.post("/getOutages", { page: "running" })
            .then((response) => {
                if(response.data.valid) {
                    const outages = response.data.data.filter((outage) => {
                        return !(!outage.latitude || !outage.longitude);
                    });
                    this.setState({ outages });
                } else {
                    this.setState({ outages: null, error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ outages: null, error: this.getErrorMessage(null) });
            });
    }

    getErrorMessage(errorCode) {
        switch(errorCode) {
            case "SESSION_EXPIRED":
                return "Sessie verlopen. Log opnieuw in.";
            default:
                return "Er is een fout opgetreden. Probeer het later opnieuw." + (errorCode ? " (" + errorCode + ")" : "");
        }
    }

    getCenterCoordinate() {
        const outages = this.state.outages;
        if(!outages) {
            return {
                centerLatitude: null, centerLongitude: null,
                latitudeSpan: null, longitudeSpan: null
            };
        }

        let minLat = null;
        let maxLat = null;
        let minLong = null;
        let maxLong = null;

        for(let outage of outages) {
            const latitude = outage.latitude;
            const longitude = outage.longitude;
            if(!latitude || !longitude) {
                continue;
            }
            if(!minLat || latitude < minLat) {
                minLat = latitude;
            }
            if(!maxLat || latitude > maxLat) {
                maxLat = latitude;
            }
            if(!minLong || longitude < minLong) {
                minLong = longitude;
            }
            if(!maxLong || longitude > maxLong) {
                maxLong = longitude;
            }
        }

        if(!minLat || !maxLat || !minLong || !maxLong) {
            return {
                centerLatitude: null, centerLongitude: null,
                latitudeSpan: null, longitudeSpan: null
            };
        }

        const centerLatitude = (minLat + maxLat) / 2;
        const centerLongitude = (minLong + maxLong) / 2;
        const latitudeSpan = outages.length === 1 ? 0.007 : Math.abs(maxLat - minLat) * 1.3;
        const longitudeSpan = outages.length === 1 ? 0.007 : Math.abs(maxLong - minLong) * 1.3;
        return {
            centerLatitude, centerLongitude,
            latitudeSpan, longitudeSpan
        };
    }

    render() {
        const {
            centerLatitude, centerLongitude,
            latitudeSpan, longitudeSpan
        } = this.getCenterCoordinate();

        return (
            <React.Fragment>
                <Title preTitle="Overzicht" noBottom>
                    Storingen Kaart
                </Title>
                <TabBar>
                    <TabBarItem to="/outages">
                        Lopend
                    </TabBarItem>
                    <TabBarItem to="/outages/finished">
                        Opgelost
                    </TabBarItem>
                    <TabBarItem to="/outages/all">
                        Alle
                    </TabBarItem>
                    <TabBarItem to="/outages/map">
                        Kaart
                    </TabBarItem>
                </TabBar>

                { !this.state.outages ? (
                    <Loading/>
                ) : (
                    <AppleMapsMap
                        latitude={ centerLatitude ? centerLatitude : 51.994459 }
                        longitude={ centerLongitude ? centerLongitude : 4.180937 }
                        spanLat={ latitudeSpan }
                        spanLong={ longitudeSpan }
                        height="750px"
                        width="100%"
                        initialMapType="hybrid"
                    >
                        { this.state.outages.map((outage) => (
                            <Annotation
                                key={ outage.id }
                                latitude={ outage.latitude }
                                longitude={ outage.longitude }
                                color={ outage.status.color }
                                title={ outage.name }
                            />
                        ))}
                    </AppleMapsMap>
                )}
            </React.Fragment>
        );
    }
}

export default OutagesMap;
