import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import isValidBSN from "./BSNValidate";

function BSNFormatter({ bsn }) {
    const valid = isValidBSN(bsn);
    if(valid) {
        return (
            <React.Fragment>
                { bsn }
                <OverlayTrigger placement="top" overlay={
                    <Tooltip id="iban">BSN geldig</Tooltip>
                }>
                    <i className="fas fa-check ml-2 text-success"/>
                </OverlayTrigger>
            </React.Fragment>
        )
    }
    return (
        <span className="text-warning">
            { bsn }
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="iban">BSN mogelijk niet geldig</Tooltip>
            }>
                <i className="fas fa-exclamation-triangle ml-2"/>
            </OverlayTrigger>
        </span>
    )
}

export default React.memo(BSNFormatter);
