import React, {
    useMemo
} from "react";
import {
    Link
} from "react-router-dom";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function DateRangeSwitcher({ baseUrl, nextUrl, previousUrl, currentMoment, previousMoment, nextMoment, displayFormat, viewType }) {
    const tooltipText = useMemo(() => {
        switch(viewType) {
            case "day": return "Naar huidige dag";
            case "month": return "Naar huidige maand";
            case "week": return "Naar huidige week";
            case "workweek": return "Naar huidige week";
            default: return "Naar huidige"
        }
    }, [viewType]);
    return (
        <div className="row mb-4">
            <div className="col text-left d-print-none">
                <Link to={ previousUrl }>
                    <i className="fas fa-chevron-left mr-2"/>
                    { previousMoment.format(displayFormat) }
                </Link>
            </div>
            <div className="col text-center">
                <OverlayTrigger overlay={
                    <Tooltip id="currentTooltip">{ tooltipText }</Tooltip>
                }>
                    <Link to={ baseUrl } className="no-link-style">
                        <b>{ currentMoment.format(displayFormat) }</b>
                    </Link>
                </OverlayTrigger>
            </div>
            <div className="col text-right d-print-none">
                <Link to={ nextUrl }>
                    { nextMoment.format(displayFormat) }
                    <i className="fas fa-chevron-right ml-2"/>
                </Link>
            </div>
        </div>
    )
}

export default React.memo(DateRangeSwitcher);
