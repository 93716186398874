import React from "react";
import {
    Card,
    Col,
    Row
} from "react-bootstrap";

import DateFormatter from "../../../components/formatters/DateFormatter";
import DeviceTypeIcon from "../../../components/comments/DeviceTypeIcon";
import numberFormatter from "../../../components/formatters/NumberFormatter";

function InventoryProductHistoryItemCard({ historyItem }) {
    return (
        <Card className="mb-2">
            <Card.Body className="p-3">
                <Row>
                    <Col className="text-left d-flex align-items-center">
                        <DeviceTypeIcon deviceType={ historyItem.deviceType } className="mr-2"/>
                        <div>
                            <b>{ historyItem.author.name }</b>
                        </div>
                    </Col>

                    <Col className="d-flex flex-column justify-content-center" style={{ fontSize: "1.25rem", maxWidth: "210px" }}>
                        { historyItem.oldAmount === historyItem.newAmount ? (
                            <div className="text-center">
                                { historyItem.newAmount }
                            </div>
                        ) : (
                            <div className="d-flex w-100">
                                <div className="flex-grow-1 text-right" style={{ width: "35%" }}>
                                    { numberFormatter({ number: historyItem.oldAmount }) }
                                </div>
                                <div className="text-center" style={{ width: "20%" }}>
                                    <i className="far fa-long-arrow-right"/>
                                </div>
                                <div className="flex-grow-1 text-left" style={{ width: "35%" }}>
                                    { numberFormatter({ number: historyItem.newAmount }) }
                                </div>
                            </div>
                        )}
                    </Col>

                    <Col className="text-right d-flex flex-column justify-content-center">
                        <div>
                            <DateFormatter date={ historyItem.date }/>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default React.memo(InventoryProductHistoryItemCard);
