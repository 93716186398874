import React from "react";
import {
    Route,
    Switch
} from "react-router-dom";

import ProductBrandsOverview from "./ProductBrandsOverview";
import ProductBrandDetail from "./brand-detail/ProductBrandDetail";
import ProductDetail from "./product-detail/ProductDetail";

function ProductsSwitch(props) {
    return (
        <Switch>
            {/*<Route path={`${props.match.path}/add`} component={ProductBrandAdd} />*/}
            <Route path={`${props.match.path}/:brandId/product/:productId`} component={ProductDetail} />
            <Route path={`${props.match.path}/:brandId`} component={ProductBrandDetail} />
            <Route path={`${props.match.path}/`} component={ProductBrandsOverview} />
        </Switch>
    )
}

export default React.memo(ProductsSwitch);
