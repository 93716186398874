import React, {
    useState
} from "react";
import {
    Alert
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../components/detail/DetailOverviewRow";
import TagPill from "../../../components/tagPill";
import ProductQuickActionSetAssignedBuilding from "./components/ProductQuickActionSetAssignedBuilding";
import ProductQuickActionSetAmount from "./components/ProductQuickActionSetAmount";

function ProductDetailOverview({ product, setProduct }) {
    const [error, setError] = useState(null);
    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            <div className="card mb-3">
                <div className="card-body">
                    <table className="table table-borderless mb-0">
                        <tbody>
                        <DetailOverviewRow title="Merk">
                            { product ? product.brand.name : <Skeleton width={100}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Naam">
                            { product ? product.name : <Skeleton width={200}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Type">
                            { product ? (
                                <TagPill color={ product.type.color }>
                                    { product.type.name }
                                </TagPill>
                            ) : <Skeleton width={75}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Toegewezen locatie" titleVerticalCenter>
                            { product ? (
                                <ProductQuickActionSetAssignedBuilding
                                    product={ product }
                                    setProduct={ setProduct }
                                    onError={ setError }
                                />
                            ) : <Skeleton width={150} height={34}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Voorradig" titleVerticalCenter>
                            { product ? (
                                <ProductQuickActionSetAmount
                                    product={ product }
                                    setProduct={ setProduct }
                                    onError={ setError }
                                />
                            ) : <Skeleton width={50} height={34}/> }
                        </DetailOverviewRow>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(ProductDetailOverview);
