import React from "react";
import axios from "axios";
import {
    Link,
    withRouter
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";
import Skeleton from "react-loading-skeleton";

import Helmet from "../../../components/Helmet";
import DetailOverviewRow from "../../../components/detail/DetailOverviewRow";
import TagPill from "../../../components/tagPill";
import numberFormatter from "../../../components/formatters/NumberFormatter";
import Loading from "../../../components/Loading";

class ProductBrandDetail extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            brand: null,
            error: null
        }
    }

    componentDidMount() {
        this.getProductBrand();
    }

    getProductBrand() {
        const brandId = this.props.match.params.brandId;
        axios.post("/getProductBrand", { brandId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ brand: response.data.data });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    render() {
        const brandId = this.props.match.params.brandId;
        const brand = this.state.brand;
        return (
            <React.Fragment>
                <Helmet title={ "Merk #" + brandId + (brand ? ` - ${brand.name}` : "") }/>

                <Title preTitle="Overzicht">
                    Merk <small className="text-muted">#{brandId}</small>
                </Title>

                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}

                <div className="card mb-3">
                    <div className="card-body">
                        <table className="table table-borderless mb-0">
                            <tbody>
                                <DetailOverviewRow title="Naam">
                                    { brand ? brand.name : <Skeleton width={150}/> }
                                </DetailOverviewRow>
                                <DetailOverviewRow title="Kleur">
                                    { brand ? (
                                        <TagPill color={ brand.color }>
                                            { brand.color }
                                        </TagPill>
                                    ) : <Skeleton width={75}/> }
                                </DetailOverviewRow>
                            </tbody>
                        </table>
                    </div>
                </div>

                <Table hover>
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <th className="text-center">#</th>
                            <th>Type</th>
                            <th colSpan={ 2 }>Product</th>
                            <th className="text-right">Voorradig</th>
                            <th>Locatie</th>
                        </tr>
                    </thead>
                    <tbody>
                        { !brand ? (
                            <tr>
                                <td colSpan={ 6 }>
                                    <Loading/>
                                </td>
                            </tr>
                        ) : brand.products.map((product) => {
                            const url = `/products/${brand.id}/product/${product.id}`;
                            return (
                                <tr className="table-clickable-row" key={ product.id }>
                                    <th className="text-center">
                                        <Link to={ url }>
                                            { product.id }
                                        </Link>
                                    </th>
                                    <th className="text-center">
                                        <Link to={ url }>
                                            <TagPill color={ product.type.color }>
                                                { product.type.name }
                                            </TagPill>
                                        </Link>
                                    </th>
                                    <td>
                                        <Link to={ url }>
                                            { product.brand.name }
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to={ url }>
                                            { product.name }
                                        </Link>
                                    </td>
                                    <td className="text-right">
                                        <Link to={ url }>
                                            { numberFormatter({ number: product.amount }) }
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to={ url }>
                                            { product.assignedBuilding ? product.assignedBuilding.name : <i className="text-muted">Geen</i> }
                                        </Link>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
}

export default withRouter(ProductBrandDetail);
