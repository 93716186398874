import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../components/Loading";
import RestockOrderProductCard from "../components/RestockOrderProductCard";

class RestockOrderDetailUndoDeliveredProductModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null
        }
        this.onSubmit = this.undoTakeProduct.bind(this);
        this.onShow = this.onShow.bind(this);
    }

    onShow() {
        this.setState({ error: null, loading: false });
    }

    undoTakeProduct() {
        this.setState({ loading: true, error: null });
        axios.post("/undoRestockOrderDeliveredProduct", {
            deliveredProductId: this.props.product.deliveredProductId
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.onUndo(response.data.expected, response.data.delivered);
                    this.props.handleClose();
                } else {
                    if(response.data.error === "INSUFFICIENT_AMOUNT_AVAILABLE") {
                        this.setState({ error: "Er is niet genoeg voorraad van dit product om het afleveren van het product ongedaan te maken." });
                    } else {
                        this.setState({ error: "Er ging iets fout bij het ongedaan maken. (" + response.data.error + ")" });
                    }
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                this.setState({ loading: false, error: "Er ging iets fout bij het ongedaan maken." });
                console.error(error);
            });
    }

    render() {
        return (
            <React.Fragment>
                <Modal size="lg" show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-warning">Afgeleverd product ongedaan maken</Modal.Title>
                    </Modal.Header>
                    <form className="mb-0">
                        <Modal.Body>
                            { this.state.error !== null && (
                                <Alert variant="danger">{ this.state.error }</Alert>
                            )}
                            { this.props.product !== null ? (
                                <React.Fragment>
                                    <p className="text-warning">
                                        Weet je zeker dat je het afgeleverde product ongedaan wilt maken? Dit zal de
                                        hoeveelheid terug toevoegen aan de actuele voorraad en het product weer als
                                        verwacht aan de bestelling toevoegen.
                                    </p>
                                    <RestockOrderProductCard product={ this.props.product } readOnly/>
                                </React.Fragment>
                            ) : (
                                <Loading/>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                                Annuleer
                            </Button>
                            <Button variant="primary" onClick={ this.onSubmit } disabled={ this.state.loading }>
                                { this.state.loading && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Ongedaan maken
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
        )
    }
}

export default RestockOrderDetailUndoDeliveredProductModal;
