import React, {
    useCallback,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Button
} from "react-bootstrap";

import downloadFile from "../../../../utilities/downloadFile";

function DownloadApplicationPDFButton({ vatRefund, onError }) {
    const [downloading, setDownloading] = useState(false);

    const onClick = useCallback(() => {
        if(!vatRefund.invoiceDate) {
            onError("Factuurdatum is niet ingesteld. Dit is een vereiste om de opgaaf zonnepaneelhouders PDF aan te kunnen maken.");
            return;
        }
        setDownloading(true);
        onError(null);
        axios.post("/getVATRefundApplicationPDF", { vatRefundId: vatRefund.id })
            .then((response) => {
                const date = moment().format("YYYY-MM-DD HH:mm:ss");
                const filename = `Opgaaf zonnepaneelhouders - ${vatRefund.lastName}, ${vatRefund.initials} (#${vatRefund.id}) - ${date}.pdf`;
                downloadFile(response.data, filename, "application/pdf");
            })
            .catch(() => {
                onError("Er ging iets fout. Probeer het later opnieuw.");
            })
            .finally(() => {
                setDownloading(false);
            });
    }, [vatRefund, onError]);

    return (
        <Button variant="secondary" size="sm" className="ml-2" onClick={ onClick } disabled={ downloading }>
            { downloading ? (
                <i className="fas fa-circle-notch fa-fw fa-spin mr-2"/>
            ) : (
                <i className="fas fa-download fa-fw mr-2"/>
            )}
            Opgaaf zonnepaneelhouders
        </Button>
    );
}

export default React.memo(DownloadApplicationPDFButton);
