import {
    useEffect
} from "react";
import {
    withRouter
} from "react-router-dom";

function ScrollToTop({ history, exclude = [] }) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            if(!exclude.includes(history.location.pathname)) {
                window.scrollTo(0, 0);
            }
        });
        return () => {
            unlisten();
        }
    }, [history, exclude]);

    return null;
}

export default withRouter(ScrollToTop);
