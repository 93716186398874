import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import Loading from "../../../../components/Loading";
import DateFormatter from "../../../../components/formatters/DateFormatter";

function UserActivitySummary({ user, limit = 10 }) {
    const [userActivity, setUserActivity] = useState(null);
    const [error, setError] = useState(null);

    const userId = useMemo(() => {
        if(!user) {
            return null;
        }
        return parseInt(user.id);
    }, [user]);

    const getUserActivity = useCallback(() => {
        setUserActivity(null);
        setError(null);
        axios.post("/getUserActivity", { userId, limit })
            .then((response) => {
                if(response.data.valid) {
                    setUserActivity(response.data.data);
                } else {
                    setUserActivity(null);
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setUserActivity(null);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, [userId, limit]);

    useEffect(() => {
        if(userId === null) {
            return;
        }
        getUserActivity();
    }, [userId, getUserActivity]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!userActivity) {
        return (
            <Loading/>
        )
    }
    if(userActivity.length === 0) {
        return (
            <p>Nog geen activiteit.</p>
        )
    }
    return userActivity.map((activity) => (
        <div className="card mb-2" key={ activity.id }>
            <div className="card-body">
                <span className="float-right text-muted">
                    <DateFormatter date={ activity.date }/>
                </span>
                <p className="card-text">
                    { `${activity.type}: ${activity.targetType} ${activity.targetId}` }
                </p>
            </div>
        </div>
    ));
}

export default React.memo(UserActivitySummary);
