import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../components/Loading";
import RestockOrderProductCard from "../components/RestockOrderProductCard";

class RestockOrderDetailRemoveProductModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null
        }
        this.onSubmit = this.removeProduct.bind(this);
        this.onShow = this.onShow.bind(this);
    }

    onShow() {
        this.setState({ error: null, loading: false });
    }

    removeProduct() {
        this.setState({ loading: true, error: null });
        axios.post("/removeRestockOrderProduct", {
            restockOrderId: this.props.restockOrderId,
            productId: this.props.product.id
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.onRemove(response.data.expected, response.data.delivered);
                    this.props.handleClose();
                } else {
                    this.setState({ loading: false, error: "Er ging iets fout bij het toevoegen van het materiaal. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ loading: false, error: "Er ging iets fout bij het toevoegen van het materiaal." });
                console.error(error);
            });
    }

    render() {
        return (
            <React.Fragment>
                <Modal size="lg" show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-danger">Product verwijderen</Modal.Title>
                    </Modal.Header>
                    <form className="mb-0">
                        <Modal.Body>
                            { this.state.error !== null && (
                                <Alert variant="danger">{ this.state.error }</Alert>
                            )}
                            { this.props.product === null ? (
                                <Loading/>
                            ) : (
                                <React.Fragment>
                                    <p className="text-danger">
                                        Weet je zeker dat je dit product wilt verwijderen?
                                    </p>
                                    <RestockOrderProductCard product={ this.props.product } readOnly/>
                                </React.Fragment>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                                Annuleer
                            </Button>
                            <Button variant="danger" onClick={ this.onSubmit } disabled={ this.state.loading }>
                                { this.state.loading && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Verwijderen
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
        )
    }
}

export default RestockOrderDetailRemoveProductModal;
