import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Modal,
    Form
} from "react-bootstrap";

import {
    withUsersContext
} from "../../../context/UsersContext.js";

class AbsenceAddModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modalState: 0,
            modalError: null,

            absenceUserId: -1,
            absenceStartDate: "",
            absenceEndDate: "",
            absenceDescription: "",
            absenceType: 0,
        };
        this.onShow = this.onShow.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if(props.show && state.modalState === 0) {
            return {
                modalState: 1,
                modalError: null,

                absenceUserId: -1,
                absenceStartDate: "",
                absenceEndDate: "",
                absenceDescription: "",
                absenceType: 0,

                ...props
            }
        } else if(!props.show && state.modalState > 0) {
            return {
                modalState: 0
            }
        }

        return null;
    }

    onShow() {
        this.props.usersContext.refreshUsers();
    }

    CanCreateAbsence() {
        return this.state.absenceUserId > 0
            && this.state.absenceType > 0
            && moment(this.state.absenceStartDate).isValid()
            && moment(this.state.absenceEndDate).isValid();
    }

    CreateAbsenceItem() {
        if(!this.CanCreateAbsence())
            return;

        this.setState({modalState: 2});

        // Make a request for a user with a given ID
        axios.post('/addAbsenceItem', {
            userId: this.state.absenceUserId,
            typeId: this.state.absenceType,
            description: this.state.absenceDescription,
            startDate: this.state.absenceStartDate,
            endDate: this.state.absenceEndDate
        })
            .then((response) => {
                if(response.data.valid) {
                    // Update current week state
                    this.props.updateCurrentWeek();
                    // Remove modal
                    this.props.onHide();
                } else {
                    // Set modal to error mode.
                    this.setState({modalState: 3, modalError: response.data.error});
                }
            })
            .catch((error) => {
                this.setState({modalState: 3, modalError: "Er was een probleem tijdens het versturen van de informatie, probeer het later opnieuw!"});
                // handle error
                console.error(error);
            });
    }

    render() {
        return <Modal
            show={this.state.modalState > 0}
            size="md"
            aria-labelledby="contained-modal-title"
            centered
            onHide={this.props.onHide}
            onShow={this.onShow}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title">
                    Absentie aanmaken
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.modalError != null && (
                    <div className="alert alert-danger text-left mb-3 mt-0" role="alert">
                        Er was een probleem tijdens het versturen van de absentie.<br/>
                        Error: {this.state.modalError}
                    </div>
                )}

                <label htmlFor="absenceEmployee">Werknemer</label>
                <Form.Control as="select" id="absenceEmployee" className="mb-3 custom-select" name="absenceemployee"
                              disabled={this.state.modalState === 2}
                              onChange={(e) => this.setState({absenceUserId: e.target.value})}
                              value={this.state.absenceUserId}
                >
                    <option value={-1}>
                        Werknemer
                    </option>
                    {this.props.usersContext.users && this.props.usersContext.users.map((user) => {
                        if('enabled' in user && user.enabled) {
                            return (
                                <option key={user.id} value={user.id}>
                                    {user.name}
                                </option>
                            );
                        }
                        return null;
                    })}
                </Form.Control>
                <br/>

                <label htmlFor="startdate">Datums</label>
                <div className="row mb-3">
                    <div className="col-6">
                        <input type="date" className="form-control" id="startdate" name="start-date"
                               placeholder="Datum" title="Start Datum"
                               disabled={this.state.modalState === 2} required
                               value={this.state.absenceStartDate}
                               onChange={(e) => this.setState({absenceStartDate: e.target.value})}/>
                    </div>
                    <div className="col-6">
                        <input type="date" className="form-control" id="enddate" name="end-date"
                               placeholder="Datum" title="Eind Datum"
                               disabled={this.state.modalState === 2} required
                               value={this.state.absenceEndDate}
                               onChange={(e) => this.setState({absenceEndDate: e.target.value})}/>
                    </div>
                </div>

                <label htmlFor="type">Type</label>
                <Form.Control as="select" id="type" className="mb-3 custom-select" name="absencetype"
                              disabled={this.state.modalState === 2}
                              onChange={(e) => this.setState({absenceType: e.target.value})}
                              value={this.state.absenceType}
                >
                    <option value={-1}></option>
                    {this.props.absenceTypes && this.props.absenceTypes.map((type) => {
                        return <option key={type.id}
                                       style={{backgroundColor: type.color, color: "white"}}
                                       value={type.id}
                        >
                            {type.name}
                        </option>
                    })}
                </Form.Control>

                <br/>

                <label htmlFor="description">Beschrijving <small className="text-muted">(optioneel)</small></label>
                <textarea className="form-control" id="description"
                          disabled={this.state.modalState === 2}
                          name="description" style={{height: 100}}
                          value={this.state.absenceDescription}
                          onChange={(e) => this.setState({absenceDescription: e.target.value})}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-secondary"
                        onClick={this.props.onHide}>
                    Sluiten
                </button>
                <button className="btn btn-primary" onClick={this.CreateAbsenceItem.bind(this)}
                        disabled={this.state.modalState === 2 || !this.CanCreateAbsence()}>
                    Aanmaken
                </button>
            </Modal.Footer>
        </Modal>;
    }
}

export default withUsersContext(AbsenceAddModal);
