import React, {
    useState
} from "react";
import moment from "moment";

import ImageModal from "./modal/ImageModal";
import ResizableImage from "../../components/ResizableImage";

function ImageGallery({ images, onImageRemoved, showOpenParentButton = false }) {
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const handleImageModalClose = () => {
        setShowImageModal(false)
    }

    return (
        <React.Fragment>
            <ImageModal
                image={ selectedImage }
                show={ showImageModal }
                onImageRemoved={ onImageRemoved }
                handleClose={ handleImageModalClose }
                showOpenParentButton={ showOpenParentButton }
            />

            <div className="row no-gutters row-cols-2 row-cols-print-4 row-cols-md-4 row-cols-lg-5 row-cols-xl-6">
                { images.map(image => {
                    const onClickImage = () => {
                        setSelectedImage(image);
                        setShowImageModal(true);
                    }
                    return (
                        <div className="col image-box" key={ image.id } onClick={ onClickImage }>
                            <ResizableImage image={ image } alt={ "Image " + image.id } size="315x315"/>
                            <div className="image-box-overlay">
                                <h4>{ image.author.name }</h4>
                                <p>{ moment(image.date).calendar() }</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
}

export default React.memo(ImageGallery);
