import React from "react";
import axios from "axios";
import {
    withRouter
} from "react-router-dom";
import {
    Alert,
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import VATRefundForm from "./components/VATRefundForm";
import DetailOverviewRow from "../../components/detail/DetailOverviewRow";
import Loading from "../../components/Loading";

class VATRefundAdd extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            error: null,
            loadError: null,
            success: false,
            installation: null,

            initials: "",
            lastName: "",
            birthday: "",
            email: "",
            phoneNumber: "",
            street: "",
            houseNumber: "",
            postalCode: "",
            city: "",
            entrepreneur: null,
            bsn: "",
            iban: "",
            ibanName: "",
            invoiceDate: "",
            invoiceVATPrice: "",
            vatNumber: "",
            wp: "",

            emptyInvoiceDate: true,
        };
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.getInstallation();
    }

    getInstallation() {
        this.setState({ installation: null, loadError: null });
        axios.post("/getInstallation", { id: this.props.match.params.installationId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ installation: response.data.data });
                } else {
                    this.setState({ loadError: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    onSave() {
        if(this.state.entrepreneur === null) {
            this.setState({ error: "Niet alle velden zijn ingevuld." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        if(!this.state.emptyInvoiceDate && this.state.invoiceDate.trim().length === 0) {
            this.setState({ error: "Niet alle velden zijn ingevuld." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        this.setState({ error: null, success: false, saving: true });
        axios.post("/addVATRefund", {
            installationId: this.props.match.params.installationId,

            initials: this.state.initials,
            lastName: this.state.lastName,
            birthday: this.state.birthday,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            street: this.state.street,
            houseNumber: this.state.houseNumber,
            postalCode: this.state.postalCode,
            city: this.state.city,
            entrepreneur: this.state.entrepreneur ? 1 : 0,
            bsn: this.state.bsn,
            iban: this.state.iban,
            ibanName: this.state.ibanName,
            invoiceDate: this.state.emptyInvoiceDate ? null : this.state.invoiceDate,
            invoiceVATPrice: this.state.emptyInvoiceDate ? null : this.state.invoiceVATPrice,
            vatNumber: this.state.vatNumber,
            wp: this.state.wp
        })
            .then((response) => {
                if(response.data.valid) {
                    const vatRefund = response.data.vatRefund;
                    this.props.history.push("/vat-refund/" + vatRefund.id);
                } else {
                    const error = response.data.error;
                    if(error === "FUTURE_INVOICE_DATE") {
                        this.setState({ error: "Factuurdatum mag niet in de toekomst zijn." });
                    } else {
                        this.setState({ error: "Er was een fout bij het opslaan van de data. (" + error + ")" });
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er was een fout bij het opslaan van de data." });
            })
            .finally(() => {
                this.setState({ saving: false });
                window.scroll({ top: 0, behavior: "smooth" });
            });
    }

    render() {
        const installation = this.state.installation;
        return (
            <React.Fragment>
                <Helmet title="Nieuwe BTW Terugvraag"/>

                <Title preTitle="Detail">
                    Nieuwe BTW Terugvraag
                </Title>

                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}

                { this.state.loadError ? (
                    <Alert variant="danger">{ this.state.loadError }</Alert>
                ) : this.state.installation === null ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h4>
                                    Geselecteerde installatie
                                </h4>

                                <table className="table table-borderless mb-0">
                                    <tbody>
                                    <DetailOverviewRow title="Naam" small>
                                        { installation.lead.name }
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Adres" small>
                                        { installation.street + " " + installation.houseNumber + ", " + installation.city }
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Postcode" small>
                                        { installation.postalCode }
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Telefoonnummer" small>
                                        <a href={"dial:" + installation.lead.phoneNumber}>
                                            {installation.lead.phoneNumber}
                                        </a>
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Email" small>
                                        <a href={"mailto:" + installation.lead.email}>
                                            {installation.lead.email}
                                        </a>
                                    </DetailOverviewRow>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <VATRefundForm
                            setFormField={ this.setState.bind(this) }
                            formFields={ this.state }
                            onSave={ this.onSave }
                            saving={ this.state.saving }
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(VATRefundAdd);
