import React from "react";
import axios from "axios";

import ProductAvailabilityChart from "./components/ProductAvailabilityChart";
import ChartCard from "../../statistics/components/ChartCard";

class ProductDetailAmountAvailable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            amountAvailable: null,
            amountNotPlannedIn: null,
            error: null
        }
    }

    componentDidMount() {
        this.getReserved();
    }

    getReserved() {
        axios.post("/getAmountAvailableThroughTimeForProduct", { productId: this.props.productId })
            .then((response) => {
                if(response.data.valid) {
                    const {
                        amountAvailable,
                        amountNotPlannedIn
                    } = response.data;
                    this.setState({
                        amountAvailable,
                        amountNotPlannedIn
                    });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    render() {
        return (
            <ChartCard title="Aantal beschikbaar">
                <ProductAvailabilityChart amountAvailable={ this.state.amountAvailable }/>
            </ChartCard>
        );
    }

}

export default ProductDetailAmountAvailable;
