import React from "react";
import axios from "axios";

const IntranetSidebarContext = React.createContext(null);

export class IntranetSidebarManager extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            remindersCount: null,
            refreshReminders: this.getReminders.bind(this)
        }
    }

    componentDidMount() {
        this.getReminders();
    }

    getReminders() {
        axios.get("/getReminders")
            .then((response) => {
                if (response.data.valid) {
                    this.setState({ remindersCount: response.data.data.length });
                } else {
                    this.setState({ remindersCount: undefined });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ remindersCount: undefined });
            });
    }

    render() {
        return (
            <IntranetSidebarContext.Provider value={ this.state }>
                { this.props.children }
            </IntranetSidebarContext.Provider>
        )
    }
}

export function withIntranetSidebarContext(Component) {
    return function contextComponent(props) {
        return (
            <IntranetSidebarContext.Consumer>
                {context => <Component {...props} intranetSidebarContext={context} />}
            </IntranetSidebarContext.Consumer>
        )
    }
}

export default IntranetSidebarContext;
