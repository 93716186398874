import React from "react";
import TagPill from "../../../components/tagPill";
import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";
import PlanningItemCalendarCardParentStatus from "./PlanningItemParentStatusDot";

function PlanningItemCalendarCard({ planningItem, beforeToday, onClick, noDescription = false, noBottomMargin = false, className }) {
    let description = planningItem.description;
    if(planningItem.descriptionSuffix) {
        description += "\n\n" + planningItem.descriptionSuffix;
    }

    let classNames = ["card", "pointer-cursor"];
    if(!noBottomMargin) {
        classNames.push("mb-2")
    }
    if(beforeToday) {
        classNames.push("text-muted")
    }
    if(className) {
        classNames.push(className);
    }

    return (
        <div
            className={ classNames.join(" ") }
            onClick={ onClick }
        >
            <div className="card-body p-1">
                <div className="d-flex flex-row">
                    <div className="d-flex align-items-center flex-grow-1">
                        <TagPill color={ planningItem.type.color }>
                            { planningItem.type.name }
                        </TagPill>
                    </div>
                    <div className="d-flex align-items-center px-1">
                        <PlanningItemCalendarCardParentStatus planningItem={ planningItem }/>
                    </div>
                </div>
                { planningItem.name ? (
                    <b>{ planningItem.name }</b>
                ) : (
                    <b className="text-danger">Unavailable</b>
                )}
                <br/>
                { !noDescription && (
                    <div style={{ fontSize: "0.9rem" }}>
                        { description.trim().length > 0 && (
                            <ReactMarkdownBreaks message={ description.trim() }/>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default React.memo(PlanningItemCalendarCard);
