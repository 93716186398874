import React from "react";

/**
 * undefined means authentication has not been checked.
 * null means not authenticated.
 * filled object means authenticated.
 * @type {React.Context<null>}
 */
const AuthenticatedUserContext = React.createContext(null);

export function withAuthenticatedUserContext(Component) {
    return function contextComponent(props) {
        return (
            <AuthenticatedUserContext.Consumer>
                {context => <Component {...props} authenticatedUserContext={context} />}
            </AuthenticatedUserContext.Consumer>
        )
    }
}

export default AuthenticatedUserContext;
