import React from "react";
import axios from "axios";
import moment from "moment";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import TagPill from "../../components/tagPill";

import AbsenceUserRow from "./components/AbsenceUserRow.js";
import AbsenceAddModal from "./modal/AbsenceAddModal.js";
import AbsenceUpdateModal from "./modal/AbsenceUpdateModal.js";
import DateRangeSwitcher from "../../components/calendar/DateRangeSwitcher";
import Loading from "../../components/Loading";

function WeekdayHeaderItem({ momentDay }) {
    if(momentDay.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
        return (
            <td>
                <TagPill>
                    { momentDay.format("D MMM") }
                </TagPill>
            </td>
        );
    } else {
        return (
            <td>
                { momentDay.format("D MMM") }
            </td>
        );
    }
}

class AbsenceCalendar extends React.PureComponent {
    state = {
        absenceTypes: null,
        absenceItemsPerUser: null,
        startDate: moment(),

        showAddAbsenceModal: false,
        updateAbsenceModalData: null,
    };

    getWeek() {
        return this.props.match.params.weekNumber === undefined ? moment().isoWeek() : parseInt(this.props.match.params.weekNumber);
    }

    getYear() {
        return this.props.match.params.year === undefined ? moment().isoWeekYear() : parseInt(this.props.match.params.year);
    }

    componentDidMount() {
        this.setState({
            startDate: moment().isoWeekYear(this.getYear()).isoWeek(this.getWeek()).isoWeekday(1)
        });
        this.getAbsenceItems();
        this.getAbsenceTypes();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.path !== this.props.match.path || prevProps.match.params !== this.props.match.params) {
            this.setState({
                startDate: moment().isoWeekYear(this.getYear()).isoWeek(this.getWeek()).isoWeekday(1)
            });
            this.getAbsenceItems();
        }
    }

    getAbsenceItems() {
        const startDate = moment().isoWeekYear(this.getYear()).isoWeek(this.getWeek()).isoWeekday(1);
        const endDate = startDate.clone().isoWeekday(5);
        this.setState({ absenceItemsPerUser: null });
        axios.post('/getAbsenceItems', {
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD")
        })
            .then((response) => {
                if(response.data.valid) {
                    let sortedAbsenceItems = {};
                    response.data.data.forEach((rawAbsenceItem) => {
                        if(!sortedAbsenceItems[rawAbsenceItem.user.id])
                            sortedAbsenceItems[rawAbsenceItem.user.id] = [];
                        sortedAbsenceItems[rawAbsenceItem.user.id].push(rawAbsenceItem);
                    });
                    this.setState({ absenceItemsPerUser: sortedAbsenceItems });
                } else {
                    // Possible session expired, check error!
                }
            })
            .catch((error) => {
                // handle error
                console.error(error);
            });
    }

    getAbsenceTypes() {
        axios.get('/getAbsenceItemTypes')
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ absenceTypes: response.data.data });
                } else {
                    // Possible session expired, check error!
                }
            })
            .catch((error) => {
                // handle error
                console.error(error);
            });
    }

    render() {
        const baseUrl = "/absence";
        const date = this.state.startDate;
        const previousDate = date.clone().subtract(1, "week");
        const nextDate = date.clone().add(1, "week");

        const previousUrl = baseUrl + "/" + previousDate.format("GGGG/WW");
        const nextUrl = baseUrl + "/" + nextDate.format("GGGG/WW");

        return (
            <React.Fragment>
                <Helmet title={ "Absenties - " + date.format("[Week] W, GGGG") }/>
                <Title
                    preTitle="Overzicht"
                    preChildren={
                        <div className="btn-group float-right mt-2" role="group">
                            <OverlayTrigger overlay={
                               <Tooltip id="addTooltip">Nieuwe absentie toevoegen</Tooltip>
                            }>
                                <div
                                    className="btn btn-primary"
                                    onClick={() => this.setState({showAddAbsenceModal: true})}
                                >
                                    <i className="fa fa-fw fa-plus"/>
                                </div>
                            </OverlayTrigger>
                        </div>
                    }
                >
                    Absentie
                </Title>

                <DateRangeSwitcher
                    baseUrl={ baseUrl }
                    previousUrl={ previousUrl }
                    nextUrl={ nextUrl }
                    currentMoment={ date }
                    previousMoment={ previousDate }
                    nextMoment={ nextDate }
                    displayFormat="[Week] W, GGGG"
                    viewType="week"
                />

                <table className="table table-hover" style={{ tableLayout: "fixed" }}>
                    <thead className="thead-light">
                    <tr className="tr-sticky">
                        <th>Maandag</th>
                        <th>Dinsdag</th>
                        <th>Woensdag</th>
                        <th>Donderdag</th>
                        <th>Vrijdag</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <WeekdayHeaderItem momentDay={ date.clone().isoWeekday(1) }/>
                        <WeekdayHeaderItem momentDay={ date.clone().isoWeekday(2) }/>
                        <WeekdayHeaderItem momentDay={ date.clone().isoWeekday(3) }/>
                        <WeekdayHeaderItem momentDay={ date.clone().isoWeekday(4) }/>
                        <WeekdayHeaderItem momentDay={ date.clone().isoWeekday(5) }/>
                    </tr>

                    {!this.state.absenceItemsPerUser ? (
                        <tr>
                            <td colSpan={5} className="text-center pt-4">
                                <Loading/>
                            </td>
                        </tr>
                    ) : (
                        Object.values(this.state.absenceItemsPerUser).length <= 0 ? (
                            <tr>
                                <td colSpan={5} className="text-center pt-4">
                                    <div className="w-100 h2">
                                        <i className="fas fa-user-check"/>
                                    </div>
                                    <div className="text-weight-bold h4">
                                        Iedereen is present
                                    </div>
                                </td>
                            </tr>
                        ) : Object.values(this.state.absenceItemsPerUser).map((absenceUser, index) => (
                            <AbsenceUserRow
                                key={ index }
                                absenceItems={ absenceUser }
                                currentWeekStartMoment={ date }
                                onClick={ (absenceItem) => this.setState({updateAbsenceModalData: absenceItem}) }
                            />
                        ))
                    )}
                    </tbody>
                </table>

                <AbsenceAddModal
                    show={ this.state.showAddAbsenceModal }
                    updateCurrentWeek={ this.getAbsenceItems.bind(this) }
                    absenceTypes={ this.state.absenceTypes }
                    onHide={ () => this.setState({showAddAbsenceModal: false}) }
                />
                <AbsenceUpdateModal
                    show={ this.state.updateAbsenceModalData !== null }
                    updateCurrentWeek={ this.getAbsenceItems.bind(this) }
                    absenceTypes={ this.state.absenceTypes }
                    absenceData={ this.state.updateAbsenceModalData }
                    onHide={ () => this.setState({updateAbsenceModalData: null}) }
                />
            </React.Fragment>
        );
    }
}

export default AbsenceCalendar;
