import React from "react";
import {
    Button,
    Modal
} from "react-bootstrap";

import TagPill from "../../tagPill";
import ReactMarkdownGFM from "../../ReactMarkdownGFM";

function DetailStatusExplanationModal({ show, statuses, handleClose }) {
    return (
        <Modal show={ show } onHide={ handleClose } size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Status uitleg</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table mb-0">
                    <thead>
                    <th>Status</th>
                    <th>Beschrijving</th>
                    </thead>
                    <tbody>
                    { statuses && statuses.map((status) => {
                        if(!status.description || status.description.trim().length === 0) {
                            return null;
                        }
                        return (
                            <tr key={ status.id }>
                                <td>
                                    <TagPill color={ status.color }>
                                        { status.name }
                                    </TagPill>
                                </td>
                                <td className="mb-0-p-last">
                                    <ReactMarkdownGFM message={ status.description }/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose }>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default React.memo(DetailStatusExplanationModal);
