import React, {
    useContext
} from "react";
import IntranetSidebarContext from "../IntranetSidebarContext";
import numberFormatter from "../../formatters/NumberFormatter";

function RemindersCountBadgeContent() {
    const sidebarContext = useContext(IntranetSidebarContext);
    if(sidebarContext.remindersCount === null) {
        return (
            <i className="fas fa-spinner-third fa-spin"/>
        );
    }
    if(sidebarContext.remindersCount === undefined) {
        return "Error";
    }
    return numberFormatter({ number: sidebarContext.remindersCount });
}

function RemindersCountBadge() {
    return (
        <div className="badge badge-light float-right my-auto" style={{ whiteSpace: "nowrap" }}>
            <RemindersCountBadgeContent/>
        </div>
    )
}

export default React.memo(RemindersCountBadge);
