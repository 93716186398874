import React from "react";
import axios from "axios";
import {
    Link,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import TagPill from "../../components/tagPill";
import Loading from "../../components/Loading";
import Page404 from "../Page404";
import ImageGallery from "./ImageGallery";
import PaginationBar from "../../components/PaginationBar";
import numberFormatter from "../../components/formatters/NumberFormatter";

class Images extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            images: null,
            error: null,
            totalAmount: null,

            page: 0,
            amountPerPage: 30
        }
    }

    componentDidMount() {
        this.parsePage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.page !== this.props.match.params.page) {
            this.parsePage();
        }
    }

    parsePage() {
        let page = 0;
        if(this.props.match.params.page !== undefined) {
            page = parseInt(this.props.match.params.page) - 1;
        }
        this.setState({ page: page });
        this.getImages(page);
    }

    getImages(page) {
        if(page < 0) {
            return;
        }
        this.setState({ images: null, error: null });
        let amount = this.state.amountPerPage;
        let offset = page * amount;
        axios.post("/getImages", { amount, offset })
            .then((response) => {
                if(response.data.valid) {
                    if(page === this.state.page) {
                        this.setState({ images: response.data.data, totalAmount: response.data.totalAmount });
                    }
                } else {
                    this.setState({ images: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ images: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    setPage(page) {
        this.props.history.push("/images/" + page);
    }

    render() {
        if(this.state.page < 0) {
            return (
                <Page404/>
            )
        }
        let paginationBar = null;
        if(this.state.totalAmount !== null && this.state.totalAmount > this.state.amountPerPage && (!this.state.images || this.state.images.length > 0)) {
            paginationBar = (
                <PaginationBar
                    page={ this.state.page + 1 }
                    pagesAmount={ Math.ceil(this.state.totalAmount / this.state.amountPerPage) }
                    onPageChange={ this.setPage.bind(this) }
                />
            )
        }
        return (
            <React.Fragment>
                <Helmet title="Foto's"/>
                <Title preTitle="Overzicht">
                    Foto's
                    <small className="ml-2">
                        <TagPill>
                            { this.state.totalAmount === null ? 0 : numberFormatter({ number: this.state.totalAmount }) }
                        </TagPill>
                    </small>
                </Title>

                { paginationBar }

                { this.state.error !== null ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : this.state.images === null ? (
                    <Loading/>
                ) : this.state.totalAmount === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-images"/></h1>
                        <h3>Geen foto's</h3>
                        <p>Er zijn nog geen foto's geupload naar het intranet.</p>
                    </div>
                ) : this.state.images.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-images"/></h1>
                        <h3>Geen foto's</h3>
                        <p>Deze pagina bestaat niet.</p>
                        <Link to="/images" className="btn btn-primary btn-sm">
                            Ga naar pagina 1
                        </Link>
                    </div>
                ) : (
                    <ImageGallery
                        images={ this.state.images }
                        showOpenParentButton
                    />
                )}

                { this.state.images && (
                    <div className="mt-4">
                        { paginationBar }
                    </div>
                )}

            </React.Fragment>
        )
    }
}

export default withRouter(Images);
