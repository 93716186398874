import React from "react";

function PrintFormTextLine({ lines = 1 }) {
    return [...Array(lines)].map((element, index) => (
        <div key={ index } className="mt-4 mb-4">
            <hr className="m-0"/>
        </div>
    ));
}

export default React.memo(PrintFormTextLine);
