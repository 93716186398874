import React from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    Tooltip,
    OverlayTrigger
} from "react-bootstrap";

import TagPill from "../../../components/tagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";

import DetailOverviewRow from "../../../components/detail/DetailOverviewRow";
import DetailOverviewQRCode from "../../../components/detail/DetailOverviewQRCode";
import DetailOverviewComments from "../../../components/detail/DetailOverviewComments";
import OutageDetailSetAssignedUserModal from "./modal/OutageDetailSetAssignedUserModal";
import OutageDetailSetStatusModal from "./modal/OutageDetailSetStatusModal";
import OutageDetailSetServiceSettingModal from "./modal/OutageDetailSetServiceSettingModal";
import OutageDetailSetPriorityModal from "./modal/OutageDetailSetPriorityModal";
import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";
import AppleMapsModal from "../../../components/apple-maps/AppleMapsModal";
import ClickCopy from "../../../components/copy/ClickCopy";

class OutageDetailOverview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            comments: null,
            newCommentLoading: false,
            error: null,
            errorComments: null,
            errorNewComment: null,
            qrCodeLoaded: false,

            showSetAssignedUserModal: false,
            showSetStatusModal: false,
            showSetServiceSettingModal: false,
            showSetPriorityModal: false,
            showAppleMapsModal: false
        };
        this.onAddComment = this.addComment.bind(this);
        this.onCommentUpdated = this.commentUpdated.bind(this);
        this.onCommentDeleted = this.commentDeleted.bind(this);
    }

    componentDidMount() {
        this.getComments();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.outageId !== null && this.props.outageId !== prevProps.outageId) {
            this.getComments(true);
        }
    }

    setOutageProps(props) {
        this.props.setOutage((prevOutage) => {
            return { ...prevOutage, ...props };
        })
    }

    clearOutageWarnings() {
        this.setOutageProps({ warnings: [] });
    }

    getComments(clearComments = false) {
        if(clearComments) {
            this.setState({ comments: null });
        }
        this.setState({ errorComments: null });
        axios.post("/getOutageComments", { outage: this.props.outageId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ comments: response.data.data });
                } else {
                    this.setState({ errorComments: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ errorComments: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    addComment(message, images, clearContent) {
        if(message.trim().length === 0) {
            this.setState({ errorNewComment: "De notitie kan niet leeg zijn." });
            return;
        }
        this.setState({ errorNewComment: null, newCommentLoading: true });
        axios.post("/addOutageComment", { outage: this.props.id, message })
            .then(async (response) => {
                if(response.data.valid) {
                    if(!await this.uploadImagesIfNeeded(response.data.comment.id, images)) {
                        this.setState({ errorNewComment: "Er is een fout opgetreden. Niet alle foto's zijn geupload." });
                    }
                    this.getComments();
                    this.clearOutageWarnings();
                    clearContent();
                } else {
                    this.setState({ errorNewComment: "Er ging iets fout bij het plaatsen van je notitie. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ errorNewComment: "Er ging iets fout bij het plaatsen van je notitie. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ newCommentLoading: false });
            });
    }

    async uploadImagesIfNeeded(commentId, images) {
        for(const image of images) {
            if(!await this.uploadImage(commentId, image)) {
                return false;
            }
        }
        return true;
    }

    async uploadImage(commentId, image) {
        if(image.file === null) {
            return false;
        }

        const formData = new FormData();
        formData.append("outageCommentId", commentId);
        formData.append("file", image.file, image.file.name);

        let response = await axios.post("/uploadImage", formData, {
            transformRequest: (data, headers) => {
                delete headers.post["Content-Type"];
                return data;
            }
        });

        return !(!response.data || !response.data.valid);
    }

    commentUpdated(comment) {
        this.setState((prevState) => {
            const comments = prevState.comments.map((searchComment) => {
                if(searchComment.id === comment.id && searchComment.commentType === comment.commentType) {
                    return comment;
                }
                return searchComment;
            });
            return { comments };
        });
    }

    commentDeleted() {
        this.getComments();
    }

    handleAssignedUserChanged(assignedUser) {
        this.setOutageProps({ assignedUser });
        this.clearOutageWarnings();
        this.setState({ showSetAssignedUserModal: false });
        this.getComments();
    }

    handleStatusChanged(status) {
        this.setOutageProps({ status });
        this.clearOutageWarnings();
        this.setState({ showSetStatusModal: false });
        this.getComments();
    }

    handleServiceSettingChanged(serviceSetting) {
        this.setOutageProps({ serviceSetting });
        this.clearOutageWarnings();
        this.setState({ showSetServiceSettingModal: false });
        this.getComments();
    }

    handlePriorityChanged(priority) {
        this.setOutageProps({ priority });
        this.clearOutageWarnings();
        this.setState({ showSetPriorityModal: false });
        this.getComments();
    }

    getAddress() {
        const outage = this.props.outage;
        return outage.street + " " + outage.houseNumber + ", " + outage.city;
    }

    render() {
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            );
        }

        const outage = this.props.outage;
        let lead = null;
        if(outage) {
            if(outage.lead) {
                lead = outage.lead;
            } else if(outage.installation) {
                lead = outage.installation.lead;
            }
        }

        return (
            <React.Fragment>
                { outage !== null && (
                    <React.Fragment>
                        <OutageDetailSetAssignedUserModal
                            show={ this.state.showSetAssignedUserModal }
                            outage={ outage }
                            handleClose={ () => this.setState({ showSetAssignedUserModal: false }) }
                            handleAssignedUserChanged={ this.handleAssignedUserChanged.bind(this) }
                        />
                        <OutageDetailSetStatusModal
                            show={ this.state.showSetStatusModal }
                            outage={ outage }
                            handleClose={ () => this.setState({ showSetStatusModal: false }) }
                            handleStatusChanged={ this.handleStatusChanged.bind(this) }
                        />
                        <OutageDetailSetServiceSettingModal
                            show={ this.state.showSetServiceSettingModal }
                            outage={ outage }
                            handleClose={ () => this.setState({ showSetServiceSettingModal: false }) }
                            handleServiceSettingChanged={ this.handleServiceSettingChanged.bind(this) }
                        />
                        <OutageDetailSetPriorityModal
                            show={ this.state.showSetPriorityModal }
                            outage={ outage }
                            handleClose={ () => this.setState({ showSetPriorityModal: false }) }
                            handlePriorityChanged={ this.handlePriorityChanged.bind(this) }
                        />
                        <AppleMapsModal
                            show={ this.state.showAppleMapsModal }
                            latitude={ outage.latitude }
                            longitude={ outage.longitude }
                            title={ outage.name }
                            emptyState="Er zijn geen coördinaten beschikbaar voor deze storing."
                            handleClose={ () => this.setState({ showAppleMapsModal: false} ) }
                        />
                    </React.Fragment>
                )}

                { outage !== null && outage.warnings.map((warning, index) => (
                    <Alert variant="warning" key={ index } className="d-print-none">{ warning }</Alert>
                ))}

                <DetailOverviewQRCode
                    content={ outage ? `outage=${outage.id}` : null }
                    caption="Scan deze code in de Sales app om deze storing direct te openen op je telefoon."
                />

                <div className="card">
                    <div className="card-body">
                        <table className="table table-borderless mb-0">
                            <tbody>
                            <DetailOverviewRow title="Naam">
                                { outage ? (
                                    <ClickCopy text={ outage.name }>
                                        { outage.name }
                                    </ClickCopy>
                                ) : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Toegewezen werknemer">
                                { outage ? (
                                    <React.Fragment>
                                        { outage.assignedUser ? outage.assignedUser.name : "Niemand" }
                                        <Button variant="link" size="sm" onClick={ () => this.setState({ showSetAssignedUserModal: true })}>
                                            <i className="fas fa-pen"/>
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Status">
                                { outage ? (
                                    <React.Fragment>
                                        <TagPill color={outage.status.color}>
                                            {outage.status.name}
                                        </TagPill>
                                        <Button variant="link" size="sm" onClick={ () => this.setState({ showSetStatusModal: true })}>
                                            <i className="fas fa-pen"/>
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={120}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Type">
                                { outage ? (
                                    <React.Fragment>
                                        <TagPill color={outage.type.color}>
                                            {outage.type.name}
                                        </TagPill>
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={100}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Service">
                                { outage ? (
                                    <React.Fragment>
                                        { outage.serviceSetting ? (
                                            <TagPill color={outage.serviceSetting.color}>
                                                {outage.serviceSetting.name}
                                            </TagPill>
                                        ) : (
                                            <TagPill color="#595959">
                                                Onbepaald
                                            </TagPill>
                                        )}
                                        <Button variant="link" size="sm" onClick={ () => this.setState({ showSetServiceSettingModal: true })}>
                                            <i className="fas fa-pen"/>
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={100}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Prioriteit">
                                { outage ? (
                                    <React.Fragment>
                                        { outage.priority ? (
                                            <TagPill color={outage.priority.color}>
                                                {outage.priority.name}
                                            </TagPill>
                                        ) : (
                                            <TagPill color="#595959">
                                                Onbepaald
                                            </TagPill>
                                        )}
                                        <Button variant="link" size="sm" onClick={ () => this.setState({ showSetPriorityModal: true })}>
                                            <i className="fas fa-pen"/>
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={100}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Adres">
                                { outage ? (
                                    <React.Fragment>
                                        <ClickCopy text={ this.getAddress() }>
                                            { this.getAddress() }
                                        </ClickCopy>
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Adres openen in Google Maps</Tooltip>
                                        }>
                                            <a href={ "https://google.com/maps?q=" + encodeURIComponent(this.getAddress()) + "&t=k" }
                                               className="btn btn-link btn-sm" target="_blank" rel="noreferrer noopener">
                                                <i className="fas fa-map"/>
                                            </a>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Adres openen in Google Earth</Tooltip>
                                        }>
                                            <a href={ "https://earth.google.com/web/search/" + encodeURIComponent(this.getAddress()) }
                                               className="btn btn-link btn-sm" target="_blank" rel="noreferrer noopener">
                                                <i className="fas fa-globe-europe"/>
                                            </a>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Adres openen in Apple Maps</Tooltip>
                                        }>
                                            <Button onClick={ () => this.setState({ showAppleMapsModal: true }) } variant="link" size="sm">
                                                <i className="fab fa-apple"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Postcode">
                                { outage ? (
                                    <ClickCopy text={ outage.postalCode }>
                                        { outage.postalCode }
                                    </ClickCopy>
                                ) : <Skeleton width={75}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Telefoonnummer">
                                { lead ? (
                                    <a href={"dial:" + lead.phoneNumber}>
                                        {lead.phoneNumber}
                                    </a>
                                ) : (
                                    <Skeleton width={100}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Email">
                                { lead ? (
                                    <a href={"mailto:" + lead.email}>{lead.email}</a>
                                ) : (
                                    <Skeleton width={200}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Datum">
                                { outage ? (
                                    <DateFormatter date={outage.date}/>
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Installatie door">
                                { outage ? (
                                    outage.installationPlanningAssignedUsers && outage.installationPlanningAssignedUsers.length > 0 ?
                                        outage.installationPlanningAssignedUsers.map((user) => user.name).join(", ") : (
                                            <i className="text-muted">Niemand</i>
                                        )
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Storing bezocht door">
                                { outage ? (
                                    outage.planningAssignedUsers && outage.planningAssignedUsers.length > 0 ?
                                        outage.planningAssignedUsers.map((user) => user.name).join(", ") : (
                                            <i className="text-muted">Niemand</i>
                                        )
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Aangemaakt door">
                                { outage ? (
                                    outage.author ? outage.author.name : (
                                        <i className="text-muted">Automatisch</i>)
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Beschrijving">
                                { outage ? (
                                    outage.description && outage.description.length > 0 ? (
                                        <ReactMarkdownBreaks message={ outage.description }/>
                                    ) : (
                                        <i className="text-muted">Leeg</i>
                                    )
                                ) : (
                                    <Skeleton count={3}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow>
                                { outage ? (
                                    outage.lead ? (
                                        <Link to={ `/lead/${outage.lead.id}` } className="btn btn-secondary btn-sm">
                                            Lead openen
                                        </Link>
                                    ) : outage.installation && (
                                        <Link to={ `/installation/${outage.installation.id}` } className="btn btn-secondary btn-sm">
                                            Installatie openen
                                        </Link>
                                    )
                                ) : (
                                    <Skeleton height={30} width={100}/>
                                )}
                            </DetailOverviewRow>
                            </tbody>
                        </table>
                    </div>
                </div>
                <DetailOverviewComments
                    comments={ this.state.comments }
                    error={ this.state.errorComments }
                    errorNewComment={ this.state.errorNewComment }
                    newCommentLoading={ this.state.newCommentLoading }
                    onSubmit={ this.onAddComment }
                    commentUpdated={ this.onCommentUpdated }
                    commentDeleted={ this.onCommentDeleted }
                    emptyState="Er staan nog geen notities onder deze storing."
                />
            </React.Fragment>
        )
    }
}

export default OutageDetailOverview;
