import React, {
    useMemo,
    useState
} from "react";
import copy from "copy-to-clipboard";
import {
    CSSTransition
} from "react-transition-group";

function ClickCopy({ children, text }) {
    const [copied, setCopied] = useState(false);

    const onClick = useMemo(() => {
        return () => {
            copy(text, { format: "text/plain" });
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        }
    }, [text]);

    return (
        <span onClick={ onClick }>
            { children }
            <CSSTransition in={ copied } timeout={200} classNames="copy-fade" unmountOnExit>
                <small className="text-primary mx-2">
                    Gekopieërd
                </small>
            </CSSTransition>
        </span>
    )
}

export default React.memo(ClickCopy);
