import React from "react";
import axios from "axios";
import {
    Switch,
    Route,
    Link
} from "react-router-dom";
import {
    Alert,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    TabBarLabel,
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import TagPill from "../../components/tagPill.js";
import Loading from "../../components/Loading";
import Page404 from "../Page404";
import OverviewSortTableHeader from "../../components/overview/OverviewSortTableHeader";
import InstallationRow from "./InstallationRow";
import InstallationAdd from "./InstallationAdd";

export class InstallationsOverview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            installations: null,
            installationsSearch: "",
            lastSortKey: "",
            error: null
        };
    }

    componentDidMount() {
        this.getInstallations();

        if(window.location.search.startsWith("?search=")) {
            const searchString = decodeURI(window.location.search.split("=")[1]);
            this.setState({ installationsSearch: searchString });
            this.setSearchKey(searchString);
            this.props.history.push(window.location.pathname)
        } else {
            this.setState({ installationsSearch: this.getSearchKey() });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.pageType !== this.props.match.params.pageType) {
            this.getInstallations();
        }
    }

    getLocalStoragePrefix() {
        if(this.props.invoicing) {
            return "invoicing";
        } else {
            return "installations";
        }
    }

    getSearchKey() {
        return localStorage.getItem(this.getLocalStoragePrefix() + "SearchKey") || "";
    }

    setSearchKey(value) {
        localStorage.setItem(this.getLocalStoragePrefix() + "SearchKey", value);
    }

    getSortKey() {
        return localStorage.getItem(this.getLocalStoragePrefix() + "SortKey") || "date";
    }

    setSortKey(value) {
        localStorage.setItem(this.getLocalStoragePrefix() + "SortKey", value);
    }

    getSortAscending() {
        return (localStorage.getItem(this.getLocalStoragePrefix() + "SortAscending") || "1") === "1";
    }

    setSortAscending(value) {
        localStorage.setItem(this.getLocalStoragePrefix() + "SortAscending", value ? "1" : "0");
    }

    searchInstallations(e) {
        this.setState({ installationsSearch: e.target.value });
        this.setSearchKey(e.target.value);
    }

    sortInstallations(installations, key, ascending = true) {
        this.setState({ lastSortKey: !ascending ? "p" + key : key });
        this.setSortKey(key);
        this.setSortAscending(ascending);
        if(!installations) {
            return installations;
        }
        return installations.sort(((a, b) => {
            let aValue = a[key] && (typeof a[key] === "object" ? ("name" in a ? a[key]['name'] : a[key]['id']) : a[key]);
            aValue = typeof aValue === 'string'? aValue.toLowerCase() : aValue;
            let bValue = b[key] && (typeof b[key] === "object" ? ("name" in b ? b[key]['name'] : b[key]['id']) : b[key]);
            bValue = typeof bValue === 'string'? bValue.toLowerCase() : bValue;

            if(aValue && bValue) {
                if(aValue < bValue) {
                    return ascending ? 1 : -1;
                } else if(aValue > bValue) {
                    return ascending ? -1 : 1;
                }
            } else if(a.id < b.id) {
                return ascending? 1 : -1;
            } else {
                return ascending? -1 : 1;
            }
            return 0;
        }))
    }

    getInstallations() {
        this.setState({ installations: null, error: null });
        axios.post("/getInstallations", { filter: this.getPageType() })
            .then((response) => {
                if(response.data.valid) {
                    if(response.data.page !== undefined && response.data.page !== this.getPageType()) {
                        return;
                    }
                    const data = response.data.data.map((data) => {
                        if(data.type === null) {
                            data.type = {
                                id: -1,
                                name: "Onbepaald",
                                color: "#595959"
                            }
                        }
                        return data;
                    });
                    this.setState({ installations: this.sortInstallations(data, this.getSortKey(), this.getSortAscending()) });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    onSortInstallations(key = "date", ascending = null) {
        if(typeof ascending !== 'boolean') {
            ascending = this.state.lastSortKey !== key;
        }
        this.setState((state) => {
            return {
                installations: this.sortInstallations(state.installations, key, ascending)
            }
        });
    }

    ResetSortingAndSearching() {
        this.setState({ installationsSearch: "" });
        this.setSearchKey("");
        this.onSortInstallations("date", true);
    }

    getInstallationsTypeTitle() {
        switch(this.getPageType()) {
            case "all":
                return "Alle installaties";
            case "invoicing":
                return "Openstaande installaties";
            case "paid":
                return "Betaalde installaties";
            default:
            case "open":
                return "Openstaande installaties";
            case "finished":
                return "Afgeronde installaties";
        }
    }

    getPageType() {
        switch(this.props.match.params.pageType) {
            case "all":
                return "all";
            case "open":
                return "open";
            case "finished":
                return "finished";
            case "invoicing":
                return "invoicing";
            case "paid":
                return "paid";
            default:
                if(this.props.invoicing) {
                    return "invoicing";
                } else {
                    return "open";
                }
        }
    }

    render() {
        if(this.getPageType() === null) {
            return (
                <Page404/>
            )
        }
        return (
            <React.Fragment>
                <Helmet title={ this.getInstallationsTypeTitle() }/>

                <Title preTitle="Overzicht" noBottom>
                    { this.getInstallationsTypeTitle() }
                    <small className="ml-2">
                        <TagPill>
                            { this.state.installations ? this.state.installations.length : 0 }
                        </TagPill>
                    </small>
                </Title>

                <TabBar noBottom>
                    { this.props.invoicing ? (
                        <React.Fragment>
                            <TabBarLabel>Installaties</TabBarLabel>
                            <TabBarItem to="/invoicing/installations">
                                Openstaand
                            </TabBarItem>
                            <TabBarItem to="/invoicing/installations/paid">
                                Betaald
                            </TabBarItem>
                            <TabBarLabel className="ml-4">Leads</TabBarLabel>
                            <TabBarItem to="/invoicing/leads">
                                Openstaand
                            </TabBarItem>
                            <TabBarItem to="/invoicing/leads/paid">
                                Betaald
                            </TabBarItem>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <TabBarItem to="/installations">
                                Openstaand
                            </TabBarItem>
                            <TabBarItem to="/installations/finished">
                                Afgerond
                            </TabBarItem>
                            <TabBarItem to="/installations/all">
                                Alle
                            </TabBarItem>
                        </React.Fragment>
                    )}

                    <div className="ml-auto align-self-center">
                        <div className="input-group float-right" style={{maxWidth: 300}}>
                            <input type="search" className="search form-control"
                                   placeholder="Zoeken" value={this.state.installationsSearch} onChange={this.searchInstallations.bind(this)} />
                            <div className="input-group-append">
                                <OverlayTrigger overlay={
                                    <Tooltip id="reset">Reset</Tooltip>
                                }>
                                    <button className="btn btn-secondary" onClick={this.ResetSortingAndSearching.bind(this)}>
                                        <i className="fas fa-undo"/>
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </TabBar>

                <span className="counter float-right"></span>

                <Table hover striped size="sm" className="results">
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <OverviewSortTableHeader
                                title="#"
                                sortKey="id"
                                className="text-center"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSortInstallations.bind(this) }
                                minWidth="60px"
                            />
                            <OverviewSortTableHeader
                                title={ <i className="fas fa-exclamation-triangle"/> }
                                sortKey="warnings"
                                className="text-center"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSortInstallations.bind(this) }
                            />
                            { this.props.invoicing && (
                                <OverviewSortTableHeader
                                    title="Facturatie status"
                                    sortKey="invoicingStatus"
                                    className="text-left"
                                    currentSortKey={ this.state.lastSortKey }
                                    sortFunction={ this.onSortInstallations.bind(this) }
                                />
                            )}
                            <OverviewSortTableHeader
                                title="Status"
                                sortKey="status"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSortInstallations.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Naam"
                                sortKey="name"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSortInstallations.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Adres"
                                sortKey="street"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSortInstallations.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="BTW Terugvraag"
                                sortKey="hasVATRefund"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSortInstallations.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Aangemaakt door"
                                sortKey="author"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSortInstallations.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Datum"
                                sortKey="date"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSortInstallations.bind(this) }
                                minWidth="150px"
                            />
                        </tr>
                    </thead>

                    <tbody>
                        { this.state.error ? (
                            <tr className="loading-row">
                                <td colSpan={ 8 }>
                                    <Alert variant="danger">{ this.state.error }</Alert>
                                </td>
                            </tr>
                        ) : this.state.installations == null ? (
                            <tr className="loading-row">
                                <td colSpan={ 8 } className="py-4 text-center">
                                    <Loading/>
                                </td>
                            </tr>
                        ) : this.state.installations.length <= 0 ? (
                            <tr className="loading-row">
                                <td colSpan={ 8 } className="text-center">
                                    <h1><i className="fas fa-box-open"/></h1>
                                    <h3>Geen installaties</h3>
                                    <p>Er zijn geen installaties gevonden voor de huidige filter.</p>
                                    { this.getPageType() === "running" && (
                                        <Link to="/installations/add" className="btn btn-primary btn-sm">
                                            <i className="fas fa-plus mr-2"/>
                                            Nieuwe installatie
                                        </Link>
                                    )}
                                </td>
                            </tr>
                        ) : this.state.installations.map(installation => (
                            <InstallationRow
                                key={ installation.id }
                                installation={ installation }
                                pageType={ this.getPageType() }
                                invoicing={ this.props.invoicing }
                                searchQuery={ this.state.installationsSearch }
                                to={ "/installation/" + installation.id }
                            />
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}

function InstallationsSwitch(props) {
    return (
        <Switch>
            <Route path={`${props.match.path}/add/:leadId`} component={InstallationAdd}/>
            <Route path={`${props.match.path}/:pageType`} component={InstallationsOverview} />
            <Route path={`${props.match.path}/`} component={InstallationsOverview} />
        </Switch>
    )
}

export default React.memo(InstallationsSwitch);
