import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function PlanningItemParentStatusDot({ planningItem }) {
    if(!planningItem || !planningItem.parentStatus) {
        return null;
    }
    return (
        <OverlayTrigger placement="top" overlay={
            <Tooltip id="planningItemParentStatus">
                { planningItem.parentStatus.name }
            </Tooltip>
        }>
            <div style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: planningItem.parentStatus.color
            }}/>
        </OverlayTrigger>
    )
}

export default React.memo(PlanningItemParentStatusDot);
