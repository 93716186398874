import React, {
    useMemo
} from "react";

function PrintFormPanelLayoutDrawing() {
    const size = useMemo(() => {
        return { width: 20, height: 36 };
    }, []);

    return (
        <table className="table table-bordered">
            { [...Array(size.height)].map((element, index) => (
                <tr key={ index }>
                    { [...Array(size.width)].map((element1, index1) => (
                        <td key={ index1 } style={{ width: (100 / size.width) + "px" }}/>
                    ))}
                </tr>
            ))}
        </table>
    )
}

export default React.memo(PrintFormPanelLayoutDrawing);
