import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../components/Loading";
import InstallationProductCard from "../components/InstallationProductCard";

class InstallationDetailRemoveProductModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            inventoryItems: null,
            error: null,
            errorSave: null,
            saving: false,

            amount: 0
        }
        this.onSubmit = this.takeProduct.bind(this);
        this.onShow = this.onShow.bind(this);
    }

    onShow() {
        const product = this.props.product;
        const amount = product ? Math.min(product.amount, product.installationAmount) : 0;
        this.setState({ errorSave: null, saving: false, amount });
    }

    takeProduct() {
        const product = this.props.product;
        if(this.state.amount === 0) {
            this.setState({ errorSave: "Vul een aantal in." });
            return;
        }
        if(this.state.amount > product.amount) {
            this.setState({ errorSave: "Ingevulde aantal is meer dan er beschikbaar is in de voorraad." });
            return;
        }
        if(this.state.amount > product.installationAmount) {
            this.setState({ errorSave: "Ingevulde aantal is meer dan er oorspronkelijk was gereserveerd." });
            return;
        }

        this.setState({ saving: true, error: null });
        axios.post("/setInstallationProductAmountAsTaken", {
            installationId: this.props.installationId,
            productId: product.id,
            amount: this.state.amount
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.onTaken(response.data.reserved, response.data.taken);
                    this.props.handleClose();
                } else {
                    this.setState({ saving: false, errorSave: "Er ging iets fout bij het afnemen van het materiaal. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ saving: false, errorSave: "Er ging iets fout bij het afnemen van het materiaal." });
                console.error(error);
            });
    }

    render() {
        const product = this.props.product;
        const inInventory = product && product.amount > 0;
        return (
            <React.Fragment>
                <Modal size="lg" show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                    <Modal.Header closeButton>
                        <Modal.Title>Materiaal afnemen</Modal.Title>
                    </Modal.Header>
                    <form className="mb-0">
                        <Modal.Body>
                            { this.state.error ? (
                                <Alert variant="danger">{ this.state.error }</Alert>
                            ) : product === null ? (
                                <Loading/>
                            ) : (
                                <React.Fragment>
                                    { this.state.errorSave !== null && (
                                        <Alert variant="danger">{ this.state.errorSave }</Alert>
                                    )}
                                    <InstallationProductCard product={ product } readOnly/>

                                    { !inInventory ? (
                                        <Alert variant="warning" className="mb-0">
                                            Product is niet voorradig dus het installatie materiaal kan niet afgenomen worden.
                                        </Alert>
                                    ) : (
                                        <React.Fragment>
                                            { product.amount < product.installationAmount && (
                                                <Alert variant="warning">
                                                    Er is niet genoeg op voorraad om al het gereserveerde materiaal af te nemen.
                                                </Alert>
                                            )}
                                            <div className="form-group mb-0">
                                                <label htmlFor="amount">Aantal</label>
                                                <input type="number" className="form-control" placeholder="Aantal" id="amount"
                                                       disabled={ this.state.saving }
                                                       value={ this.state.amount }
                                                       onChange={ (event) => this.setState({ amount: event.target.value })}
                                                />
                                            </div>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.saving }>
                                { inInventory ? "Annuleer" : "Sluiten" }
                            </Button>
                            { inInventory && (
                                <Button variant="primary" onClick={ this.onSubmit } disabled={ this.state.saving || this.state.amount === 0 }>
                                    { this.state.saving && (
                                        <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                    )}
                                    Afnemen
                                </Button>
                            )}
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
        )
    }
}

export default InstallationDetailRemoveProductModal;
