import React from "react";
import axios from "axios";
import {
    Modal,
    Form,
    Button,
    Alert,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../components/Loading";

class OutageDetailSetServiceSettingModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            serviceSettings: null,
            error: null,
            errorSave: null,

            selectedServiceSetting: null,
            loading: false
        };
        this.onShow = this.onShow.bind(this);
        this.onServiceSettingSelected = this.onServiceSettingSelected.bind(this);
        this.setServiceSetting = this.setServiceSetting.bind(this);
    }

    getServiceSettings() {
        this.setState({ serviceSettings: null, error: null });
        axios.get("/getOutageServiceSettings")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ serviceSettings: response.data.data });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    setServiceSetting() {
        this.setState({ loading: true, errorSave: null });

        axios.post("setOutageServiceSetting", { outageId: this.props.outage.id, serviceSettingId: this.state.selectedServiceSetting })
            .then((response) => {
                if(response.data.valid) {
                    this.props.handleServiceSettingChanged(response.data.serviceSetting);
                } else {
                    this.setState({ loading: false, errorSave: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loading: false, errorSave: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    onShow() {
        this.setState({
            selectedServiceSetting: this.props.outage.serviceSetting,
            serviceSettings: null,
            errorSave: null,
            loading: false
        });
        this.getServiceSettings();
    }

    onServiceSettingSelected(event) {
        this.setState({ selectedServiceSetting: event.target.value });
    }

    render() {
        const initialLoading = this.state.serviceSettings === null;
        const saving = this.state.loading;
        const loading = initialLoading || saving;
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                <Modal.Header closeButton>
                    <Modal.Title>Service instelling veranderen</Modal.Title>
                </Modal.Header>
                <form className="mb-0">
                    <Modal.Body>
                        { this.state.error ? (
                            <Alert variant="danger">{ this.state.error }</Alert>
                        ) : initialLoading ? (
                            <Loading/>
                        ) : (
                            <React.Fragment>
                                { this.state.errorSave && (
                                    <Alert variant="danger">{ this.state.errorSave }</Alert>
                                )}
                                <Form.Group controlId="serviceSetting" className="mb-0">
                                    <Form.Label>Service instelling</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={ this.state.selectedServiceSetting.id }
                                        onChange={ this.onServiceSettingSelected }
                                        disabled={ loading }
                                    >
                                        { this.state.serviceSettings.map( serviceSetting => (
                                            <option key={ serviceSetting.id } value={ serviceSetting.id } style={{ backgroundColor: serviceSetting.color, color: "white" }}>
                                                { serviceSetting.name }
                                            </option>
                                        ) )}
                                    </Form.Control>
                                </Form.Group>
                            </React.Fragment>
                        ) }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ this.props.handleClose } disabled={ loading }>
                            Annuleer
                        </Button>
                        <Button variant="primary" onClick={ this.setServiceSetting } disabled={ loading }>
                            { saving && (
                                <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                            )}
                            Opslaan
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}

export default OutageDetailSetServiceSettingModal;
