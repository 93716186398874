import React from "react";
import axios from "axios";
import Moment from "moment";
import {
    extendMoment
} from "moment-range";
import {
    matchPath,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";
import TagPill from "../../components/tagPill";
import PlanningItemEditModal from "./modal/PlanningItemEditModal";
import PlanningCommentModal from "./modal/PlanningCommentModal";
import PlanningCalendarCell from "./components/PlanningCalendarCell";
import DateRangeSwitcher from "../../components/calendar/DateRangeSwitcher";

const moment = extendMoment(Moment);

class PlanningCalendar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            week: null,
            workweek: null,
            month: null,
            year: null,

            viewType: "week", // week, workweek, month

            planningItems: null,
            planningComments: null,
            error: null,

            showCommentEditModal: false,
            editModalPlanningComment: null,
            editModalPlanningCommentDate: null,

            showItemEditModal: false,
            editModalPlanningItem: null
        };
    }

    componentDidMount() {
        this.handleDateChange();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.path !== this.props.match.path || prevProps.match.params !== this.props.match.params) {
            this.handleDateChange(prevState);
        }
        if(!prevProps.showAbsences && this.props.showAbsences) {
            this.getAbsenceItems();
        }
        if(prevProps.showAvailability !== this.props.showAvailability) {
            this.getPlanningItems();
        }
    }

    handleDateChange(prevState) {
        const week = this.getWeek();
        const month = this.getMonth();
        const year = this.getYear();
        const viewType = this.getViewType();

        const switchedViewType = prevState && prevState.viewType !== viewType;
        const switchedMonth = !switchedViewType && viewType === "month" && this.state.month !== month;

        if(this.state.week !== week || this.state.month !== month || this.state.year !== year || this.state.viewType !== viewType) {
            if(this.props.selectedDate < this.momentStartDate() || this.props.selectedDate > this.momentEndDate() || switchedMonth) {
                if(viewType === "month") {
                    const thisMonth = moment().year(year).month(month);
                    if(thisMonth.year() === moment().year() && thisMonth.month() === moment().month()) {
                        this.props.handleDateChange(moment());
                    } else {
                        this.props.handleDateChange(thisMonth.startOf("month"));
                    }
                } else {
                    const thisWeek = moment().isoWeekYear(year).isoWeek(week);
                    if(thisWeek.isoWeek() === moment().isoWeek() && thisWeek.isoWeekYear() === moment().isoWeekYear()) {
                        this.props.handleDateChange(moment());
                    } else {
                        this.props.handleDateChange(thisWeek.isoWeekday(1));
                    }
                }
            }
            this.setState({
                week: this.getWeek(),
                month: this.getMonth(),
                year: this.getYear(),
                viewType: this.getViewType()
            });
            this.getPlanningItems();
            this.getPlanningComments();
            if(this.props.showAbsences) {
                this.getAbsenceItems();
            }
        }
    }

    getWeek() {
        return this.props.match.params.weekNumber === undefined ? moment().isoWeek() : parseInt(this.props.match.params.weekNumber);
    }

    getMonth() {
        return this.props.match.params.month === undefined ? moment().month() : parseInt(this.props.match.params.month) - 1;
    }

    getYear() {
        if(this.getViewType() === "month") {
            return this.props.match.params.year === undefined ? moment().year() : parseInt(this.props.match.params.year);
        } else {
            return this.props.match.params.year === undefined ? moment().isoWeekYear() : parseInt(this.props.match.params.year);
        }
    }

    getViewType() {
        const weekMatch = matchPath(this.props.match.path, { path: "/planning/week", exact: false });
        const workweekMatch = matchPath(this.props.match.path, { path: "/planning/workweek", exact: false });
        const monthMatch = matchPath(this.props.match.path, { path: "/planning/month", exact: false });
        if(weekMatch !== null) {
            return "week";
        } else if (workweekMatch !== null) {
            return "workweek";
        } else if(monthMatch !== null) {
            return "month";
        }
        return undefined;
    }

    momentStartDate() {
        if(this.getViewType() === "month") {
            const month = this.getMonth();
            const year = this.getYear();
            return moment().year(year).month(month).startOf("month").isoWeekday(1);
        } else {
            const weekNumber = this.getWeek();
            const year = this.getYear();
            return moment().isoWeekYear(year).isoWeek(weekNumber).isoWeekday(1);
        }
    }

    momentEndDate() {
        const viewType = this.getViewType();
        if(viewType === "month") {
            const month = this.getMonth();
            const year = this.getYear();
            return moment().year(year).month(month).endOf("month").isoWeekday(7);
        } else {
            const weekNumber = this.getWeek();
            const year = this.getYear();
            return moment().isoWeekYear(year).isoWeek(weekNumber).isoWeekday(viewType === "workweek" ? 5 : 7);
        }
    }

    momentGetWeeks() {
        if(this.getViewType() === "month") {
            const monthRange = moment.range(this.momentStartDate().toDate(), this.momentEndDate().toDate());
            let weeks = [];
            for (let week of monthRange.by("week")) {
                if(!weeks.includes(week.week())) {
                    weeks.push(week.week());
                }
            }
            return weeks;
        } else {
            return [this.getWeek()];
        }
    }

    getPlanningItems() {
        this.setState({ planningItems: null, error: null });
        let startDate = this.momentStartDate().format("YYYY-MM-DD");
        let endDate = this.momentEndDate().format("YYYY-MM-DD");
        axios.post("/getPlanningItems", { startDate, endDate, withAvailability: this.props.showAvailability ? 1 : 0 })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ planningItems: response.data.data });
                } else {
                    this.setState({ planningItems: null, error: "Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ planningItems: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    getPlanningComments() {
        this.setState({ planningComments: null, error: null });
        let startDate = this.momentStartDate().format("YYYY-MM-DD");
        let endDate = this.momentEndDate().format("YYYY-MM-DD");
        axios.post("/getPlanningComments", { startDate, endDate })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ planningComments: response.data.data });
                } else {
                    this.setState({ planningComments: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ planningComments: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    getAbsenceItems() {
        this.setState({ absenceItemsPerUser: null });
        let startDate = this.momentStartDate().format("YYYY-MM-DD");
        let endDate = this.momentEndDate().format("YYYY-MM-DD");
        axios.post("/getAbsenceItems", { startDate, endDate })
            .then((response) => {
                if(response.data.valid) {
                    let absenceItems = response.data.data;
                    absenceItems.sort((absenceItem1, absenceItem2) => {
                        if(absenceItem1.user.name > absenceItem2.user.name) return -1;
                        if(absenceItem1.user.name < absenceItem2.user.name) return 1;
                        return 0;
                    });
                    this.setState({ absenceItems });
                } else {
                    this.setState({ absenceItems: null, error: "Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ absenceItems: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    planningCommentUpdated(planningComment) {
        this.setState((state) => {
            let planningComments = state.planningComments;
            const index = planningComments.findIndex(arrayPlanningComment => { return arrayPlanningComment.id === planningComment.id });
            if(index === -1) {
                planningComments.push(planningComment);
            } else {
                planningComments[index] = planningComment;
            }
            return { planningComments: planningComments, showCommentEditModal: false };
        });
    }

    planningItemUpdated(planningItem) {
        this.setState((state) => {
            let planningItems = state.planningItems;
            const index = planningItems.findIndex(arrayPlanningItem => { return arrayPlanningItem.id === planningItem.id });
            planningItems[index] = planningItem;
            return { planningItems: planningItems, showItemEditModal: false };
        });
    }

    planningItemDeleted() {
        this.getPlanningItems();
        this.setState({ showItemEditModal: false });
    }

    render() {
        const weekNumber = this.state.week === null ? moment().isoWeek() : this.state.week;
        const month = this.state.month === null ? moment().month() : this.state.month;
        const year = this.state.year === null ? moment().isoWeekYear() : this.state.year;

        const baseUrl = "/planning/" + this.state.viewType;
        let date, previousDate, nextDate;
        let previousUrl = baseUrl + "/";
        let nextUrl = baseUrl + "/";
        let currentText;
        if(this.state.viewType === "month") {
            date = moment().year(year).month(month);
            previousDate = date.clone().subtract(1, "months");
            nextDate = date.clone().add(1, "months");

            previousUrl += previousDate.format("YYYY/MM");
            nextUrl += nextDate.format("YYYY/MM");
            currentText = date.format("MMMM YYYY");
        } else {
            date = moment().isoWeekYear(year).isoWeek(weekNumber);
            previousDate = date.clone().subtract(1, "weeks");
            nextDate = date.clone().add(1, "weeks");

            previousUrl += previousDate.format("GGGG/WW");
            nextUrl += nextDate.format("GGGG/WW");
            currentText = date.format("[Week] W, GGGG");
        }

        const viewType = this.getViewType();

        return (
            <React.Fragment>
                <Helmet title={ "Planning - " + currentText }/>

                <PlanningCommentModal
                    show={ this.state.showCommentEditModal }
                    planningComment={ this.state.editModalPlanningComment }
                    date={ this.state.editModalPlanningCommentDate }
                    handleClose={ () => this.setState({ showCommentEditModal: false }) }
                    planningCommentUpdated={ this.planningCommentUpdated.bind(this) }
                />
                <PlanningItemEditModal
                    show={ this.state.showItemEditModal }
                    planningItem={ this.state.editModalPlanningItem }
                    handleClose={ () => this.setState({ showItemEditModal: false }) }
                    planningItemUpdated={ this.planningItemUpdated.bind(this) }
                    planningItemDeleted={ this.planningItemDeleted.bind(this) }
                    showParent
                />

                <DateRangeSwitcher
                    baseUrl={ baseUrl }
                    previousUrl={ previousUrl }
                    nextUrl={ nextUrl }
                    currentMoment={ date }
                    previousMoment={ previousDate }
                    nextMoment={ nextDate }
                    displayFormat={ this.state.viewType === "month" ? "MMMM YYYY" : "[Week] W, GGGG" }
                    viewType={ this.state.viewType }
                />

                <table className="table results" style={{ tableLayout: "fixed" }}>
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <th className="text-center" style={{ width: "75px" }}>Week</th>
                            <th className="text-left">Maandag</th>
                            <th className="text-left">Dinsdag</th>
                            <th className="text-left">Woensdag</th>
                            <th className="text-left">Donderdag</th>
                            <th className="text-left">Vrijdag</th>
                            { viewType !== "workweek" && (
                                <React.Fragment>
                                    <th className="text-left">Zaterdag</th>
                                    <th className="text-left">Zondag</th>
                                </React.Fragment>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.error ? (
                            <tr>
                                <td colSpan={ viewType === "workweek" ? 6 : 8 }>
                                    <Alert variant="danger">{ this.state.error }</Alert>
                                </td>
                            </tr>
                        ) : (!this.state.planningItems || !this.state.planningComments || (this.props.showAbsences && !this.state.absenceItems)) ? (
                            <tr>
                                <td colSpan={ viewType === "workweek" ? 6 : 8 }>
                                    <Loading/>
                                </td>
                            </tr>
                        ) : this.momentGetWeeks().map((incrementWeekNumber, weekIndex) => {
                            let currentWeek;
                            if(viewType === "month") {
                                currentWeek = moment().format("GGGG-WW") === moment().year(year).isoWeek(incrementWeekNumber).format("GGGG-WW");
                            } else {
                                currentWeek = moment().format("GGGG-WW") === moment().isoWeekYear(year).isoWeek(incrementWeekNumber).format("GGGG-WW");
                            }
                            return (
                                <tr key={ incrementWeekNumber }>
                                    <th className="text-center">
                                    <span className="td-content-sticky" style={{ top: "127px" }}>
                                        { currentWeek ? (
                                            <TagPill>{ incrementWeekNumber }</TagPill>
                                        ) : (
                                            <b>{ incrementWeekNumber }</b>
                                        ) }
                                    </span>
                                    </th>
                                    { [...Array(viewType === "workweek" ? 5 : 7)].map((value, index) => {
                                        let momentDay;
                                        if(viewType === "month") {
                                            momentDay = moment().year(year).month(month).startOf("month").add(weekIndex, "weeks").isoWeekday(index + 1).startOf("day");
                                        } else {
                                            momentDay = moment().isoWeekYear(year).isoWeek(weekNumber).isoWeekday(index + 1).startOf("day");
                                        }
                                        return (
                                            <PlanningCalendarCell
                                                key={ index }
                                                viewType={ viewType }
                                                moment={ momentDay }
                                                showAbsences={ this.props.showAbsences }
                                                absenceItems={
                                                    !this.state.absenceItems ? null :
                                                        this.state.absenceItems.filter((absenceItem) => {
                                                            return moment(absenceItem.startDate) <= momentDay && moment(absenceItem.endDate) >= momentDay;
                                                        })
                                                }
                                                planningItems={
                                                    this.state.planningItems.filter((planningItem) => {
                                                        return planningItem.date === momentDay.format("YYYY-MM-DD");
                                                    }).sort((planningItem1, planningItem2) => {
                                                        const type1 = planningItem1.type;
                                                        const type2 = planningItem2.type;
                                                        if(type1.priority === undefined && type2.priority === undefined) {
                                                            if(type1.id > type2.id) return 1;
                                                            if(type1.id < type2.id) return -1;
                                                            return 0;
                                                        }
                                                        if(type1.priority > type2.priority) return 1;
                                                        if(type1.priority < type2.priority) return -1;
                                                        return 0;
                                                    })
                                                }
                                                planningComment={
                                                    this.state.planningComments ? this.state.planningComments.find((planningItem) => {
                                                        return planningItem.date === momentDay.format("YYYY-MM-DD");
                                                    }) : null
                                                }
                                                onClickPlanningItem={ (planningItem) => this.setState({ showItemEditModal: true, editModalPlanningItem: planningItem }) }
                                                onClickPlanningComment={ (planningComment) => this.setState({ showCommentEditModal: true, editModalPlanningComment: planningComment, editModalPlanningCommentDate: momentDay.format("YYYY-MM-DD") }) }
                                            />
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default withRouter(PlanningCalendar);
