import React from "react";
import axios from "axios";
import {
    Switch,
    Route,
    Link
} from "react-router-dom";
import {
    Alert,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    TabBarLabel,
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import LeadRow from "./LeadRow.js";
import TagPill from "../../components/tagPill.js";
import Loading from "../../components/Loading";
import Page404 from "../Page404";
import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import OverviewSortTableHeader from "../../components/overview/OverviewSortTableHeader";
import LeadAdd from "./LeadAdd";
import numberFormatter from "../../components/formatters/NumberFormatter";
import SearchBar from "../../components/overview/search/SearchBar";
import LeadStatusSearchFilter from "./components/LeadStatusSearchFilter";

export class LeadsOverview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            leadsData: null,
            leadsSearch: "",
            lastSortKey: "",
            error: null
        };
    }

    componentDidMount() {
        this.LoadLeadsData();

        if(window.location.search.startsWith("?search=")) {
            const searchParams = new URLSearchParams(window.location.search);
            const searchString = searchParams.get("search");
            this.setState({ leadsSearch: searchString });
            this.setSearchKey(searchString);
            this.props.history.push(window.location.pathname)
        } else {
            this.setState({ leadsSearch: this.getSearchKey() });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.pageType !== this.props.match.params.pageType) {
            this.LoadLeadsData();
        }
    }

    getLocalStoragePrefix() {
        if(this.props.invoicing) {
            return "invoicing";
        } else if(this.props.addOutage) {
            return "addOutage";
        } else {
            return "leads";
        }
    }

    getSearchKey() {
        return localStorage.getItem(this.getLocalStoragePrefix() + "SearchKey") || "";
    }

    setSearchKey(value) {
        localStorage.setItem(this.getLocalStoragePrefix() + "SearchKey", value);
    }

    getSortKey() {
        return localStorage.getItem(this.getLocalStoragePrefix() + "SortKey") || "date";
    }

    setSortKey(value) {
        localStorage.setItem(this.getLocalStoragePrefix() + "SortKey", value);
    }

    getSortAscending() {
        return (localStorage.getItem(this.getLocalStoragePrefix() + "SortAscending") || "1") === "1";
    }

    setSortAscending(value) {
        localStorage.setItem(this.getLocalStoragePrefix() + "SortAscending", value ? "1" : "0");
    }

    SearchLeads(searchQuery) {
        this.setState({ leadsSearch: searchQuery });
        this.setSearchKey(searchQuery);
    }

    SortLeadsData(leads, key, ascending = true) {
        this.setState({ lastSortKey: !ascending ? "p" + key : key });
        this.setSortKey(key);
        this.setSortAscending(ascending);
        if(!leads) {
            return leads;
        }
        return leads.sort(((a, b) => {
            let aValue = a[key] && (typeof a[key] === "object" ? ("name" in a ? a[key]['name'] : a[key]['id']) : a[key]);
            aValue = typeof aValue === 'string'? aValue.toLowerCase() : aValue;
            let bValue = b[key] && (typeof b[key] === "object" ? ("name" in b ? b[key]['name'] : b[key]['id']) : b[key]);
            bValue = typeof bValue === 'string'? bValue.toLowerCase() : bValue;

            if(aValue && bValue) {
                if(aValue < bValue) {
                    return ascending ? 1 : -1;
                } else if(aValue > bValue) {
                    return ascending ? -1 : 1;
                }
            } else if(a.id < b.id) {
                return ascending? 1 : -1;
            } else {
                return ascending? -1 : 1;
            }
            return 0;
        }))
    }

    LoadLeadsData() {
        this.setState({ leadsData: null, error: null });
        axios.post("/getLeads", { page: this.getPageType() })
            .then((response) => {
                if(response.data.valid) {
                    if(response.data.page !== undefined && response.data.page !== this.getPageType()) {
                        return;
                    }
                    const data = response.data.data.map((data) => {
                        if(data.type === null) {
                            data.type = {
                                id: -1,
                                name: "Onbepaald",
                                color: "#595959"
                            }
                        }
                        return data;
                    });
                    this.setState({ leadsData: this.SortLeadsData(data, this.getSortKey(), this.getSortAscending()) });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    SortLeads(key = "date", ascending = null) {
        if(typeof ascending !== 'boolean') {
            ascending = this.state.lastSortKey !== key;
        }
        this.setState((state) => {
            return {
                leadsData: this.SortLeadsData(state.leadsData, key, ascending)
            }
        });
    }

    ResetSortingAndSearching() {
        this.setState({ leadsSearch: "" });
        this.setSearchKey("");
        this.SortLeads("date", true);
    }

    getLeadsTypeTitle() {
        switch(this.getPageType()) {
            case "all":
                return "Alle leads";
            default:
            case "running":
                return "Lopende leads";
            case "expired":
                return "Verlopen leads";
            case "finished":
                return "Voltooide leads";
            case "invoicing":
                return "Openstaande leads";
            case "paid":
                return "Betaalde leads";
        }
    }

    getPageType() {
        switch(this.props.match.params.pageType) {
            case "all":
                return "all";
            case "running":
                return "running";
            case "expired":
                return "expired";
            case "finished":
                return "finished";
            case "invoicing":
                return "invoicing";
            case "paid":
                return "paid";
            case undefined:
                if(this.props.invoicing) {
                    return "invoicing";
                } else if(this.props.addOutage) {
                    return "finished";
                } else {
                    return "running";
                }
            default:
                return null;
        }
    }

    render() {
        if(this.getPageType() === null) {
            return (
                <Page404/>
            )
        }
        return (
            <React.Fragment>
                <Helmet title={ this.getLeadsTypeTitle() }/>

                { (!this.props.addOutage) && (
                    <Title
                        preTitle="Overzicht"
                        preChildren={
                            !this.props.invoicing && (
                                <div className="float-right mt-2">
                                    <OverlayTrigger overlay={
                                        <Tooltip id="reset">Nieuwe lead</Tooltip>
                                    }>
                                        <Link to="/leads/add" className="btn btn-primary">
                                            <i className="fas fa-plus fa-fw"/>
                                        </Link>
                                    </OverlayTrigger>
                                </div>
                            )
                        }
                        noBottom
                    >
                        { this.getLeadsTypeTitle() }
                        <small className="ml-2">
                            <TagPill>
                                { this.state.leadsData ? numberFormatter({ number: this.state.leadsData.length }) : 0 }
                            </TagPill>
                        </small>
                    </Title>
                )}

                <TabBar noBottom>
                    { this.props.addOutage ? "" : this.props.invoicing ? (
                        <React.Fragment>
                            <TabBarLabel>Installaties</TabBarLabel>
                            <TabBarItem to="/invoicing/installations">
                                Openstaand
                            </TabBarItem>
                            <TabBarItem to="/invoicing/installations/paid">
                                Betaald
                            </TabBarItem>
                            <TabBarLabel className="ml-4">Leads</TabBarLabel>
                            <TabBarItem to="/invoicing/leads">
                                Openstaand
                            </TabBarItem>
                            <TabBarItem to="/invoicing/leads/paid">
                                Betaald
                            </TabBarItem>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <TabBarItem to="/leads">
                                Lopend
                            </TabBarItem>
                            <TabBarItem to="/leads/finished">
                                Voltooid
                            </TabBarItem>
                            <TabBarItem to="/leads/expired">
                                Verlopen
                            </TabBarItem>
                            <TabBarItem to="/leads/all">
                                Alle
                            </TabBarItem>
                        </React.Fragment>
                    )}

                    <div className="d-flex h-100 ml-auto">
                        <LeadStatusSearchFilter
                            searchQuery={ this.state.leadsSearch }
                            setSearchQuery={ this.SearchLeads.bind(this) }
                        />
                        <SearchBar
                            searchQuery={ this.state.leadsSearch }
                            setSearchQuery={ this.SearchLeads.bind(this) }
                            onReset={ this.ResetSortingAndSearching.bind(this) }
                        />
                    </div>
                </TabBar>

                <Table hover striped size="sm" className="results">
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <OverviewSortTableHeader
                                title="#"
                                sortKey="id"
                                className="text-center"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortLeads.bind(this) }
                                minWidth="60px"
                            />
                            <OverviewSortTableHeader
                                title="Status"
                                sortKey="status"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortLeads.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Type"
                                sortKey="type"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortLeads.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Bron"
                                sortKey="source"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortLeads.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Naam"
                                sortKey="name"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortLeads.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Adres"
                                sortKey="street"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortLeads.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Datum"
                                sortKey="date"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortLeads.bind(this) }
                                minWidth="160px"
                            />
                            <OverviewSortTableHeader
                                title="Toegewezen werknemer"
                                sortKey="assignedUser"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortLeads.bind(this) }
                            />
                        </tr>
                    </thead>

                    <tbody>
                        { this.state.error ? (
                            <tr className="loading-row">
                                <td colSpan={ 8 }>
                                    <Alert variant="danger">{ this.state.error }</Alert>
                                </td>
                            </tr>
                        ) : this.state.leadsData == null ? (
                            <tr className="loading-row">
                                <td colSpan={ 8 } className="py-4 text-center">
                                    <Loading/>
                                </td>
                            </tr>
                        ) : this.state.leadsData.length <= 0 ? (
                            <tr className="loading-row">
                                <td colSpan={ 8 } className="text-center">
                                    <h1><i className="fas fa-box-open"/></h1>
                                    <h3>Geen leads</h3>
                                    <p>Er zijn geen leads gevonden voor de huidige filter.</p>
                                    { this.getPageType() === "running" && (
                                        <Link to="/leads/add" className="btn btn-primary btn-sm">
                                            <i className="fas fa-plus mr-2"/>
                                            Nieuwe lead
                                        </Link>
                                    )}
                                </td>
                            </tr>
                        ) : (
                            <AuthenticatedUserContext.Consumer>
                                { context => this.state.leadsData.map(lead => (
                                    <LeadRow
                                        key={ lead.id }
                                        lead={ lead }
                                        pageType={ this.getPageType() }
                                        currentUserId={ context.user.id }
                                        searchQuery={ this.state.leadsSearch }
                                        to={ this.props.addOutage ? "/outages/add/lead/" + lead.id : "/lead/" + lead.id }
                                    />
                                ))}
                            </AuthenticatedUserContext.Consumer>
                        )}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}

function LeadsSwitch(props) {
    return (
        <Switch>
            <Route path={`${props.match.path}/add`} component={LeadAdd} />
            <Route path={`${props.match.path}/:pageType`} component={LeadsOverview} />
            <Route path={`${props.match.path}/`} component={LeadsOverview} />
        </Switch>
    )
}

export default React.memo(LeadsSwitch);
