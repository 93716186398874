import React from "react";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../components/Helmet";

function Page404() {
    return (
        <React.Fragment>
            <Helmet title="404 Not Found"/>
            <Title preTitle="Error">404 Not Found</Title>
            <p>Deze pagina bestaat niet.</p>
        </React.Fragment>
    )
}

export default React.memo(Page404);
