import React from "react";
import axios from "axios";
import {
    Alert,
    Table
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";

import InventoryTabBar from "./components/InventoryTabBar";
import InventoryReservedProductRows from "./components/InventoryReservedProductRows";

class InventoryNeededProducts extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            reservedProducts: null,
            error: null
        }
    }

    componentDidMount() {
        this.getAllReservedProducts();
    }

    getAllReservedProducts() {
        axios.get("/getAllReservedProducts")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ reservedProducts: response.data.reservedProducts });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    render() {
        const { reservedProducts } = this.state;
        const dates = !reservedProducts ? null : Object.keys(reservedProducts);
        const productsPerDate = !reservedProducts ? null : Object.values(reservedProducts);
        return (
            <React.Fragment>
                <Helmet title="Gereserveerde producten"/>

                <Title preTitle="Overzicht" noBottom>
                    Gereserveerde producten
                </Title>

                <InventoryTabBar noBottom={ !this.state.error }/>

                { this.state.error ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : (
                    <Table>
                        <thead className="thead-light">
                            <tr className="tr-sticky">
                                <th>Datum</th>
                                <th colSpan={ 2 }>Product</th>
                                <th style={{ width: "20px" }}/>
                                <th>Type</th>
                                <th>Aantal</th>
                                <th>Voorradigheid</th>
                                <th>Installaties</th>
                            </tr>
                        </thead>
                        <tbody>
                            { reservedProducts === null ? (
                                <tr>
                                    <td colSpan={ 8 }>
                                        <Loading/>
                                    </td>
                                </tr>
                            ) : Object.keys(reservedProducts).length === 0 ? (
                                <tr>
                                    <td colSpan={ 8 } className="text-center">
                                        <h1><i className="fas fa-pallet"/></h1>
                                        <h3>Geen gereserveerde producten</h3>
                                        <p>Er zijn geen producten gereserveerd.</p>
                                    </td>
                                </tr>
                            ) : dates.map((date, index) => {
                                const products = productsPerDate[index];
                                return (
                                    <InventoryReservedProductRows
                                        date={ date }
                                        products={ products }
                                        key={ date }
                                    />
                                );
                            })}
                        </tbody>
                    </Table>
                )}
            </React.Fragment>
        )
    }

}

export default InventoryNeededProducts;
