import React from "react";
import {
    Badge,
    Button,
    ButtonGroup
} from "react-bootstrap";

function ImageGalleryStyleSwitcher({ style, setStyle }) {
    return (
        <div className="d-flex align-items-center d-print-none">
            <div className="mr-2">
                <b>Style</b>
            </div>
            <ButtonGroup size="sm">
                <Button variant="outline-secondary" active={ style === "regular" } onClick={ () => setStyle("regular") }>
                    Standaard
                </Button>
                <Button variant="outline-secondary" active={ style === "dateGrouped" } onClick={ () => setStyle("dateGrouped") }>
                    Gegroepeerd <Badge variant="dark" className="ml-1">Beta</Badge>
                </Button>
            </ButtonGroup>
        </div>
    )
}

export default React.memo(ImageGalleryStyleSwitcher);
