import React from "react";
import {
    Route,
    Switch
} from "react-router-dom";

import InventoryPage from "./InventoryPage";
import InventoryOrderSuggestions from "./InventoryOrderSuggestions";
import InventoryNeededProducts from "./InventoryNeededProducts";

function InventorySwitch(props) {
    return (
        <Switch>
            <Route path={`${props.match.path}/suggested-orders`} component={InventoryOrderSuggestions}/>
            <Route path={`${props.match.path}/reserved-products`} component={InventoryNeededProducts}/>
            <Route path={`${props.match.path}/`} component={InventoryPage}/>
        </Switch>
    )
}

export default React.memo(InventorySwitch);
