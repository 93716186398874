import React from "react";

function TagPill({ children, className = "", color, variant = "primary" }) {
    return (
        <span className={ "badge badge-pill badge-" + variant + " " + (className ?? "") }
              style={{ backgroundColor: color }}>
            { children }
        </span>
    );
}

export default React.memo(TagPill);
