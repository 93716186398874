import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../components/Loading";
import InstallationProductCard from "../components/InstallationProductCard";

class InstallationDetailUntoTakeProductModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null
        }
        this.onSubmit = this.undoTakeProduct.bind(this);
        this.onShow = this.onShow.bind(this);
    }

    onShow() {
        this.setState({ error: null, loading: false });
    }

    undoTakeProduct() {
        this.setState({ loading: true, error: null });
        axios.post("/undoInstallationProductTaken", {
            takenProductId: this.props.takenProduct.takenProductId
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.onUndo(response.data.reserved, response.data.taken);
                    this.props.handleClose();
                } else {
                    this.setState({ saving: true, error: "Er ging iets fout bij het toevoegen van het materiaal. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ saving: true, error: "Er ging iets fout bij het toevoegen van het materiaal." });
                console.error(error);
            });
    }

    render() {
        return (
            <React.Fragment>
                <Modal size="lg" show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-warning">Materiaal afnemen ongedaan maken</Modal.Title>
                    </Modal.Header>
                    <form className="mb-0">
                        <Modal.Body>
                            { this.state.error !== null && (
                                <Alert variant="danger">{ this.state.error }</Alert>
                            )}
                            { this.props.takenProduct !== null ? (
                                <React.Fragment>
                                    <p className="text-warning">
                                        Weet je zeker dat je het afnemen van het materiaal ongedaan wilt maken? Dit zal
                                        de hoeveelheid dat is afgenomen terug aan de actuele voorraad toevoegen en het
                                        product weer als gereserveerd aan de installatie toevoegen.
                                    </p>
                                    <InstallationProductCard product={ this.props.takenProduct } readOnly/>
                                </React.Fragment>
                            ) : (
                                <Loading/>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                                Annuleer
                            </Button>
                            <Button variant="primary" onClick={ this.onSubmit } disabled={ this.state.loading }>
                                { this.state.loading && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Ongedaan maken
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
        )
    }
}

export default InstallationDetailUntoTakeProductModal;
