import React from "react";

import ChartCard from "../components/ChartCard";

import LeadsCreatedCountChart from "../charts/LeadsCreatedCountChart";
import LeadStatusChangesChart from "../charts/LeadStatusChangesChart";
import LeadStatusChangesPerStatusChart from "../charts/LeadStatusChangesPerStatusChart";

function LeadStatistics({ startDate, endDate }) {
    return (
        <React.Fragment>
            <ChartCard title="Aantal leads ontvangen per dag">
                <LeadsCreatedCountChart
                    startDate={ startDate }
                    endDate={ endDate }
                />
            </ChartCard>
            <ChartCard title="Aantal keer status van leads veranderd per dag">
                <LeadStatusChangesChart
                    startDate={ startDate }
                    endDate={ endDate }
                />
            </ChartCard>
            <ChartCard title="Aantal keer status van leads veranderd per dag per status" userSelectable>
                <LeadStatusChangesPerStatusChart
                    startDate={ startDate }
                    endDate={ endDate }
                />
            </ChartCard>
        </React.Fragment>
    )
}

export default React.memo(LeadStatistics);
