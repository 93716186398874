import React from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import {
    LeadsOverview
} from "../leads/Leads";
import {
    InstallationsOverview
} from "../installations/InstallationsPage";

function InvoicingLeadsOverview(props) {
    return <LeadsOverview invoicing {...props} />;
}

function InvoicingInstallationsOverview(props) {
    return <InstallationsOverview invoicing {...props} />;
}

function InvoicingSwitch(props) {
    return (
        <Switch>
            <Route path={`${props.match.path}/installations/:pageType`} component={InvoicingInstallationsOverview} />
            <Route path={`${props.match.path}/installations`} component={InvoicingInstallationsOverview} />

            <Route path={`${props.match.path}/leads/:pageType`} component={InvoicingLeadsOverview} />
            <Route path={`${props.match.path}/leads`} component={InvoicingLeadsOverview} />

            <Route path={ props.match.path } exact>
                <Redirect
                    to={{
                        pathname: `${props.match.path}/installations`,
                        state: { from: "/" }
                    }}
                />
            </Route>
        </Switch>
    )
}

export default React.memo(InvoicingSwitch);
