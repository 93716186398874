import React from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";
import DetailOverviewRow from "../../components/detail/DetailOverviewRow";

class OutageAddInstallation extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            installation: null,
            loadError: null,
            error: null,
            showHints: false,

            types: null,

            typeId: 0,
            description: ""
        }
        this.addOutage = this.addOutage.bind(this);
    }

    componentDidMount() {
        this.getInstallation();
        this.getTypes();
    }

    getInstallation() {
        this.setState({ installation: null, loadError: null });
        axios.post("/getInstallation", { id: this.props.match.params.installationId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ installation: response.data.data });
                } else {
                    this.setState({ loadError: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    getTypes() {
        this.setState({ types: null, loadError: null });
        axios.get("/getOutageTypes")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ types: response.data.data });
                } else {
                    this.setState({ loadError: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loadError: "Er was een fout bij het ophalen van de data." });
            });
    }

    addOutage() {
        if(!this.canSubmit()) {
            this.setState({ error: "Niet alle vereiste velden zijn ingevuld of er zitten fouten in de velden.", showHints: true });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        this.setState({ error: null });
        axios.post("/addOutage", {
            installationId: this.state.installation.id,
            typeId: this.state.typeId,
            description: this.state.description
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.history.push("/outage/" + response.data.outage.id);
                } else {
                    this.setState({ error: "Er was een fout bij het opslaan van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er was een fout bij het opslaan van de data." });
            })
            .finally(() => {
                window.scroll({ top: 0, behavior: "smooth" });
            });
    }

    hasValidType() {
        return !!this.state.types.find((type) => type.id === this.state.typeId);
    }

    canSubmit() {
        return this.state.lead !== null && this.hasValidType();
    }

    render() {
        const showHints = this.state.showHints;
        return (
            <React.Fragment>
                <Helmet title="Nieuwe storing toevoegen"/>
                <Title preTitle="Overzicht">
                    Nieuwe storing toevoegen
                </Title>

                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}

                { this.state.loadError ? (
                    <Alert variant="danger">{ this.state.loadError }</Alert>
                ) : this.state.installation === null || this.state.types === null ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h4>
                                    Geselecteerde installatie
                                </h4>

                                <table className="table table-borderless mb-0">
                                    <tbody>
                                    <DetailOverviewRow title="Naam" small>
                                        { this.state.installation.lead.name }
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Adres" small>
                                        { this.state.installation.street + " " + this.state.installation.houseNumber + ", " + this.state.installation.city }
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Postcode" small>
                                        { this.state.installation.postalCode }
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Telefoonnummer" small>
                                        <a href={"dial:" + this.state.installation.lead.phoneNumber}>
                                            {this.state.installation.lead.phoneNumber}
                                        </a>
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Email" small>
                                        <a href={"mailto:" + this.state.installation.lead.email}>
                                            {this.state.installation.lead.email}
                                        </a>
                                    </DetailOverviewRow>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-body">
                                <h4>Storingsdetails</h4>
                                <div className="form-group mb-0">
                                    <label htmlFor="type">Type</label>
                                    <select className="form-control" id="type" required
                                            value={ this.state.typeId }
                                            onChange={ (event) => {
                                                this.setState({ typeId: parseInt(event.target.value) });
                                            }}
                                    >
                                        <option value={0}>Onbepaald</option>
                                        { this.state.types.map((type) => (
                                            <option key={ type.id } value={ type.id } style={{ backgroundColor: type.color, color: "white" }}>
                                                { type.name }
                                            </option>
                                        ))}
                                    </select>
                                    { showHints && !this.hasValidType() && (
                                        <span className="text-danger">
                                            Ongeldig type!
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-body">
                                <h4>Beschrijving</h4>
                                <p className="text-muted">
                                    <b>
                                        Beschrijf hier de storing. Wat is er mis? Hoe is de klant hier achter gekomen?
                                        Welk materiaal zou de storing kunnen veroorzaken?
                                    </b>
                                </p>
                                <div className="form-group mb-0">
                                    <textarea
                                        className="form-control" id="description" rows={ 8 }
                                        value={ this.state.description }
                                        onChange={ (event) => this.setState({ description: event.target.value })}
                                    />
                                </div>
                            </div>
                        </div>

                        <button className="btn btn-primary float-right" onClick={ this.addOutage }>
                            Toevoegen
                        </button>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default OutageAddInstallation;
