import React, {
    useState
} from "react";
import {
    Alert
} from "react-bootstrap";

import Loading from "../Loading";
import Comment from "../comments/Comment";
import Event from "../comments/Event";
import ImageModal from "../../pages/images/modal/ImageModal";
import DetailNewCommentField from "./DetailNewCommentField";
import CommentEditModal from "../comments/CommentEditModal";
import CommentDeleteModal from "../comments/CommentDeleteModal";
import CommentRevisionsModal from "../comments/CommentRevisionsModal";
import CommentStyleSwitcher from "../comments/CommentStyleSwitcher";

function DetailOverviewComments({ error, comments, commentUpdated, commentDeleted, onSubmit, errorNewComment, newCommentLoading,
                                emptyState = "Er staan nog geen notities onder deze lead." }) {
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const selectImage = (image) => {
        setShowImageModal(true);
        setSelectedImage(image);
    }

    const [showEditCommentModal, setShowEditCommentModal] = useState(false)
    const [editSelectedComment, setEditSelectedComment] = useState(null);
    const editComment = (comment) => {
        setShowEditCommentModal(true);
        setEditSelectedComment(comment);
    }

    const [showDeleteCommentModal, setShowDeleteCommentModal] = useState(false)
    const [deleteSelectedComment, setDeleteSelectedComment] = useState(null);
    const deleteComment = (comment) => {
        setShowDeleteCommentModal(true);
        setDeleteSelectedComment(comment);
    }

    const [showCommentRevisionsModal, setShowCommentRevisionsModal] = useState(false)
    const [revisionsSelectedComment, setRevisionsSelectedComment] = useState(null);
    const showRevisions = (comment) => {
        setShowCommentRevisionsModal(true);
        setRevisionsSelectedComment(comment);
    }

    const [style, setStyle] = useState(localStorage.getItem("commentStyle") ?? "compact");
    const setCommentStyle = (newStyle) => {
        setStyle(newStyle);
        localStorage.setItem("commentStyle", newStyle);
    }

    return (
        <div className="mt-4">
            <ImageModal
                image={ selectedImage }
                show={ showImageModal }
                handleClose={ () => setShowImageModal(false) }
            />
            <CommentEditModal
                comment={ editSelectedComment }
                show={ showEditCommentModal }
                handleClose={ () => setShowEditCommentModal(false) }
                commentUpdated={ (comment) => {
                    commentUpdated(comment);
                    setShowEditCommentModal(false);
                }}
            />
            <CommentDeleteModal
                comment={ deleteSelectedComment }
                show={ showDeleteCommentModal }
                handleClose={ () => setShowDeleteCommentModal(false) }
                commentDeleted={ () => {
                    commentDeleted();
                    setShowDeleteCommentModal(false);
                }}
            />
            <CommentRevisionsModal
                comment={ revisionsSelectedComment }
                show={ showCommentRevisionsModal }
                handleClose={ () => setShowCommentRevisionsModal(false) }
            />

            <div className="float-right">
                <CommentStyleSwitcher
                    style={ style }
                    setStyle={ setCommentStyle }
                />
            </div>
            <h4>Notities</h4>
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : comments === null ? (
                <Loading/>
            ) : comments.length === 0 ? (
                <p>{ emptyState }</p>
            ) : (
                <div className="comments-container">
                    { comments.map(comment =>
                        comment.commentType === "comment" ? (
                            <Comment
                                key={ "comment" + comment.id }
                                comment={ comment }
                                style={ style }
                                selectImage={ selectImage }
                                editComment={ () => editComment(comment) }
                                deleteComment={ () => deleteComment(comment) }
                                showRevisions={ () => showRevisions(comment) }
                            />
                        ) : comment.commentType === "event" ? (
                            <Event
                                key={ "event" + comment.id }
                                event={ comment }
                                style={ style }
                            />
                        ) : (
                            <p>Unimplemented comment type</p>
                        )
                    )}
                </div>
            )}

            <DetailNewCommentField onSubmit={ onSubmit } error={ errorNewComment } loading={ newCommentLoading }/>
        </div>
    );
}

export default React.memo(DetailOverviewComments);
