import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";
import TagPill from "../../components/tagPill";
import PlanningItemNewModal from "./modal/PlanningItemNewModal";
import DateFormatter from "../../components/formatters/DateFormatter";

class PlanningInstallationsPlanned extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            installations: null,
            error: null,

            showNewModal: false,
            newModalInstallationId: null
        };
    }

    componentDidMount() {
        this.getInstallations();
    }

    getInstallations() {
        axios.post("/getInstallations", { filter: "planned" })
            .then((response) => {
                if(response.data.valid) {
                    let installations = response.data.data;
                    const now = moment();
                    for (const [index, installation] of installations.entries()) {
                        let earliest = null;
                        let latest = null;
                        for(let planningItem of installation.planningItems) {
                            const date = moment(planningItem.date);
                            if(earliest === null || date < earliest) {
                                earliest = date;
                            }
                            if(latest === null || date > latest) {
                                latest = date;
                            }
                        }
                        installations[index].earliest = earliest;
                        installations[index].latest = latest;
                        installations[index].allInPast = installation.planningItems.length > 0 && latest < now;
                    }
                    installations.sort((installation1, installation2) => {
                        if(installation1.planningItems.length === 0 && installation2.planningItems.length !== 0) return -1;
                        if(installation1.planningItems.length !== 0 && installation2.planningItems.length === 0) return 1;
                        if(installation1.planningItems.length === 0 && installation2.planningItems.length === 0) return 0;

                        if(installation1.earliest < installation2.earliest) return -1;
                        if(installation1.earliest > installation2.earliest) return 1;
                        return 0;
                    })
                    this.setState({ installations });
                } else {
                    this.setState({ leads: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ leads: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    planningItemAdded(planningItem) {
        this.setState({ showNewModal: false });
        this.props.history.push("/planning/week/" + moment(planningItem.date).format("gggg/WW"));
    }

    render() {
        const helmet = (
            <Helmet title={ "Planning - Ingepland" }/>
        );
        if(this.state.error !== null) {
            return (
                <React.Fragment>
                    { helmet }
                    <Alert variant="danger">{this.state.error}</Alert>
                </React.Fragment>
            )
        }
        if(this.state.installations === null) {
            return (
                <React.Fragment>
                    { helmet }
                    <Loading/>
                </React.Fragment>
            )
        }
        if(this.state.installations.length === 0) {
            return (
                <div className="text-center">
                    { helmet }
                    <h1><i className="fas fa-calendar"/></h1>
                    <h3>Geen installaties</h3>
                    <p>Er zijn geen installaties ingepland.</p>
                </div>
            )
        }
        return (
            <React.Fragment>
                { helmet }
                <PlanningItemNewModal
                    show={ this.state.showNewModal }
                    installationId={ this.state.newModalInstallationId }
                    handleClose={ () => this.setState({ showNewModal: false }) }
                    planningItemAdded={ this.planningItemAdded.bind(this) }
                />
                <table className="table results">
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <th className="text-center" style={{ width: "75px" }}>#</th>
                            <th className="text-left">Status</th>
                            <th className="text-left">Naam</th>
                            <th className="text-left">Adres</th>
                            <th className="text-left">Uitvoeringsdatum(s)</th>
                            <th className="text-left"/>
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.installations.map((installation) => (
                            <tr key={ installation.id } className={ installation.allInPast ? "table-success" : "" }>
                                <th className="text-center align-middle">
                                    { installation.id }
                                </th>
                                <td className="align-middle">
                                    <TagPill color={ installation.status.color }>
                                        { installation.status.name }
                                    </TagPill>
                                </td>
                                <td className="align-middle">
                                    { installation.lead.name }
                                </td>
                                <td className="align-middle">
                                    { `${installation.street} ${installation.houseNumber}, ${installation.city}` }
                                </td>
                                <td className="align-middle">
                                    { installation.planningItems.length === 0 ? (
                                        <b>Niet ingepland!</b>
                                    ) : installation.planningItems.map((planningItem) => (
                                        <div key={ planningItem.id }>
                                            <Link to={ "/planning/week/" + moment(planningItem.date).format("gggg/WW") } style={{ color: "#212529", textDecoration: "none" }}>
                                                <DateFormatter
                                                    date={ planningItem.date }
                                                    format="DD-MM-YYYY"
                                                />
                                            </Link>
                                        </div>
                                    )) }
                                </td>
                                <td className="align-middle">
                                    <div className="btn-group float-right">
                                        <Link to={ "/installation/" + installation.id } className="btn btn-secondary btn-sm">
                                            Installatie openen
                                        </Link>
                                        <button className="btn btn-primary btn-sm" onClick={ () => this.setState({ showNewModal: true, newModalInstallationId: installation.id })}>
                                            Nogmaals inplannen
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default PlanningInstallationsPlanned;
