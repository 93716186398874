import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import * as Sentry from "@sentry/react";

import Loading from "../Loading";
import SimpleMDE from "react-simplemde-editor";

class CommentEditModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false,
            message: ""
        }
        this.onEditorChange = this.editorChange.bind(this);
        this.simpleMdeOptions = {
            spellChecker: false
        }
    }

    onShow() {
        const comment = this.props.comment;
        this.setState({
            error: null,
            message: comment.message
        });
    }

    getApiEndpoint() {
        const comment = this.props.comment;
        if(comment.leadId) {
            return "/setLeadComment";
        } else if(comment.outageId) {
            return "/setOutageComment";
        } else if(comment.installationId) {
            return "/setInstallationComment";
        } else if(comment.vatRefundId) {
            return "/setVATRefundComment";
        } else {
            Sentry.captureMessage("CommentEditModal.getApiEndpoint: Unimplemented comment parent!");
        }
    }

    getApiParameters() {
        const comment = this.props.comment;
        if(comment.leadId) {
            return { leadCommentId: comment.id };
        } else if(comment.outageId) {
            return { outageCommentId: comment.id };
        } else if(comment.installationId) {
            return { installationCommentId: comment.id };
        } else if(comment.vatRefundId) {
            return { vatRefundCommentId: comment.id };
        } else {
            Sentry.captureMessage("CommentEditModal.getApiParameters: Unimplemented comment parent!");
        }
    }

    setComment() {
        const url = this.getApiEndpoint();
        const parameters = this.getApiParameters();
        this.setState({ loading: true });
        axios.post(url, { message: this.state.message, ...parameters })
            .then((response) => {
                if(response.data.valid) {
                    if(this.props.commentUpdated) {
                        if(response.data.leadComment) {
                            this.props.commentUpdated(response.data.leadComment);
                        } else if(response.data.outageComment) {
                            this.props.commentUpdated(response.data.outageComment);
                        } else if(response.data.comment) {
                            this.props.commentUpdated(response.data.comment);
                        }
                    }
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    editorChange(value) {
        this.setState({ message: value });
    }

    render() {
        return (
            <React.Fragment>
                <Modal size="lg" show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow.bind(this) }>
                    <Modal.Header closeButton>
                        <Modal.Title>Notitie bewerken</Modal.Title>
                    </Modal.Header>
                    <form className="mb-0">
                        <Modal.Body>
                            { this.state.error !== null && (
                                <Alert variant="danger">{ this.state.error }</Alert>
                            )}
                            { this.props.comment !== null ? (
                                <SimpleMDE
                                    value={ this.state.message }
                                    onChange={ this.onEditorChange }
                                    options={ this.simpleMdeOptions }
                                />
                            ) : (
                                <Loading/>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                                Annuleer
                            </Button>
                            <Button variant="primary" onClick={ this.setComment.bind(this) } disabled={ this.state.loading }>
                                { this.state.loading && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Opslaan
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default CommentEditModal;
