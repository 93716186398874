import axios from "axios";

import DetailSetAssignedUserModal from "../../../../components/detail/modal/DetailSetAssignedUserModal";

class LeadDetailSetCompletedByUserModal extends DetailSetAssignedUserModal {
    getInitialAssignedUser() {
        return this.props.lead.completedByUser ? this.props.lead.completedByUser.id : null;
    }

    getModalTitle() {
        return "Verkocht door werknemer veranderen";
    }

    setAssignedUser(completedByUserId) {
        this.setState({ loading: true, errorSave: null });
        axios.post("/setLeadCompletedByUser", { userId: completedByUserId, leadId: this.props.lead.id })
            .then((response) => {
                if(response.data.valid) {
                    this.props.handleCompletedByUserChanged(response.data.user);
                } else {
                    this.setState({ loading: false, errorSave: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loading: false, errorSave: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }
}

export default LeadDetailSetCompletedByUserModal;
