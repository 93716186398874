import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    electronicFormatIBAN,
    friendlyFormatIBAN,
    isValidIBAN
} from "ibantools";

function IBANFormatter({ iban }) {
    const cleanIban = electronicFormatIBAN(iban);
    const valid = isValidIBAN(cleanIban);
    if(valid) {
        return (
            <React.Fragment>
                { friendlyFormatIBAN(cleanIban) }
                <OverlayTrigger placement="top" overlay={
                    <Tooltip id="iban">IBAN geldig</Tooltip>
                }>
                    <i className="fas fa-check ml-2 text-success"/>
                </OverlayTrigger>
            </React.Fragment>
        )
    }
    return (
        <span className="text-warning">
            { iban }
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="iban">IBAN mogelijk niet geldig</Tooltip>
            }>
                <i className="fas fa-exclamation-triangle ml-2"/>
            </OverlayTrigger>
        </span>
    )
}

export default React.memo(IBANFormatter);
