import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";

import DashboardCard from "../DashboardCard";
import Loading from "../../../components/Loading";
import PlanningItemListItem from "../../planning/components/PlanningItemListItem";
import AppleMapsMultipleModal from "../../../components/apple-maps/AppleMapsMultipleModal";

class DashboardPlanningWidget extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            planningItems: null,
            error: null,

            showMapModal: false,
            mapAnnotations: null
        }
    }

    componentDidMount() {
        this.getPlanning();
    }

    getPlanning() {
        this.setState({ planningItems: null, error: null });
        let date = moment().format("YYYY-MM-DD");
        axios.post("/getPlanningItems", { startDate: date, endDate: date })
            .then((response) => {
                if(response.data.valid) {
                    const planningItems = response.data.data.filter((planningItem) => {
                        if(this.props.leadOnly && planningItem.lead !== null) {
                            return true;
                        }
                        if(this.props.outageOnly && planningItem.outage !== null) {
                            return true;
                        }
                        if(this.props.installationOnly && planningItem.installation !== null) {
                            return true;
                        }
                        return false;
                    }).sort((planningItem1, planningItem2) => {
                        const type1 = planningItem1.type;
                        const type2 = planningItem2.type;
                        if(type1.priority === undefined && type2.priority === undefined) {
                            if(type1.id > type2.id) return 1;
                            if(type1.id < type2.id) return -1;
                            return 0;
                        }
                        if(type1.priority > type2.priority) return 1;
                        if(type1.priority < type2.priority) return -1;
                        return 0;
                    });

                    const mapAnnotations = planningItems.map((planningItem) => {
                        return {
                            id: planningItem.id,
                            latitude: planningItem.latitude,
                            longitude: planningItem.longitude,
                            color: planningItem.type.color,
                            title: planningItem.name
                        }
                    })

                    this.setState({ planningItems, mapAnnotations });
                } else {
                    this.setState({ planningItems: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ planningItems: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    getPlanningItemParentUrl(planningItem) {
        if(planningItem.lead) {
            return "/lead/" + planningItem.lead.id;
        } else if(planningItem.outage) {
            return "/outage/" + planningItem.outage.id;
        } else if(planningItem.installation) {
            return "/installation/" + planningItem.installation.id;
        }
        return null;
    }

    render() {
        const { leadOnly, outageOnly, installationOnly, children, ...passingProps } = this.props;
        return (
            <React.Fragment>
                <AppleMapsMultipleModal
                    show={ this.state.showMapModal }
                    handleClose={ () => this.setState({ showMapModal: false }) }
                    annotations={ this.state.mapAnnotations }
                    title={ this.props.title }
                />
                <DashboardCard { ...passingProps }>
                    <DashboardCard.BodyRegular
                        title={ this.props.title }
                        titleAccessory={
                            <Button
                                variant="secondary"
                                size="sm"
                                className="no-drag float-right"
                                onClick={ () => this.setState({ showMapModal: true }) }
                            >
                                <i className="fas fa-map fa-fw"/>
                            </Button>
                        }
                    >
                        <div className="dashboard-planning-widget-content no-drag">
                            { this.state.error ? (
                                <Alert variant="danger">{ this.state.error }</Alert>
                            ) : this.state.planningItems === null ? (
                                <div className="h-100 d-flex align-items-center">
                                    <div className="w-100">
                                        <Loading/>
                                    </div>
                                </div>
                            ) : this.state.planningItems.length === 0 ? (
                                <div className="text-center h-100 w-100 d-flex align-items-center">
                                    <div className="w-100">
                                        <h2><i className="fas fa-calendar"/></h2>
                                        <h4>Geen planning items</h4>
                                        <p>Er zijn geen planning items gepland vandaag.</p>
                                    </div>
                                </div>
                            ) : this.state.planningItems.map((planningItem) => (
                                <div key={ planningItem.id } className="dashboard-planning-widget-item">
                                    <Link
                                        to={ this.getPlanningItemParentUrl(planningItem) }
                                        style={{ color: "#212529", textDecoration: "none" }}
                                    >
                                        <PlanningItemListItem
                                            planningItem={ planningItem }
                                        />
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </DashboardCard.BodyRegular>
                    { children }
                </DashboardCard>
            </React.Fragment>
        )
    }
}

export default React.memo(DashboardPlanningWidget);
