import React from "react";

import DateFormatter from "../formatters/DateFormatter";
import DeviceTypeIcon from "./DeviceTypeIcon";
import ReactMarkdownGFM from "../ReactMarkdownGFM";
import ResizableImage from "../ResizableImage";

function Comment(props) {
    return (
        <div className={ props.style === "compact" ? "card-comment-container" : "mb-3" }>
            <div className={"card " + (props.className ? props.className : "")}>
                { props.customCardHeader }
                <div className="card-body" style={{ paddingBottom: "4px" }}>
                    <ReactMarkdownGFM message={ props.comment.message.replaceAll("\\n", "\n") }/>
                    { props.comment.images && props.comment.images.length > 0 && props.selectImage !== undefined && (
                        <div className="row no-gutters row-cols-1 row-cols-print-4 row-cols-md-4 row-cols-lg-5 row-cols-xl-8 mb-3">
                            { props.comment.images.map( image =>
                                <div className="col image-box" key={ image.id } onClick={ () => { props.selectImage(image); } }>
                                    <ResizableImage image={ image } alt={ "Image " + image.id } size="315x315"/>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="card-footer text-muted">
                    { !props.noButtons && (
                        <React.Fragment>
                            <div className="btn-group float-right" role="group">
                                { props.comment.revisions.length > 1 && (
                                    <button type="button" className="card-link btn btn-secondary btn-sm" onClick={ props.showRevisions }>
                                        <i className="fas fa-history"/> Geschiedenis
                                    </button>
                                )}
                                <button type="button" className="card-link btn btn-primary btn-sm" onClick={ props.editComment }>
                                    <i className="fas fa-edit"/> Bewerken
                                </button>
                                <button type="button" className="card-link btn btn-danger btn-sm" onClick={ props.deleteComment }>
                                    <i className="fas fa-trash-alt"/> Verwijderen
                                </button>
                            </div>
                            <div style={{height: "2px"}}/>
                        </React.Fragment>
                    ) }
                    <DeviceTypeIcon deviceType={ props.comment.deviceType } className="mr-2"/>
                    <b className="card-text">{ props.comment.user.name } </b>
                    <DateFormatter date={ props.comment.date }/>
                    { props.comment.revisions && props.comment.revisions.length > 1 && (
                        <span className="badge badge-pill badge-secondary ml-2">
                        <i className="fas fa-history"/> { props.comment.revisions.length - 1 } keer bewerkt
                    </span>
                    )}
                </div>
            </div>
        </div>
    )
}

export default React.memo(Comment);
