import React from "react";
import {
    Link
} from "react-router-dom";

import TagPill from "../../components/tagPill";
import DateFormatter from "../../components/formatters/DateFormatter";
import CurrentUserHighlight from "../../components/CurrentUserHighlight";

class OutageRow extends React.PureComponent {
    getUrl() {
        if(this.props.pageType === "all") {
            return "/outage/" + this.props.outage.id + "?from=all";
        } else {
            return "/outage/" + this.props.outage.id;
        }
    }

    getLead() {
        const outage = this.props.outage;
        if(outage.lead) {
            return outage.lead;
        } else if(outage.installation) {
            return outage.installation.lead;
        }
        return null;
    }

    getAddress() {
        const outage = this.props.outage;
        return outage.street + " " + outage.houseNumber + ", " + outage.city;
    }

    SearchActive() {
        if(this.props.searchQuery) {
            const outage = this.props.outage;
            const lead = this.getLead();

            const splitSearchQuery = this.props.searchQuery.split(" ");
            // Loop through every search query item
            for(let searchQueryIndex = 0; searchQueryIndex < splitSearchQuery.length; searchQueryIndex++) {
                const searchQuery = (splitSearchQuery[searchQueryIndex] || "").toLowerCase();
                if(searchQuery.indexOf("=") !== -1) {
                    let [searchKey, searchValue] = searchQuery.split("=");
                    switch(searchKey) {
                        case "id":
                            if(parseInt(outage.id) !== parseInt(searchValue))
                                return false;
                            break;
                        case "auteur":
                        case "author":
                            if((outage.author ? outage.author.name.replace(/\s/g,'') : "automatisch").toLowerCase().indexOf(searchValue) === -1
                                && (outage.author ? outage.author.name.replace(/\s/g,'') : "automatic").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "phonenumber":
                        case "telefoonnummer":
                        case "telefoon":
                            if(lead.phoneNumber.replace(/\s/g,'').toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "email":
                            if(lead.email.replace(/\s/g,'').toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "status":
                            if(parseInt(outage.status.id) !== parseInt(searchValue)
                            && outage.status.name.replace(/\s/g,'').toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "type":
                            if((!outage.type || parseInt(outage.type.id) !== parseInt(searchValue))
                                || outage.type.name.replace(/\s/g,'').toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "servicesetting":
                        case "serviceinstelling":
                        case "service":
                            if(parseInt(outage.serviceSetting.id) !== parseInt(searchValue)
                                && outage.serviceSetting.name.replace(/\s/g,'').toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "priority":
                        case "prioriteit":
                            let prioritySearchValue = false;
                            if((outage.priority ? outage.priority.id.toString() : "unknown").toLowerCase().indexOf(searchValue) !== -1
                                || (outage.priority ? outage.priority.id.toString() : "onbepaald").toLowerCase().indexOf(searchValue) !== -1)
                                prioritySearchValue = true;

                            if(outage.priority && outage.priority.name.replace(/\s/g,'').toLowerCase().indexOf(searchValue) !== -1)
                                prioritySearchValue = true;

                            if(!prioritySearchValue)
                                return false;

                            break;
                        case "name":
                        case "naam":
                            if(outage.name.replace(/\s/g,'').toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "address":
                        case "adres":
                            if((outage.street + outage.houseNumber + "," + outage.city).replace(/\s/g,'').toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "assigneduser":
                        case "toegewezenwerknemer":
                            let assignedUserSearchValue = false;
                            if((outage.assignedUser ? outage.assignedUser.id.toString() : "nobody").toLowerCase().indexOf(searchValue) !== -1
                                || (outage.assignedUser ? outage.assignedUser.id.toString() : "niemand").toLowerCase().indexOf(searchValue) !== -1)
                                assignedUserSearchValue = true;

                            if(outage.assignedUser && outage.assignedUser.name.replace(/\s/g,'').toLowerCase().indexOf(searchValue) !== -1)
                                assignedUserSearchValue = true;

                            if(outage.assignedUser && parseInt(outage.assignedUser.id) === parseInt(this.props.currentUserId) && (searchValue === "me" || searchValue === "ik"))
                                assignedUserSearchValue = true;

                            if(!assignedUserSearchValue)
                                return false;

                            break;
                        default:
                            return false;
                    }
                } else {
                    // Lead ID
                    if(parseInt(outage.id) === parseInt(searchQuery))
                        continue;

                    // Author
                    if((outage.author ? outage.author.name.replace(/\s/g,'') : "automatisch").toLowerCase().indexOf(searchQuery) !== -1
                        || (outage.author ? outage.author.name.replace(/\s/g,'') : "automatic").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Phone number
                    if(lead.phoneNumber.replace(/\s/g,'').toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Email
                    if(lead.email.replace(/\s/g,'').toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Type
                    if(outage.type && (parseInt(outage.type.id) === parseInt(searchQuery)
                        || outage.type.name.replace(/\s/g,'').toLowerCase().indexOf(searchQuery) !== -1))
                        continue;

                    // Status
                    if(parseInt(outage.status.id) === parseInt(searchQuery)
                        || outage.status.name.replace(/\s/g,'').toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Service Setting
                    if(parseInt(outage.serviceSetting.id) === parseInt(searchQuery)
                        || outage.serviceSetting.name.replace(/\s/g,'').toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Priority
                    if((outage.priority ? outage.priority.id.toString() : "unknown").toLowerCase().indexOf(searchQuery) !== -1
                        || (outage.priority ? outage.priority.id.toString() : "onbepaald").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;
                    if(outage.priority && outage.priority.name.replace(/\s/g,'').toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Name
                    if(outage.name.replace(/\s/g,'').toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Address
                    if((outage.street + outage.houseNumber + "," + outage.city).replace(/\s/g,'').toLowerCase().indexOf(searchQuery) !== -1)
                        continue;


                    // Assigned user id
                    if((outage.assignedUser ? outage.assignedUser.id.toString() : "nobody").toLowerCase().indexOf(searchQuery) !== -1
                        || (outage.assignedUser ? outage.assignedUser.id.toString() : "niemand").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Assigned user name
                    if(outage.assignedUser && outage.assignedUser.name.replace(/\s/g,'').toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Assigned user when its current user
                    if(outage.assignedUser && parseInt(outage.assignedUser.id) === parseInt(this.props.currentUserId) && (searchQuery === "me" || searchQuery === "ik"))
                        continue;

                    // Wanings
                    if(outage.warnings && outage.warnings.length > 0
                        && ("warning".indexOf(searchQuery) !== -1 || "waarschuwing".indexOf(searchQuery) !== -1))
                        continue;

                    return false;
                }
            }
        }
        return true;
    }

    render() {
        if(!this.SearchActive())
            return null;

        const outage = this.props.outage;
        const lead = this.getLead();

        return (
            <tr className={"table-clickable-row" + (outage.warnings && outage.warnings.length > 0 ? " table-warning" : "")}>
                <th scope="row" className="align-middle text-center">
                    <Link to={ this.getUrl() }>
                        { outage.id }
                    </Link>
                </th>
                <td className="align-middle">
                    <Link to={ this.getUrl() }>
                        <TagPill color={ outage.status.color }>
                            { outage.status.name }
                        </TagPill>
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.getUrl() }>
                        { outage.type ? (
                            <TagPill color={ outage.type.color }>
                                { outage.type.name }
                            </TagPill>
                        ) : (
                            <TagPill color="#595959">Onbepaald</TagPill>
                        )}
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.getUrl() }>
                        <TagPill color={ outage.serviceSetting.color }>
                            { outage.serviceSetting.name }
                        </TagPill>
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.getUrl() }>
                        { outage.priority ? (
                            <TagPill color={ outage.priority.color }>
                                { outage.priority.name }
                            </TagPill>
                        ) : (
                            <TagPill color="#595959">Onbepaald</TagPill>
                        )}
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.getUrl() }>
                        { lead.name }
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.getUrl() }>
                        { this.getAddress() }
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.getUrl() }>
                        <DateFormatter date={ outage.date } format="DD-MM-YYYY HH:mm"/>
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.getUrl() }>
                        <CurrentUserHighlight user={ outage.assignedUser } emptyState="Niemand"/>
                    </Link>
                </td>

            </tr>
        )
    }
}

export default OutageRow;
