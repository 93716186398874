import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../components/Loading";
import RestockOrderProductCard from "../components/RestockOrderProductCard";

class RestockOrderDetailDeliverProductModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            inventoryItems: null,
            error: null,
            errorSave: null,
            saving: false,

            amount: 0
        }
        this.onSubmit = this.deliverProduct.bind(this);
        this.onShow = this.onShow.bind(this);
    }

    onShow() {
        const product = this.props.product;
        this.setState({ errorSave: null, saving: false, amount: product.orderAmount });
    }

    deliverProduct(event) {
        if(event) {
            event.preventDefault();
        }
        const product = this.props.product;
        if(this.state.amount === 0) {
            this.setState({ errorSave: "Vul een aantal in." });
            return;
        }
        if(this.state.amount > product.orderAmount) {
            this.setState({ errorSave: "Ingevulde aantal is meer dan er oorspronkelijk was verwacht." });
            return;
        }

        this.setState({ saving: true, error: null });
        axios.post("/setRestockOrderProductAmountAsDelivered", {
            restockOrderId: this.props.restockOrderId,
            productId: product.id,
            amount: this.state.amount
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.onDelivered(response.data.expected, response.data.delivered);
                    this.props.handleClose();
                } else {
                    this.setState({ saving: false, errorSave: "Er ging iets fout bij het afnemen van het materiaal. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ saving: false, errorSave: "Er ging iets fout bij het afnemen van het materiaal." });
                console.error(error);
            });
    }

    render() {
        const product = this.props.product;
        return (
            <React.Fragment>
                <Modal size="lg" show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                    <Modal.Header closeButton>
                        <Modal.Title>Product markeren als afgeleverd</Modal.Title>
                    </Modal.Header>
                    <form className="mb-0" onSubmit={ this.onSubmit }>
                        <Modal.Body>
                            { product === null ? (
                                <Loading/>
                            ) : (
                                <React.Fragment>
                                    { this.state.errorSave !== null && (
                                        <Alert variant="danger">{ this.state.errorSave }</Alert>
                                    )}
                                    <RestockOrderProductCard product={ product } readOnly/>

                                    <div className="form-group mb-0">
                                        <label htmlFor="amount">Aantal</label>
                                        <input type="number" className="form-control" placeholder="Aantal" id="amount"
                                               disabled={ this.state.saving }
                                               value={ this.state.amount }
                                               onChange={ (event) => this.setState({ amount: event.target.value })}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.saving }>
                                Annuleer
                            </Button>
                            <Button variant="primary" onClick={ this.onSubmit } disabled={ this.state.saving || this.state.amount === 0 }>
                                { this.state.saving && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Markeer als afgeleverd
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
        )
    }
}

export default RestockOrderDetailDeliverProductModal;
