import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import TagPill from "../../../components/tagPill";
import Skeleton from "react-loading-skeleton";
import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";
import ParentSummary from "../../../components/ParentSummary";

class LeadDetailInstallations extends React.PureComponent {
    state = {
        installations: null,
        error: null
    };

    componentDidMount() {
        this.getLeadInstallations();
    }

    getLeadInstallations() {
        this.setState({ installations: null, error: null });
        axios.post("/getInstallationsForLead", { leadId: this.props.leadId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ installations: response.data.data });
                } else {
                    this.setState({ error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er was een fout bij het ophalen van de data." });
            });
    }

    render() {
        if(this.state.error !== null) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        if(this.state.installations === null || this.props.lead === null) {
            return (
                <React.Fragment>
                    {[...Array(20)].map((value, index) => (
                        <div className="mb-3" key={ index }>
                            <Skeleton height={ 100 }/>
                        </div>
                    ))}
                </React.Fragment>
            )
        }
        if(this.state.installations.length === 0) {
            return (
                <div className="text-center">
                    <h1><i className="fas fa-solar-panel"/></h1>
                    <h3>Geen installaties</h3>
                    <p>Er zijn geen installaties aangemaakt bij deze lead.</p>
                    { this.props.lead.status.finished ? (
                        <Link to={ "/installations/add/" + this.props.leadId } className="btn btn-primary btn-sm">
                            <i className="fas fa-plus mr-2"/>
                            Nieuwe installatie
                        </Link>
                    ) : (
                        <p>
                            De status van de lead moet <TagPill color="#02C235">Opdracht</TagPill> zijn om een installatie aan te maken.
                        </p>
                    )}
                </div>
            )
        }
        return (
            <React.Fragment>
                <ParentSummary { ...this.props }/>
                { this.props.lead.status.finished && (
                    <Link to={ "/installations/add/" + this.props.leadId } className="btn btn-primary btn-sm mb-3">
                        <i className="fas fa-plus mr-2"/>
                        Nieuwe installatie
                    </Link>
                )}
                { this.state.installations.map((installation) => (
                    <div className="card mb-3" key={ installation.id }>
                        <div className="card-body">
                            <h5 className="card-title">
                                Installatie #{ installation.id }
                                <div className="float-right">
                                    <small>
                                        <TagPill className="ml-2" color={ installation.status.color }>{ installation.status.name }</TagPill>
                                    </small>
                                </div>
                            </h5>
                            <ReactMarkdownBreaks message={ installation.description }/>
                            <Link to={ "/installation/" + installation.id } className="d-hidden stretched-link"/>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}

export default LeadDetailInstallations;
