import React, {
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import {
    SidebarContext,
    useWindowSize
} from "@zandor300/backoffice-framework";

function ReactGridWidthProvider(Component) {
    return function ComposedComponent(props) {
        const innerRef = useRef();
        const windowSize = useWindowSize();
        const sidebarContext = useContext(SidebarContext);

        const [width, setWidth] = useState(1280);

        useEffect(() => {
            const node = innerRef.current;
            if(!node) {
                return
            }
            if(node instanceof HTMLElement && node.offsetWidth) {
                setWidth(node.offsetWidth);
            }
        }, [windowSize, innerRef, sidebarContext]);

        return (
            <div style={{ overflowX: "hidden" }}>
                <Component
                    width={ width }
                    innerRef={ innerRef }
                    { ...props }
                />
            </div>
        )
    }
}

export default ReactGridWidthProvider;
