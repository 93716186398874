import React, {
    useContext,
    useEffect,
    useState
} from "react";
import moment from "moment";
import {
    SingleDatePicker
} from "react-dates";
import {
    ANCHOR_RIGHT
} from "react-dates/constants";
import {
    SidebarContext,
    useWindowSize
} from "@zandor300/backoffice-framework";

function RemindersDatePicker({
                                 date = moment(),
                                 focused,
                                 onDateChange,
                                 onFocusChange
}) {
    const sidebarContext = useContext(SidebarContext);
    const windowSize = useWindowSize();

    const [numberOfMonths, setNumberOfMonths] = useState(2);

    useEffect(() => {
        let newNumberOfMonths = 2;
        if(sidebarContext.mobile) {
            newNumberOfMonths = 1;
        }
        if(windowSize.width < 1000) {
            newNumberOfMonths = 1;
        }
        setNumberOfMonths(newNumberOfMonths);
    }, [sidebarContext.mobile, windowSize.width]);

    return (
        <SingleDatePicker
            id="date"
            date={ date }
            displayFormat={ () => moment.localeData().longDateFormat('L') }
            focused={ focused }
            onDateChange={ onDateChange }
            onFocusChange={ onFocusChange }
            initialVisibleMonth={ () => moment() }
            numberOfMonths={ numberOfMonths }
            horizontalMargin={ 15 }
            anchorDirection={ ANCHOR_RIGHT }
            small={ true }
        />
    )
}

export default React.memo(RemindersDatePicker);
