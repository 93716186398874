import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function PlanningDayCalendarAbsenceRow({ users, absenceItems }) {
    return (
        <tr>
            <td>Absenties</td>
            { users.map((user) => {
                const userAbsenceItems = absenceItems.filter((absenceItem) => {
                    return absenceItem.user.id === user.id;
                });
                return (
                    <td style={{ verticalAlign: "middle" }}>
                        <div className="mt-auto d-flex flex-wrap h-100 align-items-center justify-content-center">
                            { userAbsenceItems.map((absenceItem) => (
                                <div className="m-1" key={ absenceItem.id }>
                                    <OverlayTrigger placement="top" overlay={
                                        <Tooltip id="absenceItem">
                                            <b>{ absenceItem.type.name }</b>
                                            <br/>
                                            { absenceItem.description }
                                        </Tooltip>
                                    }>
                                        <div style={{
                                            width: "12px",
                                            height: "12px",
                                            borderRadius: "50%",
                                            backgroundColor: absenceItem.type.color
                                        }}/>
                                    </OverlayTrigger>
                                </div>
                            ))}
                        </div>
                    </td>
                );
            })}
        </tr>
    )
}

export default React.memo(PlanningDayCalendarAbsenceRow);
