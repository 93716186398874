import React from "react";
import {
    Alert,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    Collapse
} from "react-collapse";

import TagPill from "../../../../components/tagPill";
import CurrentUserHighlight from "../../../../components/CurrentUserHighlight";
import DateFormatter from "../../../../components/formatters/DateFormatter";
import numberFormatter from "../../../../components/formatters/NumberFormatter";

class InstallationTakenProductCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showDetails: false
        }
        this.onToggleShowDetails = this.onToggleShowDetails.bind(this);
    }

    getTotalAmountTaken() {
        let totalAmountTaken = 0;
        for(const takenProduct of this.props.products) {
            totalAmountTaken += takenProduct.installationAmount;
        }
        return totalAmountTaken;
    }

    onToggleShowDetails() {
        this.setState((prevState) => {
            return { showDetails: !prevState.showDetails }
        })
    }

    render() {
        const product = this.props.product;
        const totalAmountTaken = this.getTotalAmountTaken();
        return (
            <div className="card mb-2">
                <div className="card-body">
                    { this.state.errorSave && (
                        <Alert variant="danger">{ this.state.errorSave }</Alert>
                    )}
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <h5 className="card-title mb-0">
                                <span className="mt-2">
                                    { numberFormatter({ number: totalAmountTaken }) + "x " }
                                    { product.brand.name } { product.name }
                                </span>
                                <small className="ml-2">
                                    <TagPill color={ product.type.color } className="mr-2">
                                        { product.type.name }
                                    </TagPill>
                                </small>
                            </h5>
                        </div>

                        <div className="text-muted nowrap">Voorradig: { numberFormatter({ number: product.amount }) }</div>
                    </div>

                    <div className="d-print-none">
                        <Collapse isOpened={ this.state.showDetails }>
                            { this.getCollapsedContent() }
                        </Collapse>
                        <Button variant="link" size="sm" className="p-0" onClick={ this.onToggleShowDetails }>
                            { this.state.showDetails ? (
                                <React.Fragment>
                                    <i className="fas fa-angle-up mr-2"/>
                                    Details verbergen
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <i className="fas fa-angle-down mr-2"/>
                                    Details tonen
                                </React.Fragment>
                            )}
                        </Button>
                    </div>
                    <div className="d-none d-print-block">
                        { this.getCollapsedContent() }
                    </div>
                </div>
            </div>
        )
    }

    getCollapsedContent() {
        return (
            <div className="pt-2">
                { this.props.products.map((takenProduct) => {
                    const undoTaking = () => {
                        if(this.props.onUndo) {
                            this.props.onUndo(takenProduct);
                        }
                    }
                    return (
                        <div className="d-flex" key={ takenProduct.takenProductId }>
                            <div className="flex-grow-1 d-flex align-items-center">
                                <div>
                                    { takenProduct.installationAmount }x
                                    {" door "}
                                    <CurrentUserHighlight user={ takenProduct.author } emptyState="onbekend"/>
                                    {" op "}
                                    <DateFormatter date={ takenProduct.date }/>
                                </div>
                            </div>
                            <div>
                                <OverlayTrigger overlay={
                                    <Tooltip id="edit">Materiaal afnemen ongedaan maken</Tooltip>
                                }>
                                    <Button variant="link" className="text-danger py-0" size="sm" onClick={ undoTaking }>
                                        <i className="fas fa-undo"/>
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

}

export default InstallationTakenProductCard;
