import React from "react";
import axios from "axios";
import {
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import RestockOrderForm from "./components/RestockOrderForm";

class RestockOrderAdd extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            saving: false,

            suppliers: null,

            supplier: null,
            supplierOrderNumber: "",
            expectedDeliveryDate: "",
            description: ""
        };
        this.onSave = this.onSave.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onSave() {
        if(this.state.supplier === null) {
            this.setState({ error: "Selecteer een leverancier." });
            return;
        }
        this.setState({ error: null, saving: true });
        axios.post("/addRestockOrder", {
            supplierId: this.state.supplier.id,
            supplierOrderNumber: this.state.supplierOrderNumber,
            expectedDeliveryDate: this.state.expectedDeliveryDate,
            description: this.state.description
        })
            .then((response) => {
                if(response.data.valid) {
                    const restockOrder = response.data.restockOrder;
                    this.props.history.push("/order/" + restockOrder.id);
                } else {
                    this.setState({ saving: false, error: "Er was een fout bij het opslaan van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ saving: false, error: "Er was een fout bij het opslaan van de data." });
            });
    }

    onChange(state) {
        this.setState({ ...state });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet title="Nieuwe bestelling toevoegen"/>

                <Title preTitle="Overzicht">
                    Nieuwe bestelling toevoegen
                </Title>
                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}
                <RestockOrderForm
                    restockOrder={ this.state }
                    setRestockOrder={ this.onChange }
                    onSave={ this.onSave }
                    saving={ this.state.saving }
                    add
                />
            </React.Fragment>
        )
    }
}

export default withRouter(RestockOrderAdd);
