import React from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import RestockOrderForm from "../components/RestockOrderForm";

class RestockOrderDetailEdit extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            success: false,
            saving: false,

            restockOrder: null
        };
        this.onSave = this.onSave.bind(this);
        this.setRestockOrderState = this.setRestockOrderState.bind(this);
    }

    componentDidMount() {
        if(this.props.restockOrder !== null) {
            this.setState({ restockOrder: this.props.restockOrder });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.restockOrder !== prevProps.restockOrder) {
            this.setState({ restockOrder: this.props.restockOrder });
        }
    }

    setRestockOrderState(state) {
        this.setState((prevState) => {
            return { restockOrder: { ...prevState.restockOrder, ...state } }
        })
    }

    onSave() {
        const restockOrder = this.state.restockOrder;
        this.setState({ error: null, success: false, saving: true });
        axios.post("/setRestockOrder", {
            restockOrderId: restockOrder.id,

            supplierId: restockOrder.supplier.id,
            supplierOrderNumber: restockOrder.supplierOrderNumber,
            expectedDeliveryDate: restockOrder.expectedDeliveryDate,
            description: restockOrder.description
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.setRestockOrder(response.data.restockOrder);
                    this.setState({ success: true });
                } else {
                    this.setState({ error: "Er was een fout bij het opslaan van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er was een fout bij het opslaan van de data." });
            })
            .finally(() => {
                this.setState({ saving: false });
                window.scroll({ top: 0, behavior: "smooth" });
            });
    }

    render() {
        const restockOrder = this.state.restockOrder;
        return (
            <React.Fragment>
                { this.state.success && (
                    <Alert variant="success">Wijzigingen zijn opgeslagen.</Alert>
                )}
                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}
                { !restockOrder ? (
                    <Loading/>
                ) : (
                    <RestockOrderForm
                        restockOrder={ restockOrder }
                        setRestockOrder={ this.setRestockOrderState }
                        onSave={ this.onSave }
                        saving={ this.state.saving }
                    />
                )}
            </React.Fragment>
        )
    }
}

export default RestockOrderDetailEdit;
