import React from "react";
import {
    Alert,
    Button
} from "react-bootstrap";

import PaginationBar from "../PaginationBar";
import Loading from "../Loading";
import ImageGallery from "../../pages/images/ImageGallery";
import ParentSummary from "../ParentSummary";
import UploadImageModal from "./modal/UploadImageModal";
import ImageGalleryDateGrouped from "./ImageGalleryDateGrouped";
import ImageGalleryStyleSwitcher from "../images/ImageGalleryStyleSwitcher";

class DetailImages extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            images: null,
            error: null,
            totalAmount: null,

            page: 0,
            amountPerPage: 30,

            showImageUploadModal: false,
            viewStyle: "dateGrouped"
        };
        this.setPage = this.setPage.bind(this);
        this.onImageUploaded = this.onImageUploaded.bind(this);
        this.onImageRemoved = this.onImageRemoved.bind(this);
        this.onClickImageUpload = this.onClickImageUpload.bind(this);
        this.handleImageUploadClose = this.handleImageUploadClose.bind(this);
        this.setViewStyle = this.setViewStyle.bind(this);
    }

    componentDidMount() {
        this.getImages(0);
    }

    onImageUploaded() {
        this.getImages(this.state.page);
    }

    onImageRemoved() {
        this.getImages(this.state.page);
    }

    getImages(page) {
        throw new Error("DetailImages.getImages(..) not implemented!");
    }

    setPage(page) {
        this.setState({ page: page - 1 });
        this.getImages(page - 1);
    }

    getEmptyStateDescription() {
        return (
            <p>getEmptyStateDescription() not implemented!</p>
        )
    }

    canUploadImages() {
        return false;
    }

    getUploadRequestParameters() {
        return {};
    }

    onClickImageUpload() {
        this.setState({ showImageUploadModal: true });
    }

    handleImageUploadClose() {
        this.setState({ showImageUploadModal: false });
    }

    setViewStyle(style) {
        this.setState({ viewStyle: style });
    }

    render() {
        const {
            images,
            error,
            totalAmount,

            page,
            amountPerPage,

            showImageUploadModal,
            viewStyle
        } = this.state;
        const canUploadImages = images !== null && this.canUploadImages();
        return (
            <React.Fragment>
                { (totalAmount !== null && totalAmount > amountPerPage) && (
                    <PaginationBar
                        page={ page + 1 }
                        pagesAmount={ Math.ceil(totalAmount / amountPerPage) }
                        onPageChange={ this.setPage }
                    />
                )}
                { canUploadImages && (
                    <UploadImageModal
                        show={ showImageUploadModal }
                        handleClose={ this.handleImageUploadClose }
                        imageUploaded={ this.onImageUploaded }
                        requestParameters={ this.getUploadRequestParameters() }
                    />
                )}

                { error !== null ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : images === null ? (
                    <Loading/>
                ) : images.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-images"/></h1>
                        <h3>Geen foto's</h3>
                        { this.getEmptyStateDescription() }
                        { canUploadImages && (
                            <Button variant="primary" onClick={ this.onClickImageUpload } size="sm">
                                <i className="fas fa-plus mr-2"/>
                                Foto uploaden
                            </Button>
                        )}
                    </div>
                ) : (
                    <React.Fragment>
                        { canUploadImages && (
                            <Button variant="primary" onClick={ this.onClickImageUpload } size="sm" className="mb-3">
                                <i className="fas fa-plus mr-2"/>
                                Foto uploaden
                            </Button>
                        )}
                        <ParentSummary { ...this.props }/>

                        <div className="float-right">
                            <ImageGalleryStyleSwitcher
                                style={ viewStyle }
                                setStyle={ this.setViewStyle }
                            />
                        </div>

                        { viewStyle === "dateGrouped" ? (
                            <ImageGalleryDateGrouped
                                images={ images }
                                onImageRemoved={ this.onImageRemoved }
                            />
                        ) : (
                            <ImageGallery
                                images={ images }
                                onImageRemoved={ this.onImageRemoved }
                            />
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default DetailImages;
