import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";

import Loading from "../../../components/Loading";

function RestockOrderForm({ restockOrder, setRestockOrder, onSave, saving = false, add = false }) {
    const [suppliers, setSuppliers] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setError(null);
        axios.get("/getSuppliers")
            .then((response) => {
                if(response.data.valid) {
                    const suppliers = response.data.data.sort((supplier1, supplier2) => supplier1.name < supplier2.name ? -1 : 1);
                    setSuppliers(suppliers);
                } else {
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, []);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!suppliers) {
        return (
            <Loading/>
        )
    }

    return (
        <React.Fragment>
            <div className="card mb-3">
                <div className="card-body">
                    <h4>Bestelling info</h4>
                    <div className="form-group">
                        <label htmlFor="supplier">Leverancier</label>
                        <select
                            className="form-control" id="supplier" required
                            disabled={ saving }
                            value={ restockOrder.supplier ? restockOrder.supplier.id : 0 }
                            onChange={ (event) => {
                                setRestockOrder({ supplier: parseInt(event.target.value) === 0 ? null : suppliers.find((supplier) => supplier.id === parseInt(event.target.value)) });
                            }}
                        >
                            { add && (
                                <option value={ 0 }/>
                            )}
                            { suppliers.map((supplier) => (
                                <option key={ supplier.id } value={ supplier.id } style={{ backgroundColor: supplier.color, color: "white" }}>
                                    { supplier.name }
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="supplierOrderNumber">Ordernummer leverancier</label>
                        <input
                            type="text" className="form-control" id="supplierOrderNumber" required
                            disabled={ saving }
                            value={ restockOrder.supplierOrderNumber }
                            onChange={ (event) => setRestockOrder({ supplierOrderNumber: event.target.value })}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="expectedDeliveryDate">Verwachte leverdatum</label>
                        <input
                            type="date" className="form-control" id="expectedDeliveryDate" required
                            disabled={ saving }
                            value={ restockOrder.expectedDeliveryDate }
                            onChange={ (event) => setRestockOrder({ expectedDeliveryDate: event.target.value })}
                        />
                    </div>

                    <div className="form-group mb-0">
                        <label htmlFor="expectedDeliveryDate">Beschrijving</label>
                        <textarea
                            className="form-control" id="description" rows={ 3 }
                            disabled={ saving }
                            value={ restockOrder.description }
                            onChange={ (event) => setRestockOrder({ description: event.target.value })}
                        />
                    </div>
                </div>
            </div>

            <Button variant="primary" className="float-right" onClick={ onSave } disabled={ saving }>
                { saving && (
                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                )}
                Opslaan
            </Button>

        </React.Fragment>
    )
}

export default React.memo(RestockOrderForm);
