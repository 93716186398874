import React from "react";
import ReactMarkdown from "react-markdown";
import breaks from "remark-breaks";

import ProductAvailabilityIcon from "../pages/installations/detail/components/ProductAvailabilityIcon";

function ReactMarkdownBreaks({ message, noLinks = false }) {
    let disallowedElements = ["table", "h1", "h2", "h3", "h4", "h5", "h6"];
    if(noLinks) {
        disallowedElements.push("a");
    }
    return (
        <div style={{ marginBottom: "-16px" }}>
            <ReactMarkdown
                plugins={[breaks]}
                unwrapDisallowed
                disallowedElements={ disallowedElements }
                components={{
                    a: ({
                            node,
                            href,
                            children,
                            ...linkProps
                    }) => (
                        <a
                            href={ href }
                            target="_blank"
                            rel="noopener noreferrer"
                            { ...linkProps }
                        >
                            { children }
                        </a>
                    ),
                    strong: ({
                                 node,
                                 children,
                                 ...strongProps
                    }) => {
                        if(Array.isArray(children)) {
                            for(const child of children) {
                                if(!(typeof child === "string" || child instanceof String)) {
                                    continue;
                                }
                                if(!child.startsWith("AVAILABILITY:")) {
                                    continue;
                                }
                                const availability = child.split(":")[1];
                                return (
                                    <ProductAvailabilityIcon availability={ availability }/>
                                )
                            }
                        }
                        return (
                            <b { ...strongProps }>
                                { children }
                            </b>
                        )
                    }
                }}
            >
                { message }
            </ReactMarkdown>
        </div>
    )
}

export default React.memo(ReactMarkdownBreaks);
