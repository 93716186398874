import React from "react";

import TagPill from "../../../components/tagPill.js";

function AbsenceUserItem(props) {
    const absenceSpanDays = Math.max(Math.min(props.endDay - props.startDay, 5), 1);
    return (
        <td colSpan={absenceSpanDays} onClick={props.onClick}>
            <div className="d-flex">
                { props.moreOnPrevWeek && (
                    <div className="card-arrow-left" />
                )}
                <div className={"card flex-grow-1 pointer-cursor" + (props.moreOnPrevWeek ? " left-border-0" : "") + (props.moreOnNextWeek ? " right-border-0" : "")}>
                    <div className="card-body p-1">
                        <TagPill color={props.absenceItem.type.color}>
                            {props.absenceItem.type.name}
                        </TagPill>

                        <br/>
                        <b>{props.absenceItem.user.name}</b>
                        <br/>
                        {props.absenceItem.description}
                    </div>
                </div>
                { props.moreOnNextWeek && (
                    <div className="card-arrow-right" />
                )}
            </div>
        </td>
    )
}

export default React.memo(AbsenceUserItem);
