import React from "react";
import InventoryHistoryContent from "../../inventory/components/InventoryHistoryContent";

function ProductDetailHistory({ productId }) {
    return (
        <InventoryHistoryContent
            productId={ productId }
        />
    );
}

export default React.memo(ProductDetailHistory);
