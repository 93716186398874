import React, {
    useContext
} from "react";
import {
    NavDropdown
} from "react-bootstrap";
import {
    Navbar
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "./../context/AuthenticatedUserContext";

function IntranetNavbar() {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    const user = authenticatedUserContext.user;

    return (
        <Navbar>
            <NavDropdown title={ user && user.name } id="user">
                <NavDropdown.Item onClick={ authenticatedUserContext.logoutFunction }>Uitloggen</NavDropdown.Item>
            </NavDropdown>
        </Navbar>
    )
}

export default React.memo(IntranetNavbar);
