import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import ProductDetailReservedRow from "./components/ProductDetailReservedRow";
import NumberFormatter from "../../../components/formatters/NumberFormatter";

class ProductDetailReserved extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            reserved: null,
            toOrderUntilDates: null,
            amountNotPlannedIn: null,
            error: null
        }
    }

    componentDidMount() {
        this.getReserved();
    }

    getReserved() {
        axios.post("/getReservedOfProduct", { productId: this.props.productId })
            .then((response) => {
                if(response.data.valid) {
                    const {
                        reserved,
                        amountNotPlannedIn
                    } = response.data;
                    this.setState({
                        reserved,
                        amountNotPlannedIn,
                        toOrderUntilDates: this.generateTotalToOrderUntilDate(reserved)
                    });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    generateTotalToOrderUntilDate(reserved) {
        let toOrder = 0;
        let toOrderUntilDates = {};
        for(const [date, entries] of Object.entries(reserved)) {
            for(const entry of entries) {
                if(entry.fulfilled) continue;
                toOrder += entry.amount;
            }
            toOrderUntilDates[date] = toOrder;
        }
        return toOrderUntilDates;
    }

    render() {
        return (
            <React.Fragment>
                { this.props.product && (
                    <div className="row my-4">
                        <div className="col text-center">
                            <div>
                                <h5 className="my-0">Aantal gereserveerd</h5>
                            </div>
                            <div>
                                <h4 className="my-0">
                                    <NumberFormatter number={ this.props.product.amountReserved }/>
                                </h4>
                            </div>
                        </div>
                        <div className="col text-center">
                            <div>
                                <h5 className="my-0">Aantal besteld</h5>
                            </div>
                            <div>
                                <h4 className="my-0">
                                    <NumberFormatter number={ this.props.product.amountExpected }/>
                                </h4>
                            </div>
                        </div>
                    </div>
                )}
                <Table>
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <th>
                                Datum
                            </th>
                            <th className="text-right">
                                Cumulatief benodigd
                            </th>
                            <th className="text-right">
                                Gereserveerd
                            </th>
                            <th>
                                Voorraad
                            </th>
                            <th>
                                Installaties
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.error ? (
                            <tr>
                                <td colSpan={ 5 }>
                                    <Alert variant="danger">{ this.state.error }</Alert>
                                </td>
                            </tr>
                        ) : !this.state.reserved ? (
                            <tr>
                                <td colSpan={ 5 }>
                                    <Loading/>
                                </td>
                            </tr>
                        ) : Object.keys(this.state.reserved).length === 0 ? (
                            <tr>
                                <td colSpan={ 5 } className="text-center">
                                    <h1><i className="fas fa-inventory"/></h1>
                                    <h3>Niet gereserveerd</h3>
                                    <p className="mb-0">
                                        Dit product is momenteel niet gereserveerd in een ingeplande installatie.
                                    </p>
                                    { this.state.amountNotPlannedIn > 0 && (
                                        <React.Fragment>
                                            <p>
                                                Er zijn wel <NumberFormatter number={ this.state.amountNotPlannedIn }/> stuks gereserveerd in niet-ingeplande installaties.
                                            </p>
                                            <Link
                                                to={`${this.props.urlPrefix}/installations-reserved`}
                                                className="btn btn-primary"
                                            >
                                                Installaties bekijken
                                            </Link>
                                        </React.Fragment>
                                    )}
                                </td>
                            </tr>
                        ) : (
                            <React.Fragment>
                                { Object.keys(this.state.reserved).map((date) => {
                                    const values = this.state.reserved[date];
                                    const toOrder = this.state.toOrderUntilDates[date];
                                    return (
                                        <ProductDetailReservedRow
                                            key={ date }
                                            date={ date }
                                            values={ values }
                                            toOrder={ toOrder }
                                        />
                                    )
                                })}
                                { this.state.amountNotPlannedIn > 0 && (
                                    <tr>
                                        <td colSpan={ 2 }>
                                            <b>Niet ingepland</b>
                                        </td>
                                        <td className="text-right">
                                            <NumberFormatter number={ this.state.amountNotPlannedIn }/>
                                        </td>
                                        <td/>
                                        <td>
                                            <Link to={`${this.props.urlPrefix}/installations-reserved`}>
                                                Bekijken
                                            </Link>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        )}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

}

export default ProductDetailReserved;
