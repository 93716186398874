import React from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../components/detail/DetailOverviewRow";
import DateFormatter from "../../../components/formatters/DateFormatter";
import UserActivitySummary from "./components/UserActivitySummary";
import UserActivityHeatmap from "./components/UserActivityHeatmap";

function UserDetailOverview({ user }) {
    return (
        <React.Fragment>
            <div className="card mb-3">
                <div className="card-body">
                    <table className="table table-borderless mb-0">
                        <tbody>
                        <DetailOverviewRow title="Naam">
                            { user ? user.name : <Skeleton width={100}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Email">
                            { user ? (
                                !user.email || user.email.length === 0 ? (
                                    <i className="text-muted">Geen email</i>
                                ) : (
                                   <a href={ `mailto:${user.email}` }>
                                       { user.email }
                                   </a>
                                )
                            ) : <Skeleton width={250}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Geboortedatum">
                            { user ? (
                                !user.birthday ? (
                                    <i className="text-muted">Onbekend</i>
                                ) : (
                                   <DateFormatter date={ user.birthday } format="DD-MM-YYYY"/>
                                )
                            ) : <Skeleton width={100}/> }
                        </DetailOverviewRow>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="d-flex justify-content-center my-5">
                <div className="w-100" style={{ maxWidth: "900px" }}>
                    <UserActivityHeatmap
                        user={ user }
                        startDate={ moment().subtract(12, "months").format("YYYY-MM-DD") }
                        endDate={ moment().format("YYYY-MM-DD") }
                    />
                </div>
            </div>

            <h3>Activiteit</h3>
            <UserActivitySummary
                user={ user }
            />
        </React.Fragment>
    )
}

export default React.memo(UserDetailOverview);
