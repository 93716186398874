import React from "react";

function SortIcon({ sortKey, currentSortKey }) {
    return (
        <div>
            { sortKey === currentSortKey ? (
                <i className="fad fa-sort-down"/>
            ) : "p" + sortKey === currentSortKey ? (
                <i className="fad fa-sort-up"/>
            ) : (
                <i className="fad fa-sort"/>
            )}
        </div>
    )
}

export default React.memo(SortIcon);
