import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import DetailPlanning from "../../../components/detail/DetailPlanning";
import TagPill from "../../../components/tagPill";

class LeadDetailPlanning extends DetailPlanning {
    getPlanningItems() {
        this.setState({ planningItems: null, error: null });
        axios.post("/getPlanningItemsForLead", { leadId: this.props.leadId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ planningItems: this.sortPlanningItems(response.data.data) });
                } else {
                    this.setState({ planningItems: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ planningItems: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    onPlanningItemAddedDeleted() {
        if(this.props.refreshLead) {
            this.props.refreshLead();
        }
    }

    showNewButton() {
        return this.props.lead && this.props.lead.status.finished;
    }

    getEmptyStateDescription() {
        return (
            <React.Fragment>
                <p>Er zijn nog geen planning items voor deze lead.</p>
                { this.props.lead && !this.props.lead.status.finished && (
                    <p>
                        De status van de lead moet <TagPill color="#02C235">Opdracht</TagPill> zijn om een planning item toe te kunnen voegen.
                    </p>
                )}
            </React.Fragment>
        )
    }

    getAlerts() {
        return [
            <Alert variant="warning">
                <b>Let op!</b> Bij nieuw verkochte leads moet de{" "}
                <Link to={ "/lead/" + this.props.leadId + "/installations" }>installatie</Link>{" "}
                ingepland worden. <b>Niet de lead inplannen!</b>
            </Alert>
        ];
    }

    isLoading() {
        return this.state.planningItems === null || this.props.lead === null;
    }
}

export default LeadDetailPlanning;
