import React from "react";
import Skeleton from "react-loading-skeleton";
import {
    Alert
} from "react-bootstrap";

import TagPill from "../../../components/tagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";
import DetailOverviewRow from "../../../components/detail/DetailOverviewRow";

import DetailOverviewQRCode from "../../../components/detail/DetailOverviewQRCode";
import RestockOrderDetailProducts from "./RestockOrderDetailProducts";
import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";
import ClickCopy from "../../../components/copy/ClickCopy";

class RestockOrderDetailOverview extends React.PureComponent {
    setRestockOrderProps(props) {
        this.props.setRestockOrder((prevRestockOrder) => {
            return { ...prevRestockOrder, ...props };
        })
    }

    render() {
        const restockOrder = this.props.restockOrder;

        return (
            <React.Fragment>
                { restockOrder && restockOrder.warnings && restockOrder.warnings.map((warning, index) => (
                    <Alert variant="warning" key={ index } className="d-print-none">{ warning }</Alert>
                ))}

                <DetailOverviewQRCode
                    content={ restockOrder ? `restockOrder=${restockOrder.id}` : null }
                    caption="Scan deze code in de Voorraad app om deze bestelling direct te openen op je telefoon."
                />

                <div className="card">
                    <div className="card-body">
                        <table className="table table-borderless mb-0">
                            <tbody>
                            <DetailOverviewRow title="Afgeleverd">
                                { restockOrder ? (
                                    <React.Fragment>
                                        { restockOrder.delivered ? (
                                            <TagPill variant="success">
                                                Geleverd
                                            </TagPill>
                                        ) : (
                                            <TagPill variant="danger">
                                                In afwachting
                                            </TagPill>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={200}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Leverancier">
                                { restockOrder ? (
                                    <TagPill color={restockOrder.supplier.color}>
                                        {restockOrder.supplier.name}
                                    </TagPill>
                                ) : (
                                    <Skeleton width={200}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Ordernummer leverancier">
                                { restockOrder ? (
                                    restockOrder.supplierOrderNumber.trim().length === 0 ? (
                                        <i className="text-muted">Niet ingevuld</i>
                                    ) : (
                                        <ClickCopy text={ restockOrder.supplierOrderNumber }>
                                            { restockOrder.supplierOrderNumber }
                                        </ClickCopy>
                                    )
                                ) : (
                                    <Skeleton width={200}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Besteldatum">
                                { restockOrder ? (
                                    <DateFormatter date={restockOrder.date}/>
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title={ (restockOrder && restockOrder.delivered) ? "Leverdatum" : "Verwachte leverdatum" }>
                                { restockOrder ? (
                                    <DateFormatter date={restockOrder.delivered ? restockOrder.deliveryDate : restockOrder.expectedDeliveryDate}/>
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Aangemaakt door">
                                { restockOrder ? (
                                    restockOrder.author ? restockOrder.author.name : (
                                        <i className="text-muted">Automatisch</i>)
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Beschrijving">
                                { restockOrder ? (
                                    restockOrder.description && restockOrder.description.length > 0 ? (
                                        <ReactMarkdownBreaks message={ restockOrder.description }/>
                                    ) : (
                                        <i className="text-muted">Leeg</i>
                                    )
                                ) : (
                                    <Skeleton count={3}/>
                                )}
                            </DetailOverviewRow>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="mt-3">
                    <RestockOrderDetailProducts
                        restockOrderId={ this.props.restockOrderId }
                        onChange={ this.props.getRestockOrder }
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default RestockOrderDetailOverview;
