import axios from "axios";

import BaseAddProductModal from "../../../../components/modal/BaseAddProductModal";

class RestockOrderDetailAddProductModal extends BaseAddProductModal {
    abstractGetTitle() {
        return "Product toevoegen";
    }

    abstractSave(productId, amount, onComplete, onError) {
        axios.post("/addRestockOrderProduct", {
            restockOrderId: this.props.restockOrderId,
            productId,
            amount
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.onSave(response.data.expected, response.data.delivered);
                    onComplete();
                } else {
                    onError("Er ging iets fout bij het toevoegen van het materiaal. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                onError("Er ging iets fout bij het toevoegen van het product.");
                console.error(error);
            });
    }
}

export default RestockOrderDetailAddProductModal;
