import React, {
    useState
} from "react";
import {
    Modal
} from "react-bootstrap";

function ChartModal({ show, handleClose, title, children, userSelectable, users, selectedUser, onUserSelect }) {
    const [showing, setShowing] = useState(false);
    return (
        <Modal size="fullscreen" show={ show } onHide={ handleClose } onShow={ () => setShowing(true) } onExited={ () => setShowing(false) }>
            <Modal.Header closeButton>
                <Modal.Title className="flex-grow-1">
                    <i className="fas fa-chart-bar ml-2 mr-3"/>
                    { title }
                </Modal.Title>
                { userSelectable && (
                    <div>
                        <select
                            className="form-control"
                            value={ selectedUser === null ? -1 : selectedUser }
                            onChange={ onUserSelect }
                        >
                            <option value={ -1 }>Iedereen</option>
                            { users.map((user) => (
                                <option key={ user.id } value={ user.id }>
                                    { user.name }
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="h-100">
                    { showing && children }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default React.memo(ChartModal);
