import React from "react";
import {
    Alert
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import {
    LeadsOverview
} from "../leads/Leads";

function OutageAddStep1(props) {
    return (
        <React.Fragment>
            <Title preTitle="Overzicht">
                Nieuwe storing toevoegen - Stap 1
            </Title>

            <Alert variant="warning">
                <b>Je bent nu een storing bij een lead aan het maken.</b> Om een storing bij een installatie aan te
                maken, ga je eerst naar de installatie en dan naar het tabblad installaties.
            </Alert>

            <LeadsOverview addOutage {...props} />
            <Helmet title="Nieuwe storing toevoegen"/>
        </React.Fragment>
    );
}

export default React.memo(OutageAddStep1);
