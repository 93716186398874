import React from "react";
import {
    Button,
    Modal
} from "react-bootstrap";

import InventoryHistoryContent from "./components/InventoryHistoryContent";

function InventoryHistoryModal(props) {
    const product = props.product;
    return (
        <Modal show={ props.show } onHide={ props.handleClose } size="lg">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title">
                    Geschiedenis<br/>
                    <small>{ product != null ? product.brand.name + " " + product.name : "" }</small>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <InventoryHistoryContent
                    modalShowing={ props.show }
                    productId={ product ? product.id : null }
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ props.handleClose }>Sluiten</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(InventoryHistoryModal);
