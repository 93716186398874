import React, {
    useContext
} from "react";
import AuthenticatedUserContext from "../context/AuthenticatedUserContext";

function CurrentUserHighlight({ user, emptyState = "Niemand" }) {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    if(!user) {
        return emptyState;
    }
    if(authenticatedUserContext.user && user.id === authenticatedUserContext.user.id) {
        return (
            <b>{ user.name }</b>
        );
    }
    return user.name;
}

export default React.memo(CurrentUserHighlight);
