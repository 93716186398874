import React from "react";
import axios from "axios";
import moment from "moment";
import {
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const dateRegex = /([0-9]{4,})-([0-1][0-9])-([0-3][0-9])/;

class LeadAdd extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,

            types: null,
            sources: null,
            showHints: false,

            name: "",
            email: "",
            phoneNumber: "",
            typeId: 0,
            sourceId: 0,
            date: "",

            street: "",
            houseNumber: "",
            postalCode: "",
            city: "",

            message: ""
        };
    }

    componentDidMount() {
        this.getTypes();
        this.getSources();
    }

    getTypes() {
        this.setState({ types: null, error: null });
        axios.get("/getLeadTypes")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ types: response.data.data });
                } else {
                    this.setState({ types: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ types: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    getSources() {
        this.setState({ sources: null, error: null });
        axios.get("/getLeadSources")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ sources: response.data.data });
                } else {
                    this.setState({ sources: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ sources: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    addLead() {
        if(!this.canSubmit()) {
            this.setState({ error: "Niet alle vereiste velden zijn ingevuld of er zitten fouten in de velden.", showHints: true });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        this.setState({ error: null });
        axios.post("/addLeadInternal", {
            name: this.state.name,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            typeId: this.state.typeId,
            sourceId: this.state.sourceId,
            date: this.state.date,

            street: this.state.street,
            houseNumber: this.state.houseNumber,
            postalCode: this.state.postalCode,
            city: this.state.city,

            message: this.state.message
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.history.push("/lead/" + response.data.leadId);
                } else {
                    this.setState({ error: "Er was een fout bij het opslaan van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er was een fout bij het opslaan van de data." });
            })
            .finally(() => {
                window.scroll({ top: 0, behavior: "smooth" });
            });
    }

    hasValidEmail() {
        return emailRegex.test(this.state.email);
    }

    hasValidDate() {
        return dateRegex.test(this.state.date) && moment() > moment(this.state.date);
    }

    hasValidType() {
        return this.state.typeId === 0 || !!this.state.types.find((type) => type.id === this.state.typeId);
    }

    hasValidSource() {
        return this.state.sourceId !== 0 && !!this.state.sources.find((source) => source.id === this.state.sourceId)
    }

    canSubmit() {
        if(this.state.name.length === 0 || this.state.phoneNumber.length === 0 || this.state.street.length === 0
            || this.state.houseNumber.length === 0 || this.state.postalCode.length === 0 || this.state.city.length === 0) {
            return false;
        }
        if(!this.hasValidEmail() || !this.hasValidType() || !this.hasValidSource() || !this.hasValidDate()) {
            return false;
        }
        return true;
    }

    render() {
        const showHints = this.state.showHints;
        return (
            <React.Fragment>
                <Helmet title="Nieuwe lead toevoegen"/>
                <Title preTitle="Overzicht">
                    Nieuwe lead toevoegen
                </Title>

                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}

                { this.state.sources === null || this.state.types === null ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h4>Contact informatie</h4>
                                <div className="form-group">
                                    <label htmlFor="name">Naam klant</label>
                                    <input type="text" className="form-control" id="name" required
                                           value={this.state.name} onChange={ (event) => this.setState({ name: event.target.value })}
                                    />
                                    { showHints && this.state.name.length === 0 && (
                                        <span className="text-danger">
                                            Vul de naam van de klant in!
                                        </span>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control" id="email" required
                                           value={this.state.email} onChange={ (event) => this.setState({ email: event.target.value })}
                                    />
                                    { showHints && !this.hasValidEmail() && (
                                        <span className="text-danger">
                                            Ongeldige email!
                                        </span>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phoneNumber">Telefoonnummer</label>
                                    <input type="text" className="form-control" id="phoneNumber" required
                                           value={this.state.phoneNumber}
                                           onChange={ (event) => {
                                               this.setState({ phoneNumber: event.target.value.replace(/[a-zA-Z]*/g,"") });
                                           }}
                                    />
                                    { showHints && this.state.phoneNumber.length === 0 && (
                                        <span className="text-danger">
                                            Vul een telefoonnummer in!
                                        </span>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="type">Type</label>
                                    <select className="form-control" id="type" required
                                            value={ this.state.typeId }
                                            onChange={ (event) => {
                                                this.setState({ typeId: parseInt(event.target.value) });
                                            }}
                                    >
                                        <option value={ 0 }>Onbepaald</option>
                                        { this.state.types && this.state.types.map((type) => (
                                            <option key={ type.id } value={ type.id } style={{ backgroundColor: type.color, color: "white" }}>
                                                { type.name }
                                            </option>
                                        ))}
                                    </select>
                                    { showHints && !this.hasValidType() && (
                                        <span className="text-danger">
                                            Ongeldig type!
                                        </span>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="source">Bron</label>
                                    <select className="form-control" id="source" required
                                            value={ this.state.sourceId }
                                            onChange={ (event) => {
                                                this.setState({ sourceId: parseInt(event.target.value) })
                                            }}
                                    >
                                        <option value={ 0 }/>
                                        { this.state.sources && this.state.sources.filter((source) => source.manualInput).map((source) => (
                                            <option key={ source.id } value={ source.id } style={{ backgroundColor: source.color, color: "white" }}>
                                                { source.name }
                                            </option>
                                        ))}
                                    </select>
                                    { showHints && !this.hasValidSource() && (
                                        <span className="text-danger">
                                            Ongeldige bron!
                                        </span>
                                    )}
                                </div>

                                <div className="form-group mb-0">
                                    <label htmlFor="date">Datum ontvangen</label>
                                    <input type="date" className="form-control" id="date" required
                                           value={this.state.date.split(" ")[0]} onChange={ (event) => this.setState({ date: event.target.value })}
                                    />
                                    { showHints && !this.hasValidDate() && (
                                        <span className="text-danger">
                                            Ongeldige datum!
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-body">
                                <h4>Adres</h4>
                                <div className="row">
                                    <div className="col-sm-8">
                                        <div className="form-group">
                                            <label htmlFor="street">Straat</label>
                                            <input type="text" className="form-control" id="street" required
                                                   value={this.state.street} onChange={ (event) => this.setState({ street: event.target.value })}
                                            />
                                            { showHints && this.state.street.length === 0 && (
                                                <span className="text-danger">
                                                    Vul een straatnaam in!
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="houseNumber">Huisnummer + Toevoeging</label>
                                            <input type="text" className="form-control" id="houseNumber" required
                                                   value={this.state.houseNumber} onChange={ (event) => this.setState({ houseNumber: event.target.value })}
                                            />
                                            { showHints && this.state.houseNumber.length === 0 && (
                                                <span className="text-danger">
                                                    Vul een huisnummer in!
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group mb-0">
                                            <label htmlFor="postalCode">Postcode</label>
                                            <input type="text" className="form-control" id="postalCode" required
                                                   value={this.state.postalCode} onChange={ (event) => this.setState({ postalCode: event.target.value })}
                                            />
                                            { showHints && this.state.postalCode.length === 0 && (
                                                <span className="text-danger">
                                                    Vul een postcode in!
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group mb-0">
                                            <label htmlFor="city">Stad</label>
                                            <input type="text" className="form-control" id="city" required
                                                   value={this.state.city} onChange={ (event) => this.setState({ city: event.target.value })}
                                            />
                                            { showHints && this.state.city.length === 0 && (
                                                <span className="text-danger">
                                                    Vul een stad in!
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-body">
                                <h4>Opmerkingen</h4>
                                <div className="form-group mb-0">
                                    <textarea
                                        className="form-control" id="message" rows={ 8 }
                                        value={this.state.message}
                                        onChange={ (event) => this.setState({ message: event.target.value })}
                                    />
                                </div>
                            </div>
                        </div>

                        <button className="btn btn-primary float-right" onClick={ this.addLead.bind(this) }>
                            Toevoegen
                        </button>
                    </React.Fragment>
                )}

            </React.Fragment>
        )
    }
}

export default withRouter(LeadAdd);
