import React from "react";

import CopyButton from "../../../../components/copy/CopyButton";

function LeadExcelCopyButton({ lead, children, ...props }) {
    if(!lead) {
        return null;
    }
    return (
        <CopyButton
            text={
                [
                    lead.name,
                    lead.street + " " + lead.houseNumber,
                    lead.postalCode,
                    lead.city,
                    lead.phoneNumber,
                    lead.email
                ].join("\n")
            }
            { ...props }
        >
            { children }
        </CopyButton>
    )
}

export default React.memo(LeadExcelCopyButton);
