import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner,
    Table
} from "react-bootstrap";

import Loading from "../../../../components/Loading";
import CurrentUserHighlight from "../../../../components/CurrentUserHighlight";
import DateFormatter from "../../../../components/formatters/DateFormatter";

class InstallationSendVATRefundToken extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tokens: null,
            error: null,
            errorSave: null,
            loading: false
        }
        this.onShow = this.onShow.bind(this);
        this.sendLink = this.sendLink.bind(this);
    }

    onShow() {
        this.setState({ error: null, errorSave: null, loading: false });
        this.getTokens();
    }

    getTokens() {
        this.setState({ tokens: null });
        axios.post("/getInstallationVATRefundTokens", { installationId: this.props.installation.id })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ tokens: response.data.data });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    sendLink() {
        this.setState({ loading: true });
        axios.post("/sendInstallationVATRefundTokenToCustomer", { id: this.props.installation.id })
            .then((response) => {
                if(response.data.valid) {
                    this.props.refreshInstallation();
                    this.props.handleClose();
                } else {
                    this.setState({ errorSave: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ errorSave: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        if(!this.props.installation) {
            return null;
        }
        return (
            <React.Fragment>
                <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow } size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>BTW Terugvraag link versturen</Modal.Title>
                    </Modal.Header>
                    { this.state.error ? (
                        <React.Fragment>
                            <Modal.Body>
                                <Alert variant="danger">{ this.state.error }</Alert>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={ this.props.handleClose }>
                                    Sluiten
                                </Button>
                            </Modal.Footer>
                        </React.Fragment>
                    ) : !this.state.tokens || !this.props.installation ? (
                        <Modal.Body>
                            <Loading/>
                        </Modal.Body>
                    ) : (
                        <React.Fragment>
                            <Modal.Body>
                                { this.state.errorSave !== null && (
                                    <Alert variant="danger">{ this.state.errorSave }</Alert>
                                )}
                                <p>
                                    Weet je zeker dat je een BTW Terugvraag link wilt aanmaken en versturen aan{" "}
                                    { this.props.installation.lead.email }?
                                </p>
                                { this.state.tokens.length === 0 ? (
                                    <p className="mb-0">Er is nog geen link verstuurd bij deze installatie.</p>
                                ) : (
                                    <React.Fragment>
                                        <p>Er is al eerder een link verstuurd. Zie hieronder.</p>
                                        <Table size="sm" className="mb-0">
                                            <thead>
                                                <th>#</th>
                                                <th>Email</th>
                                                <th>Verstuurd door</th>
                                                <th>Datum</th>
                                                <th className="text-center">Link gebruikt</th>
                                            </thead>
                                            <tbody>
                                                { this.state.tokens.map((token) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                { token.id }
                                                            </td>
                                                            <td>
                                                                { token.email }
                                                            </td>
                                                            <td>
                                                                <CurrentUserHighlight user={ token.author } emptyState="Onbekend"/>
                                                            </td>
                                                            <td>
                                                                { !token.date ? (
                                                                    "Onbekend"
                                                                ) : (
                                                                    <DateFormatter date={ token.date }/>
                                                                )}
                                                            </td>
                                                            <td className="text-center">
                                                                { token.used ? (
                                                                    <i className="fas fa-check text-success fa-fw"/>
                                                                ) : (
                                                                    <i className="fas fa-times text-danger fa-fw"/>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </React.Fragment>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                                    Annuleer
                                </Button>
                                <Button variant="primary" onClick={ this.sendLink } disabled={ this.state.loading }>
                                    { this.state.loading && (
                                        <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                    )}
                                    { this.state.tokens.length > 0 ? "Nogmaals versturen" : "Versturen" }
                                </Button>
                            </Modal.Footer>
                        </React.Fragment>
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}

export default InstallationSendVATRefundToken;
