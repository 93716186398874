import React, {
    createContext
} from "react";
import axios from "axios";

const InventoryContext = createContext(null);

export class InventoryManager extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            buildings: null,
            error: null,

            refreshBuildingsIfNeeded: this.refreshBuildingsIfNeeded.bind(this)
        }
        this.loadingBuildings = false;
    }

    refreshBuildingsIfNeeded() {
        if(this.state.buildings === null) {
            this.getBuildings();
        }
    }

    getBuildings() {
        if(this.loadingBuildings) {
            return;
        }
        this.loadingBuildings = true;
        axios.get("/getInventoryBuildings")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ buildings: response.data.data });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            })
            .finally(() => {
                this.loadingBuildings = false;
            })
    }

    render() {
        return (
            <InventoryContext.Provider value={ this.state }>
                { this.props.children }
            </InventoryContext.Provider>
        )
    }

}

export function withInventoryContext(Component) {
    return function contextComponent(props) {
        return (
            <InventoryContext.Consumer>
                {context => <Component {...props} inventoryContext={context} />}
            </InventoryContext.Consumer>
        )
    }
}

export default InventoryContext;
