import React, {
    useContext, useEffect, useMemo,
    useState
} from "react";
import moment from "moment";
import {
    DateRangePicker
} from "react-dates";
import {
    ANCHOR_RIGHT,
    END_DATE,
    START_DATE
} from "react-dates/constants";
import {
    SidebarContext,
    useWindowSize
} from "@zandor300/backoffice-framework";

function StatisticsDateRangePicker({
                                       startDate,
                                       endDate,
                                       focusedInput,
                                       onDatesChange,
                                       onFocusChange
}) {
    const sidebarContext = useContext(SidebarContext);
    const windowSize = useWindowSize();

    const [numberOfMonths, setNumberOfMonths] = useState(2);

    useEffect(() => {
        let newNumberOfMonths = 2;
        if(sidebarContext.mobile) {
            newNumberOfMonths = 1;
        }
        if(windowSize.width < 1000) {
            newNumberOfMonths = 1;
        }
        setNumberOfMonths(newNumberOfMonths);
    }, [sidebarContext.mobile, windowSize.width]);

    const isOutsideRange = useMemo(() => {
        return (day) => {
            return day > moment().endOf("month")
        }
    }, []);

    return (
        <DateRangePicker
            startDateId={ START_DATE }
            endDateId={ END_DATE }
            startDate={ startDate }
            endDate={ endDate }
            displayFormat={ () => moment.localeData().longDateFormat('L') }
            focusedInput={ focusedInput }
            onDatesChange={ onDatesChange }
            onFocusChange={ onFocusChange }
            initialVisibleMonth={ () => (endDate ? endDate : moment()).clone().subtract(1, "month") }
            numberOfMonths={ numberOfMonths }
            horizontalMargin={ 15 }
            anchorDirection={ ANCHOR_RIGHT }
            minDate={ moment("2019-01-01") }
            maxDate={ moment().endOf("month") }
            isOutsideRange={ isOutsideRange }
            small
        />
    )
}

export default React.memo(StatisticsDateRangePicker);
