import React, {
    useMemo
} from "react";
import {
    Alert,
    Dropdown
} from "react-bootstrap";
import Loading from "../../Loading";
import TagPill from "../../tagPill";

function SearchFilter({ searchQuery, setSearchQuery, title, filterKey, values = [], error }) {
    const selectedValues = useMemo(() => {
        const searchQueryTokens = searchQuery.split(" ");
        for(let i = 0; i < searchQueryTokens.length; i++) {
            const searchQueryToken = searchQueryTokens[i].trim();
            if(searchQueryToken.startsWith(filterKey + "=")) {
                const valuesString = searchQueryToken.split("=")[1];
                if(valuesString.length === 0) {
                    return [];
                } else {
                    return valuesString.split(",");
                }
            }
        }
        return [];
    }, [searchQuery, filterKey]);

    const onSelect = useMemo(() => {
        return (value) => {
            let searchQueryTokens = searchQuery.split(" ");
            let foundKey = false;
            for(let i = 0; i < searchQueryTokens.length; i++) {
                const searchQueryToken = searchQueryTokens[i].trim();
                if(searchQueryToken.startsWith(filterKey + "=")) {
                    foundKey = true;
                    const valuesString = searchQueryToken.split("=")[1];
                    if(valuesString.length === 0) {
                        searchQueryTokens[i] = filterKey + "=" + value.value;
                    } else {
                        let valuesStringValues = valuesString.split(",");
                        if(valuesStringValues.includes(value.value)) {
                            if(valuesStringValues.length === 1) {
                                searchQueryTokens = searchQueryTokens.filter((searchToken) => {
                                    return !searchToken.startsWith(filterKey + "=");
                                });
                                break;
                            } else {
                                valuesStringValues = valuesStringValues.filter((searchValue) => {
                                    return searchValue !== value.value;
                                });
                            }
                        } else {
                            valuesStringValues.push(value.value);
                        }
                        searchQueryTokens[i] = filterKey + "=" + valuesStringValues.join(",");
                    }
                    break;
                }
            }
            if(!foundKey) {
                searchQueryTokens.push(filterKey + "=" + value.value);
            }
            setSearchQuery(searchQueryTokens.join(" "));
        }
    }, [searchQuery, setSearchQuery, filterKey]);

    return (
        <Dropdown className="align-self-center" alignRight>
            <Dropdown.Toggle
                variant={ "tabbar-filter" + (selectedValues.length > 0 ? " active" : "") }
                id={ "filter-" + filterKey }
            >
                { title }
                { selectedValues.length > 0 && (
                    <span className="ml-2">
                        ({ selectedValues.length })
                    </span>
                )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                { error ? (
                    <Alert variant="danger" className="mx-3 my-2" style={{ minWidth: "250px" }}>{ error }</Alert>
                ) : !values ? (
                    <Loading/>
                ) : values.map((value) => {
                    const onClick = () => {
                        onSelect(value);
                    }
                    const selected = selectedValues.includes(value.value);
                    return (
                        <Dropdown.Item onClick={ onClick } className="pl-3" key={ value.value }>
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input pointer-cursor"
                                    id="customCheck1" checked={ selected }
                                    onChange={ onClick }
                                />
                                <label className="custom-control-label pointer-cursor pl-2" htmlFor="customCheck1">
                                    { value.tagPill ? (
                                        <TagPill color={ value.color }>{ value.name }</TagPill>
                                    ) : (
                                        value.name
                                    )}
                                </label>
                            </div>
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default React.memo(SearchFilter);
