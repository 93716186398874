import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import TagPill from "../../../components/tagPill";
import Skeleton from "react-loading-skeleton";
import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";
import ParentSummary from "../../../components/ParentSummary";

class InstallationDetailOutages extends React.PureComponent {
    state = {
        outages: null,
        error: null
    };

    componentDidMount() {
        this.getInstallationOutages();
    }

    getInstallationOutages() {
        this.setState({ outages: null, error: null });
        axios.post("/getOutagesForInstallation", { installationId: this.props.installationId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ outages: response.data.data });
                } else {
                    this.setState({ outages: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ outages: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    render() {
        if(this.state.error !== null) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        if(this.state.outages === null || this.props.installation === null) {
            return (
                <React.Fragment>
                    {[...Array(20)].map((value, index) => (
                        <div className="mb-3" key={ index }>
                            <Skeleton height={ 100 }/>
                        </div>
                    ))}
                </React.Fragment>
            )
        }

        if(this.state.outages.length === 0) {
            return (
                <div className="text-center">
                    <h1><i className="fas fa-bolt"/></h1>
                    <h3>Geen storingen</h3>
                    <p>Er zijn geen storingen bekend bij deze storing.</p>
                    <Link to={ "/outages/add/installation/" + this.props.installation.id } className="btn btn-primary btn-sm">
                        <i className="fas fa-plus mr-2"/>
                        Nieuwe storing
                    </Link>
                </div>
            )
        }
        return (
            <React.Fragment>
                <ParentSummary
                    installation={ this.props.installation }
                />
                <Link to={ "/outages/add/installation/" + this.props.installation.id } className="btn btn-primary btn-sm mb-3">
                    <i className="fas fa-plus mr-2"/>
                    Nieuwe storing
                </Link>
                { this.state.outages.map(outage => (
                    <div className="card mb-3" key={ outage.id }>
                        <div className="card-body">
                            <h5 className="card-title">
                                Storing #{ outage.id }
                                <div className="float-right">
                                    <small>
                                        <TagPill className="ml-2" color={ outage.status.color }>{ outage.status.name }</TagPill>
                                        <TagPill className="ml-2" color={ outage.type.color }>{ outage.type.name }</TagPill>
                                        <TagPill className="ml-2" color={ outage.serviceSetting.color }>{ outage.serviceSetting.name }</TagPill>
                                    </small>
                                </div>
                            </h5>
                            <ReactMarkdownBreaks message={ outage.description }/>
                            <Link to={ "/outage/" + outage.id } className="d-hidden stretched-link"/>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}

export default InstallationDetailOutages;
