import React from "react";
import moment from "moment";
import {
    Link
} from "react-router-dom";
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card
} from "react-bootstrap";

import TagPill from "../../../components/tagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";
import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";

function PlanningItemCard(props) {
    const planningItem = props.planningItem;
    let description = planningItem.description;
    if(planningItem.descriptionSuffix) {
        description += "\n\n" + planningItem.descriptionSuffix;
    }
    return (
        <Card className={ "mb-3" + (props.className !== undefined ? " " + props.className : "") }>
            <Card.Body>
                <p className="card-text">
                    <span className="float-right">
                        { props.linkToPlanning ? (
                            <Link to={ "/planning/week/" + moment(planningItem.date).format("gggg/WW") } className="no-link-style">
                                <DateFormatter date={ planningItem.date } format="L" message="Klik om in planning te openen"/>
                            </Link>
                        ) : (
                            <DateFormatter date={ planningItem.date } format="L"/>
                        )}
                    </span>
                    <TagPill color={ planningItem.type.color }>{ planningItem.type.name }</TagPill>
                </p>
                { description.length > 0 && (
                    <ReactMarkdownBreaks message={ description }/>
                )}
            </Card.Body>
            <Card.Footer className="text-muted">
                <ButtonToolbar className="float-right">
                    { props.linkToPlanning && (
                        <ButtonGroup size="sm" className="ml-2">
                            <Link to={ "/planning/week/" + moment(planningItem.date).format("gggg/WW") } className="btn btn-secondary">
                                <i className="fas fa-calendar-alt mr-1"/>
                                Naar planning
                            </Link>
                        </ButtonGroup>
                    )}
                    { (props.onClickEdit !== undefined || props.onClickDelete !== undefined) && (
                        <ButtonGroup size="sm" className="float-right ml-2">
                            { props.onClickEdit !== undefined && (
                                <Button variant="primary" onClick={ props.onClickEdit }>
                                    <i className="fas fa-edit mr-1"/>
                                    Bewerken
                                </Button>
                            )}
                            { props.onClickDelete !== undefined && (
                                <Button variant="danger" onClick={ props.onClickDelete }>
                                    <i className="fas fa-trash-alt mr-1"/>
                                    Verwijderen
                                </Button>
                            )}
                        </ButtonGroup>
                    )}
                </ButtonToolbar>

                <p className="d-inline-block mr-2" style={{ marginBottom: 0, lineHeight: "28px" }}>
                    <span>Aangemaakt door <b>{ planningItem.author ? planningItem.author.name : "onbekend" }</b> op </span>
                    <DateFormatter date={ planningItem.dateCreated }/>
                </p>
            </Card.Footer>
        </Card>
    );
}

export default React.memo(PlanningItemCard);
