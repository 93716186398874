import React from "react";

function ChartNoData() {
    return (
        <div className="h-100 w-100 d-flex align-items-center text-center">
            <div className="w-100">
                <h1><i className="fas fa-chart-bar"/></h1>
                <h3>Geen data</h3>
                <p>Er is geen data beschikbaar voor deze grafiek voor de geselecteerde periode.</p>
            </div>
        </div>
    )
}

export default React.memo(ChartNoData);
