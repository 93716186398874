import React from "react";

import PrintFormTextLine from "../../../../components/print/PrintFormTextLine";
import PrintFormCheckbox from "../../../../components/print/PrintFormCheckbox";
import PrintFormPanelLayoutDrawing from "../../../../components/print/PrintFormPanelLayoutDrawing";

function LeadDetailPrintForm() {
    return (
        <React.Fragment>
            <div className="card mb-3">
                <div className="card-body">
                    <p className="card-text">
                        Gebruik de scan functie in de Sales app om dit ingevulde document te scannen en in het intranet
                        op te slaan.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <h5>Plaats omvormer</h5>
                    <PrintFormTextLine lines={ 2 }/>

                    <h5>Type omvormer</h5>
                    <PrintFormCheckbox title="Afore"/>
                    <PrintFormCheckbox title="SolarEdge"/>
                    <PrintFormCheckbox title="Solax"/>
                    <PrintFormCheckbox title="Solis"/>
                    <PrintFormCheckbox title="APS"/>
                    <PrintFormCheckbox title="Enphase"/>
                    <p>Bijzonderheden:</p>
                    <PrintFormTextLine lines={ 2 }/>

                    <h5>Aansluiting panelen naar omvormer</h5>
                    <PrintFormTextLine lines={ 3 }/>

                    <h5>Aansluiting omvormer naar meterkast</h5>
                    <PrintFormCheckbox title="Wasmachinegroep"/>
                    <PrintFormCheckbox title="Via loze leiding"/>
                    <PrintFormCheckbox title="Anders:"/>
                    <PrintFormTextLine lines={ 3 }/>

                    <h5>Groepenkast vervangen</h5>
                    <div className="mb-3">
                        <PrintFormCheckbox title="8 groepen"/>
                        <PrintFormCheckbox title="12 groepen"/>
                    </div>
                    <div className="mb-3">
                        <PrintFormCheckbox title="1 fase"/>
                        <PrintFormCheckbox title="3 fase"/>
                    </div>
                    <PrintFormCheckbox title="Kookgroep"/>
                    <p>Bijzonderheden:</p>
                    <PrintFormTextLine lines={ 3 }/>
                </div>
                <div className="col">
                    <h5>Legplan tekening</h5>
                    <PrintFormPanelLayoutDrawing/>

                    <h5>Overige bijzonderheden</h5>
                    <PrintFormTextLine lines={ 8 }/>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(LeadDetailPrintForm);
