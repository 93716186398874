import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Modal,
    Spinner
} from "react-bootstrap";

class PlanningCommentModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false,
            date: null,
            description: null
        }
        this.onShow = this.show.bind(this);
        this.onSave = this.save.bind(this);
    }

    show() {
        const planningComment = this.props.planningComment;
        this.setState({
            error: null,
            date: planningComment ? planningComment.date : this.props.date,
            description: planningComment ? planningComment.description : ""
        });
    }

    save() {
        if(!this.props.planningComment) {
            this.addPlanningComment();
        } else {
            this.setPlanningComment();
        }
    }

    setPlanningComment() {
        const planningCommentId = this.props.planningComment.id;
        this.setState({ loading: true });
        axios.post("/setPlanningComment", {
            planningCommentId: planningCommentId,
            description: this.state.description
        })
            .then((response) => {
                if(response.data.valid) {
                    if(this.props.planningCommentUpdated) {
                        this.props.planningCommentUpdated(response.data.planningComment);
                    }
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    addPlanningComment() {
        this.setState({ loading: true });
        axios.post("/addPlanningComment", {
            date: this.state.date,
            description: this.state.description
        })
            .then((response) => {
                if(response.data.valid) {
                    if(this.props.planningCommentUpdated) {
                        this.props.planningCommentUpdated(response.data.planningComment);
                    }
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <React.Fragment>
                <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                    <Modal.Header closeButton>
                        <Modal.Title>Planning notitie bewerken</Modal.Title>
                    </Modal.Header>
                    <form className="mb-0">
                        <Modal.Body>
                            { this.state.error !== null && (
                                <Alert variant="danger">{ this.state.error }</Alert>
                            )}
                            <Form.Group controlId="date">
                                <Form.Label>Datum</Form.Label>
                                <Form.Control type="date" value={ this.state.date } disabled
                                              onChange={ (event) => this.setState({ date: event.target.value })}/>
                            </Form.Group>
                            <Form.Group controlId="description" className="mb-0">
                                <Form.Label>Beschrijving</Form.Label>
                                <Form.Control as="textarea" rows="8" value={ this.state.description } disabled={ this.state.loading }
                                              onChange={ (event) => this.setState({ description: event.target.value })}/>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                                Annuleer
                            </Button>
                            <Button variant="primary" onClick={ this.onSave } disabled={ this.state.loading }>
                                { this.state.loading && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Opslaan
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default PlanningCommentModal;
