import React from "react";
import moment from "moment";
import {
    Link
} from "react-router-dom";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import DateFormatter from "../../../components/formatters/DateFormatter";
import TagPill from "../../../components/tagPill";

function InventoryOrderSuggestionRows({
    date,
    products
}) {
    if(!products) {
        return null;
    }
    const dateInPast = moment() > moment(date);
    return products.map((productSuggestion, index) => {
        const showDate = index === 0;
        return (
            <tr key={ index } className={ dateInPast ? "table-warning" : "" }>
                { showDate && (
                    <th rowSpan={ products.length } style={{ minWidth: "120px" }}>
                        <DateFormatter date={ date } format="DD-MM-YYYY"/>
                    </th>
                )}
                <td>
                    { productSuggestion.product.brand.name }
                </td>
                <td>
                    { productSuggestion.product.name }
                </td>
                <td>
                    <OverlayTrigger overlay={
                        <Tooltip id="edit">Overzicht van reserveringen voor dit product</Tooltip>
                    }>
                        <Link to={ `/products/${productSuggestion.product.brand.id}/product/${productSuggestion.product.id}/reserved` }>
                            <i className="fas fa-info-circle"/>
                        </Link>
                    </OverlayTrigger>
                </td>
                <td>
                    <TagPill color={ productSuggestion.product.type.color }>
                        { productSuggestion.product.type.name }
                    </TagPill>
                </td>
                <td>
                    { productSuggestion.amountToOrder }
                </td>
                <td>
                    { productSuggestion.installations.map((installation, index) => (
                        <div className={ index === 0 ? "" : "mt-2" }>
                            <Link to={ "/installation/" + installation.id }>
                                { installation.lead ? installation.lead.name : installation.outage ? installation.outage.name : "Unknown name" }
                                { " #" + installation.id }
                            </Link>
                        </div>
                    ))}
                </td>
            </tr>
        )
    })
}

export default React.memo(InventoryOrderSuggestionRows);
