import React, {
    useMemo
} from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function SearchBar({ searchQuery, setSearchQuery, onReset }) {
    const onChange = useMemo(() => {
        return (event) => {
            setSearchQuery(event.target.value);
        }
    }, [setSearchQuery]);

    return (
        <div className="align-self-center ml-3">
            <div className="input-group float-right my-2" style={{ maxWidth: 300 }}>
                <input
                    type="search" className="search form-control"
                    placeholder="Zoeken" value={ searchQuery } onChange={ onChange }
                />
                { onReset && (
                    <div className="input-group-append">
                        <OverlayTrigger overlay={
                            <Tooltip id="reset">Reset</Tooltip>
                        }>
                            <button className="btn btn-secondary" onClick={ onReset }>
                                <i className="fas fa-undo"/>
                            </button>
                        </OverlayTrigger>
                    </div>
                )}
            </div>
        </div>
    )
}

export default React.memo(SearchBar);
