import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Loading from "../../components/Loading";
import TagPill from "../../components/tagPill";
import numberFormatter from "../../components/formatters/NumberFormatter";
import Helmet from "../../components/Helmet";

class ProductBrandsOverview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            brands: null,
            error: null
        }
    }

    componentDidMount() {
        this.getProductBrands();
    }

    getProductBrands() {
        axios.get("/getProductBrands")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ brands: response.data.data });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet title="Producten"/>
                <Title preTitle="Overzicht">
                    Producten
                </Title>

                { this.state.error ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : !this.state.brands ? (
                    <Loading/>
                ) : (
                    <Table hover>
                        <thead className="thead-light">
                            <tr className="tr-sticky">
                                <th className="text-center" style={{ width: "75px" }}>
                                    #
                                </th>
                                <th>Brand</th>
                                <th>Aantal producten</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.brands.map((brand) => {
                                const url = `/products/${brand.id}`;
                                return (
                                    <tr className="table-clickable-row" key={ brand.id }>
                                        <th className="text-center">
                                            <Link to={ url }>
                                                { brand.id }
                                            </Link>
                                        </th>
                                        <td>
                                            <Link to={ url }>
                                                <TagPill color={ brand.color }>
                                                    { brand.name }
                                                </TagPill>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={ url }>
                                                { numberFormatter({ number: brand.products.length }) }
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                )}
            </React.Fragment>
        );
    }
}

export default ProductBrandsOverview;
