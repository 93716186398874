import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import PlanningItemModalForm from "./PlanningItemModalForm";

class PlanningItemNewModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false,

            date: "",
            description: "",
            assignedUsers: []
        }
        this.onShow = this.onShow.bind(this);
        this.setPlaningItemState = this.setPlaningItemState.bind(this);
    }

    onShow() {
        this.setState({
            error: null,
            loading: false,
            date: "",
            time: "",
            description: "",
            assignedUsers: []
        });
    }

    setPlaningItemState(state, callback) {
        this.setState((prevState) => {
            return { ...prevState, ...state }
        }, callback);
    }

    addPlanningItem() {
        const assignedUsers = this.state.assignedUsers ? this.state.assignedUsers.map((user) => user.id).join(",") : null;

        this.setState({ loading: true });
        axios.post("/addPlanningItem", {
            leadId: this.props.leadId,
            outageId: this.props.outageId,
            installationId: this.props.installationId,
            date: this.state.date,
            time: this.state.time,
            description: this.state.description,
            assignedUsers
        })
            .then((response) => {
                if(response.data.valid) {
                    if(this.props.planningItemAdded) {
                        this.props.planningItemAdded(response.data.item);
                    }
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow } size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Inplannen</Modal.Title>
                </Modal.Header>
                <form className="mb-0">
                    <Modal.Body>
                        { this.state.error !== null && (
                            <Alert variant="danger">{ this.state.error }</Alert>
                        )}
                        <PlanningItemModalForm
                            planningItem={ this.state }
                            setState={ this.setPlaningItemState }
                            loading={ this.state.loading }
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                            Annuleer
                        </Button>
                        <Button variant="primary" onClick={ this.addPlanningItem.bind(this) } disabled={ this.state.loading }>
                            { this.state.loading && (
                                <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                            )}
                            Opslaan
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

export default PlanningItemNewModal;
