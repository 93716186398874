import React from "react";
import {
    Alert,
    Button
} from "react-bootstrap";

import Skeleton from "react-loading-skeleton";
import PlanningItemEditModal from "../../pages/planning/modal/PlanningItemEditModal";
import PlanningItemDeleteModal from "../../pages/planning/modal/PlanningItemDeleteModal";
import PlanningItemNewModal from "../../pages/planning/modal/PlanningItemNewModal";
import PlanningItemCard from "../../pages/planning/components/PlanningItemCard";
import ParentSummary from "../ParentSummary";

class DetailPlanning extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            planningItems: null,
            error: null,

            showNewModal: false,
            showEditModal: false,
            editModalPlanningItem: null,
            showDeleteModal: false,
            deleteModalPlanningItem: null
        };
    }

    componentDidMount() {
        this.getPlanningItems();
    }

    getPlanningItems() {
        throw new Error("DetailPlanning.getPlanningItems() not implemented!");
    }

    sortPlanningItems(planningItems) {
        return planningItems.sort((planningItem1, planningItem2) => {
            if(planningItem1.date > planningItem2.date) return -1;
            if(planningItem1.date < planningItem2.date) return 1;
            return planningItem1.id > planningItem2.id ? -1 : 1;
        });
    }

    showNewButton() {
        return true;
    }

    getEmptyStateDescription() {
        return (
            <p>getEmptyStateDescription() not implemented!</p>
        )
    }

    isLoading() {
        return this.state.planningItems === null;
    }

    planningItemUpdated(planningItem) {
        this.setState((state) => {
            let planningItems = state.planningItems;
            const index = planningItems.findIndex(arrayPlanningItem => { return arrayPlanningItem.id === planningItem.id });
            planningItems[index] = planningItem;
            return { planningItems: planningItems, showEditModal: false };
        });
    }

    planningItemAddedDeleted() {
        this.getPlanningItems();
        this.setState({ showNewModal: false, showEditModal: false, showDeleteModal: false });
        this.onPlanningItemAddedDeleted();
    }

    onPlanningItemAddedDeleted() {
        // Abstract function
    }

    getAlerts() {
        // Abstract function
        return [];
    }

    render() {
        return (
            <React.Fragment>
                <PlanningItemEditModal
                    show={ this.state.showEditModal }
                    planningItem={ this.state.editModalPlanningItem }
                    handleClose={ () => this.setState({ showEditModal: false }) }
                    planningItemUpdated={ this.planningItemUpdated.bind(this) }
                />
                <PlanningItemDeleteModal
                    show={ this.state.showDeleteModal }
                    planningItem={ this.state.deleteModalPlanningItem }
                    handleClose={ () => this.setState({ showDeleteModal: false }) }
                    planningItemDeleted={ this.planningItemAddedDeleted.bind(this) }
                />
                <PlanningItemNewModal
                    { ...this.props }
                    show={ this.state.showNewModal }
                    handleClose={ () => this.setState({ showNewModal: false }) }
                    planningItemAdded={ this.planningItemAddedDeleted.bind(this) }
                />

                { this.state.error !== null ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : this.isLoading() ? (
                    <React.Fragment>
                        {[...Array(20)].map((value, index) => (
                            <div className="mb-3" key={ index }>
                                <Skeleton height={ 162 }/>
                            </div>
                        ))}
                    </React.Fragment>
                ) : this.state.planningItems.length === 0 ? (
                    <React.Fragment>
                        { this.getAlerts() }
                        <div className="text-center">
                            <h1><i className="fas fa-calendar"/></h1>
                            <h3>Geen planning items</h3>
                            { this.getEmptyStateDescription() }
                            { this.showNewButton() && (
                                <Button variant="primary" size="sm" onClick={ () => this.setState({ showNewModal: true })}>
                                    <i className="fas fa-plus mr-2"/>
                                    Nieuwe planning item
                                </Button>
                            )}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        { this.getAlerts() }
                        <ParentSummary { ...this.props }/>
                        { this.showNewButton() && (
                            <Button variant="primary" size="sm" className="mb-3" onClick={ () => this.setState({ showNewModal: true })}>
                                <i className="fas fa-plus mr-2"/>
                                Nieuwe planning item
                            </Button>
                        )}
                        { this.state.planningItems.map(planningItem => (
                            <PlanningItemCard
                                key={ planningItem.id }
                                planningItem={ planningItem }
                                linkToPlanning
                                onClickEdit={ () => this.setState({ showEditModal: true, editModalPlanningItem: planningItem }) }
                                onClickDelete={ () => this.setState({ showDeleteModal: true, deleteModalPlanningItem: planningItem }) }
                            />
                        ))}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default DetailPlanning;
