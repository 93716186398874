import React, {
    useState
} from "react";

function PrintFormCheckbox({ title }) {
    const [value, setValue] = useState(false);
    return (
        <div className="custom-control custom-checkbox">
            <input
                type="checkbox"
                className="custom-control-input"
                id="checkbox"
                onChange={ (event) => setValue(event.target.checked) }
                checked={ value }
            />
            <label className="custom-control-label" htmlFor="checkbox">
                { title }
            </label>
        </div>
    )
}

export default React.memo(PrintFormCheckbox);
