import React, {
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Button,
    Modal
} from "react-bootstrap";
import {
    useWindowSize
} from "@zandor300/backoffice-framework";

import ImageRemoveModal from "./ImageRemoveModal";

function allowedToRemoveImage(image) {
    if(!image) {
        return false;
    }
    switch(image.parentType) {
        case "lead":
        case "outage":
        case "installation":
            return true;
        default:
            return false;
    }
}

function OpenParentButton({ image }) {
    if(!image || !image.parent) {
        return null;
    }
    let url = null
    let text = null;
    switch(image.parentType) {
        case "lead":
            url = "/lead/" + image.parent.id;
            text = "Lead openen";
            break;
        case "leadComment":
            url = "/lead/" + image.parent.leadId;
            text = "Lead openen";
            break;
        case "outage":
            url = "/outage/" + image.parent.id;
            text = "Storing openen";
            break;
        case "outageComment":
            url = "/outage/" + image.parent.outageId;
            text = "Storing openen";
            break;
        case "installation":
            url = "/installation/" + image.parent.id;
            text = "Installatie openen";
            break;
        case "installationComment":
            url = "/installation/" + image.parent.installationId;
            text = "Installatie openen";
            break;
        default:
            return null;
    }
    return (
        <Link to={ url } className="btn btn-primary ml-2">
            { text }
        </Link>
    )
}

function ImageModal({ image, show, handleClose, onImageRemoved, showOpenParentButton }) {
    const size = useWindowSize();

    const onMouseClickBackground = event => {
        if(event.target.nodeName !== "IMG") {
            handleClose();
        }
    };

    const allowedToRemove = onImageRemoved !== undefined && allowedToRemoveImage(image);

    const [showImageRemoveModal, setShowImageRemoveModal] = useState(false);
    const onRemove = () => {
        setShowImageRemoveModal(true);
    }
    const handleImageRemoveModalClose = () => {
        setShowImageRemoveModal(false);
    }
    const onImageRemove = () => {
        onImageRemoved();
        handleImageRemoveModalClose();
        handleClose();
    }

    return (
        <React.Fragment>
            <ImageRemoveModal
                show={ show && showImageRemoveModal }
                handleClose={ handleImageRemoveModalClose }
                onImageRemoved={ onImageRemove }
                image={ image }
            />

            <Modal size="image" show={ show } onHide={ handleClose }>
                <Button className="modal-image-close" variant="link" size="lg" onClick={ handleClose }>
                    <i className="fas fa-times"/>
                </Button>
                <Modal.Body className="text-center" onClick={ onMouseClickBackground }>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        { image !== null && (
                            <img src={ image.url } alt="Selected"
                                 style={{ maxWidth: "100%", maxHeight: size.height }} />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex w-100">
                        <div className="flex-grow-1">
                            { allowedToRemove && (
                                <Button variant="danger" onClick={ onRemove }>
                                    Verwijderen
                                </Button>
                            )}
                        </div>
                        <div>
                            <Button variant="secondary" onClick={ handleClose } className="ml-2">
                                Sluiten
                            </Button>
                            { image !== null && (
                                <React.Fragment>
                                    <a href={ image.url } className="btn btn-secondary ml-2" target="_blank" rel="noreferrer noopener">
                                        Origineel openen
                                    </a>
                                    { showOpenParentButton && (
                                        <OpenParentButton image={ image }/>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ImageModal;
