import React, {
    useMemo
} from "react";
import moment from "moment";

import DateFormatter from "../formatters/DateFormatter";
import ImageGallery from "../../pages/images/ImageGallery";

function ImageGalleryDateGrouped({ images, onImageRemoved }) {
    const groupedImages = useMemo(() => {
        let imageDict = {};
        for(const image of images) {
            const dateString = moment(image.date).format("YYYY-MM-DD");
            if(dateString in imageDict) {
                imageDict[dateString].push(image);
            } else {
                imageDict[dateString] = [image];
            }
        }
        return imageDict;
    }, [images]);

    return Object.entries(groupedImages).map(([date, images]) => (
        <div key={ date } className="mb-3">
            <h3>
                <DateFormatter date={ date }/>
            </h3>

            <ImageGallery
                images={ images }
                onImageRemoved={ onImageRemoved }
            />
        </div>
    ))
}

export default React.memo(ImageGalleryDateGrouped);
