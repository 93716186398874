import React from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import AbsenceCalendar from "./AbsenceCalendar";

function AbsencePage(props) {
    return (
        <Switch>
            <Route path={[
                props.match.path,
                `${props.match.path}/:year/:weekNumber`
            ]} exact component={AbsenceCalendar}/>
            <Route path={ props.match.path }>
                <Redirect to={ props.match.path }/>
            </Route>
        </Switch>
    )
}

export default React.memo(AbsencePage);
