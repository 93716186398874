import React, {
    useContext,
    useEffect,
    useMemo
} from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    Form
} from "react-bootstrap";
import {
    useLocalStorage
} from "@zandor300/backoffice-framework";

import UsersContext from "../../../context/UsersContext";
import Loading from "../../../components/Loading";

function PlanningItemModalForm({ planningItem, setState, loading = false }) {
    const usersContext = useContext(UsersContext);

    const [userFilter, setUserFilter] = useLocalStorage("planningItemUserFilter", "all");

    const refreshUsers = useMemo(() => {
        return usersContext.refreshUsers;
    }, [usersContext.refreshUsers]);

    useEffect(() => {
        refreshUsers();
    }, [refreshUsers]);

    const isUserSelected = useMemo(() => {
        return (user) => {
            return planningItem.assignedUsers && planningItem.assignedUsers.findIndex((assignedUser) => {
                return assignedUser.id === user.id
            }) !== -1;
        }
    }, [planningItem]);

    const sortUsers = useMemo(() => {
        return (users) => {
            if(!users) return users;
            return [...users].sort((user1, user2) => {
                const selected1 = isUserSelected(user1);
                const selected2 = isUserSelected(user2);
                if(selected1 && !selected2) return -1;
                if(!selected1 && selected2) return 1;
                if(user1.name < user2.name) return -1;
                if(user1.name > user2.name) return 1;
                return 0;
            })
        }
    }, [isUserSelected]);

    const onClickUser = useMemo(() => {
        return (user) => {
            let assignedUsers = planningItem.assignedUsers ? [...planningItem.assignedUsers] : [];
            if(isUserSelected(user)) {
                assignedUsers = assignedUsers.filter((assignedUser) => {
                    return assignedUser.id !== user.id;
                });
            } else {
                assignedUsers.push(user);
            }
            setState({ assignedUsers });
        }
    }, [isUserSelected, planningItem.assignedUsers, setState]);

    if(usersContext.error) {
        return (
            <Alert variant="danger">{ usersContext.error }</Alert>
        )
    }
    if(!usersContext.users) {
        return (
            <Loading/>
        )
    }
    return (
        <div className="row">
            <div className="col-md-6">
                <div className="row">
                    <div className="col-lg-6">
                        <Form.Group controlId="date">
                            <Form.Label>Datum</Form.Label>
                            <Form.Control
                                type="date" value={ planningItem.date } disabled={ loading }
                                onChange={ (event) => setState({ date: event.target.value })}
                            />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group controlId="date">
                            <Form.Label>Tijd</Form.Label>
                            <Form.Control
                                type="text" value={ planningItem.time || "" } disabled={ loading }
                                onChange={ (event) => setState({ time: event.target.value })}
                            />
                        </Form.Group>
                    </div>
                </div>
                <Form.Group controlId="description">
                    <Form.Label>Beschrijving</Form.Label>
                    <Form.Control
                        as="textarea" rows="8" value={ planningItem.description } disabled={ loading }
                        onChange={ (event) => setState({ description: event.target.value })}
                    />
                </Form.Group>
            </div>
            <div className="col-md-6">
                <p className="mb-1">Toegewezen werknemers</p>
                <ButtonGroup size="sm" className="d-flex mb-2">
                    <Button
                        className="flex-grow-1"
                        variant={ (userFilter !== "all" ? "outline-" : "") + "secondary" }
                        onClick={ () => setUserFilter("all") }
                    >
                        Alle
                    </Button>
                    <Button
                        className="flex-grow-1"
                        variant={ (userFilter !== "office" ? "outline-" : "") + "secondary" }
                        onClick={ () => setUserFilter("office") }
                    >
                        Kantoor
                    </Button>
                    <Button
                        className="flex-grow-1"
                        variant={ (userFilter !== "mechanic" ? "outline-" : "") + "secondary" }
                        onClick={ () => setUserFilter("mechanic") }
                    >
                        Monteur
                    </Button>
                </ButtonGroup>
                <div
                    className="pr-1"
                    style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                    { sortUsers(usersContext.users).map((user) => {
                        const selected = isUserSelected(user);

                        if(!selected) {
                            if(user.intranet && userFilter === "mechanic") {
                                return null;
                            }
                            if(!user.intranet && userFilter === "office") {
                                return null;
                            }
                        }

                        const onClick = () => {
                            onClickUser(user);
                        };
                        return (
                            <div
                                key={ user.id }
                                className={ "card mb-1" + (selected ? " border-primary" : "") }
                            >
                                <label className="pointer-cursor mb-0" htmlFor={ user.id }>
                                    <div className="card-body py-2">
                                        <div className="custom-control custom-checkbox custom-checkbox-lg">
                                            <input
                                                type="checkbox"
                                                id={ user.id }
                                                name={ user.id }
                                                className="custom-control-input"
                                                checked={ selected }
                                                onChange={ onClick }
                                            />
                                            <span className="custom-control-label pointer-cursor pl-1">
                                                <b className="mt-1 ml-2">{ user.name }</b>
                                            </span>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default PlanningItemModalForm;
