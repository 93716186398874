import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function WarningTooltip({ warnings }) {
    if(!warnings || warnings.length === 0) {
        return null;
    }
    return (
        <OverlayTrigger placement="top" overlay={
            <Tooltip id="warning">
                { warnings.map((warning, index) => (
                    <React.Fragment>
                        { warning }
                        { index < warnings.length - 1 && (
                            <br/>
                        )}
                    </React.Fragment>
                ))}
            </Tooltip>
        }>
            <i className="fas fa-exclamation-triangle text-warning"/>
        </OverlayTrigger>
    )
}

export default React.memo(WarningTooltip);
