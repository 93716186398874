import React from "react";
import {
    TabBar,
    TabBarItem
} from "@zandor300/backoffice-framework";

function InventoryTabBar({ children, ...props }) {
    return (
        <TabBar { ...props }>
            <TabBarItem to="/inventory">
                Actuele voorraad
            </TabBarItem>
            <TabBarItem to="/inventory/suggested-orders">
                Voorgestelde bestellingen
            </TabBarItem>
            <TabBarItem to="/inventory/reserved-products">
                Gereserveerde producten
            </TabBarItem>
            { children }
        </TabBar>
    )
}

export default React.memo(InventoryTabBar);
