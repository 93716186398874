import React from "react";
import SendEmailButton from "./SendEmailButton";

const NEW_LINE = "%0D%0A";

function LeadSendEmailButton({ lead, children, ...props }) {
    if(!lead) {
        return null;
    }
    const lines = [];
    lines.push("Beste " + lead.name + ",");
    lines.push("Onlangs hebben wij u onze offerte toegestuurd betreffende zonnepanelen voor uw woning naar aanleiding van uw aanvraag hiervoor.");
    lines.push("Wij zijn erg benieuwd of u nog vragen heeft over de offerte of de eventuele installatie zelf. Mocht u nog interesse hebben in zonnepanelen, dan is het wat ons betreft verstandig een vrijblijvend bezoek in te plannen om de situatie te bekijken en de offerte met u door te nemen.");
    lines.push("In afwachting van uw reactie verblijf ik.");
    lines.push("Onderstaand onze bedrijfsvideos:" + NEW_LINE + "https://youtu.be/PScGuIp6_zM" + NEW_LINE + "https://youtu.be/QlnkQXWN7us");

    return (
        <SendEmailButton
            email={ lead.email }
            subject="Offerte zonnepanelen herinnering"
            body={ lines.join(NEW_LINE + NEW_LINE) }
            { ...props }
        >
            { children }
        </SendEmailButton>
    )
}

export default React.memo(LeadSendEmailButton);
