import React, {
    useEffect,
    useState
} from "react";
import moment from "moment";
import {
    Chart
} from "react-charts";

import ChartLoading from "../../../statistics/components/ChartLoading";
import ChartNoData from "../../../statistics/components/ChartNoData";

function ProductAvailabilityChart({ amountAvailable }) {
    const [data, setData] = useState(null);

    useEffect(() => {
        if(!amountAvailable) {
            setData(null);
            return;
        }
        const chartData = Object.entries(amountAvailable).map(([date, value]) => {
            return {
                date,
                value
            }
        });
        setData([{
            label: "Aantal beschikbaar",
            data: chartData
        }])
    }, [amountAvailable]);

    const primaryAxis = React.useMemo(
        () => ({
            getValue: (datum) => moment(datum.date).format("DD-MM-YYYY")
        }), []
    );
    const secondaryAxes = React.useMemo(
        () => [
            {
                getValue: (datum) => datum.value,
                elementType: "line"
            },
        ], []
    );

    if(!data) {
        return (
            <ChartLoading/>
        )
    }
    if(data.length === 0) {
        return (
            <ChartNoData/>
        )
    }
    return (
        <Chart
            options={{
                data,
                primaryAxis,
                secondaryAxes,
            }}
        />
    )
}

export default React.memo(ProductAvailabilityChart);
