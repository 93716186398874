import React from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";

import AnimatedLogo from "../AnimatedLogo";

class DetailOverviewQRCode extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            qrCodeLoaded: false
        };
    }

    componentDidMount() {
        AnimatedLogo.pushNetworkActivity();
    }

    componentWillUnmount() {
        if(!this.state.qrCodeLoaded) {
            AnimatedLogo.popNetworkActivity();
        }
    }

    onQRCodeLoaded() {
        this.setState({ qrCodeLoaded: true });
        AnimatedLogo.popNetworkActivity();
        if(this.props.onQRCodeLoaded) {
            this.props.onQRCodeLoaded();
        }
    }

    render() {
        return (
            <div className="card card-qrcode float-right ml-2 d-none d-lg-block" style={{ width: "12rem" }}>
                <div style={{ width: "12rem", height: "12rem" }}>
                    { !this.state.qrCodeLoaded && (
                        <div className="card-body">
                            <Skeleton height={152}/>
                        </div>
                    )}
                    { this.props.content && (
                        <img
                            src={ axios.defaults.baseURL + "qrcode?content=" + encodeURIComponent(this.props.content) }
                            className={ "card-img-top" + (this.state.qrCodeLoaded ? "" : " d-none") }
                            alt={ this.props.content }
                            onLoad={ this.onQRCodeLoaded.bind(this) }
                        />
                    )}
                </div>
                <div className="card-body pt-0">
                    <p className="card-text">
                        { this.props.caption }
                    </p>
                </div>
            </div>
        );
    }
}

export default DetailOverviewQRCode;
