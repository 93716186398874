import React from "react";
import axios from "axios";

import DetailReminders from "../../../components/detail/DetailReminders";

class LeadDetailReminders extends DetailReminders {
    getReminders() {
        this.setState({ reminders: null, error: null });
        axios.post("/getRemindersForLead", { leadId: this.props.leadId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ reminders: response.data.data });
                } else {
                    this.setState({ reminders: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ reminders: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    getEmptyStateDescription() {
        return (
            <p>Er zijn nog geen herinneringen toegevoegd aan deze lead.</p>
        )
    }
}

export default LeadDetailReminders;
