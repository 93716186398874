import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import Confetti from "react-confetti";
import {
    Collapse
} from "react-collapse";
import {
    useWindowSize
} from "@zandor300/backoffice-framework";

function joinNames(array) {
    if(!array || array.length === 0) {
        return null;
    }
    if(array.length === 1) {
        return array[0];
    }
    let mutableArray = [...array];
    let last = mutableArray.pop();
    return mutableArray.join(", ") + " en " + last;
}

function isBirthdayToday(user) {
    return moment(user.birthday).format("MM-DD") === moment().format("MM-DD")
}

function BirthdayWidgetInternal({ birthdays, dismissed, onDismiss }) {
    const windowSize = useWindowSize();
    const height = 150;
    const show = birthdays !== null && birthdays.length > 0;

    const namesPreviousDays = !show ? null : birthdays.filter((user) => !isBirthdayToday(user)).map((user) => user.name);
    const namesToday = !show ? null : birthdays.filter((user) => isBirthdayToday(user)).map((user) => user.name);

    return (
        <Collapse isOpened={ show && !dismissed }>
            { show && (
                <div className="card" style={{ marginLeft: "10px", marginRight: "10px", overflowX: "hidden" }}>
                    <Confetti
                        width={ windowSize.width }
                        height={ height }
                    />
                    <div className="card-body text-center d-flex flex-column justify-content-center" style={{ height: height + "px" }}>
                        { namesToday.length > 0 && (
                            <h5 className="card-title">
                                { namesToday.length === 1 ? (
                                    joinNames(namesToday) + " is jarig!"
                                ) : (
                                    joinNames(namesToday) + " zijn jarig!"
                                )}
                            </h5>
                        )}
                        { namesPreviousDays.length > 0 && (
                            <h5 className="card-title">
                                { namesPreviousDays.length === 1 ? (
                                    joinNames(namesPreviousDays) + " was jarig!"
                                ) : (
                                    joinNames(namesPreviousDays) + " waren jarig!"
                                )}
                            </h5>
                        )}
                        <h3 className="card-text">
                            Gefeliciteerd!
                        </h3>
                    </div>
                    <div style={{ position: "absolute", right: 10, top: 10 }}>
                        <OverlayTrigger overlay={
                            <Tooltip id="dismiss">Sluiten</Tooltip>
                        } placement="left">
                            <Button variant="link" onClick={ onDismiss }>
                                <i className="fas fa-times"/>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
            )}
        </Collapse>
    )
}

class BirthdayWidget extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            birthdays: null,
            dismissed: this.isDismissed(),
            error: null
        }
        this.onDismiss = this.onDismiss.bind(this);
    }

    isDismissed() {
        const dismissedStorage = localStorage.getItem("birthdayDismissed");
        if(!dismissedStorage) {
            return false;
        }
        return dismissedStorage === moment().format("YYYY-MM-DD");
    }

    onDismiss() {
        this.setState({ dismissed: true }, () => {
            localStorage.setItem("birthdayDismissed", moment().format("YYYY-MM-DD"));
        })
    }

    componentDidMount() {
        if(!this.isDismissed()) {
            this.getBirthday();
        }
    }

    getBirthday() {
        this.setState({ birthday: null, error: null });
        axios.get("/getBirthday")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ birthdays: response.data.data });
                } else {
                    this.setState({ birthdays: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ birthdays: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    render() {
        return (
            <BirthdayWidgetInternal
                birthdays={ this.state.birthdays }
                dismissed={ this.state.dismissed }
                onDismiss={ this.onDismiss }
            />
        )
    }

}

export default BirthdayWidget;
