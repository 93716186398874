import React from "react";
import axios from "axios";
import {
    Modal,
    Form,
    Button,
    Alert,
    Spinner
} from "react-bootstrap";

import Loading from "../../Loading";

class DetailSetAssignedUserModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            users: null,
            error: null,
            errorSave: null,

            selectedUser: null,
            loading: false
        };
        this.onShow = this.onShow.bind(this);
        this.onAssignedUserSelected = this.onAssignedUserSelected.bind(this);
        this.onSetAssignedUser = this.onSetAssignedUser.bind(this);
    }

    getUsers() {
        this.setState({ users: null, error: null });
        axios.get("/getUsers")
            .then((response) => {
                if(response.data.valid) {
                    let users = response.data.data.filter(user => {
                        return user.enabled && user.intranet;
                    });
                    users.sort((user1, user2) => {
                        if(user1.name < user2.name) return -1;
                        if(user1.name > user2.name) return 1;
                        return 0;
                    });
                    this.setState({ users: users });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    onSetAssignedUser() {
        this.setAssignedUser(this.state.selectedUser);
    }

    setAssignedUser(assignedUserId) {
        throw new Error("setAssignedUser() not implemented.");
    }

    onShow() {
        const assignedUser = this.getInitialAssignedUser();
        this.setState({
            selectedUser: assignedUser ? assignedUser : -1,
            users: null,
            errorSave: null,
            loading: false
        });
        this.getUsers();
    }

    getInitialAssignedUser() {
        throw new Error("getInitialAssignedUser() not implemented.");
    }

    onAssignedUserSelected(event) {
        this.setState({ selectedUser: parseInt(event.target.value) });
    }

    getModalTitle() {
        return "Toegewezen werknemer veranderen";
    }

    render() {
        const initialLoading = this.state.users === null;
        const saving = this.state.loading;
        const loading = initialLoading || saving;
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                <Modal.Header closeButton>
                    <Modal.Title>{ this.getModalTitle() }</Modal.Title>
                </Modal.Header>
                <form className="mb-0">
                    <Modal.Body>
                        { this.state.error ? (
                            <Alert variant="danger">{ this.state.error }</Alert>
                        ) : initialLoading ? (
                            <Loading/>
                        ) : (
                            <React.Fragment>
                                { this.state.errorSave && (
                                    <Alert variant="danger">{ this.state.errorSave }</Alert>
                                )}
                                <Form.Group controlId="assignedUser" className="mb-0">
                                    <Form.Label>Toegewezen werknemer</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={ this.state.selectedUser !== null ? this.state.selectedUser : -1 }
                                        onChange={ this.onAssignedUserSelected }
                                        disabled={ loading }
                                    >
                                        <option value={ -1 }>Niemand</option>
                                        { this.state.users.map( user => (
                                            <option key={ user.id } value={ user.id }>
                                                { user.name }
                                            </option>
                                        ) )}
                                    </Form.Control>
                                </Form.Group>
                            </React.Fragment>
                        ) }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ this.props.handleClose } disabled={ loading }>
                            Annuleer
                        </Button>
                        <Button variant="primary" onClick={ this.onSetAssignedUser } disabled={ loading }>
                            { saving && (
                                <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                            )}
                            Opslaan
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}

export default DetailSetAssignedUserModal;
