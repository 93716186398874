import React from "react";
import {
    Alert
} from "react-bootstrap";

import ReminderCard from "../../pages/reminders/components/ReminderCard";
import ReminderEditModal from "../../pages/reminders/modal/ReminderEditModal";
import ReminderRedoModal from "../../pages/reminders/modal/ReminderRedoModal";
import ReminderNewModal from "../../pages/reminders/modal/ReminderNewModal";
import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import Skeleton from "react-loading-skeleton";
import ParentSummary from "../ParentSummary";

class DetailReminders extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            reminders: null,
            error: null,

            editModalReminder: null,
            showEditModal: false,
            redoModalReminder: null,
            showRedoModal: false,
            showNewModal: false
        };
        this.openReminderEditModal = this.openReminderEditModal.bind(this);
        this.openReminderRedoModal = this.openReminderRedoModal.bind(this);
        this.reminderUpdated = this.reminderUpdated.bind(this);
        this.reminderAdded = this.reminderAdded.bind(this);
    }

    componentDidMount() {
        this.getReminders();
    }

    getReminders() {
        throw new Error("DetailReminders.getReminders() not implemented!");
    }

    getEmptyStateDescription() {
        return (
            <p>getEmptyStateDescription() not implemented!</p>
        )
    }

    openReminderEditModal(reminder) {
        this.setState({ editModalReminder: reminder, showEditModal: true });
    }

    openReminderRedoModal(reminder) {
        this.setState({ redoModalReminder: reminder, showRedoModal: true });
    }

    reminderUpdated(reminder) {
        this.setState((state) => {
            let reminders = [...state.reminders];
            const index = reminders.findIndex(arrayReminder => { return arrayReminder.id === reminder.id });
            reminders[index] = reminder;
            return { reminders: reminders, showEditModal: false };
        });
    }

    reminderAdded() {
        this.getReminders();
        this.setState({ showNewModal: false, showRedoModal: false });
    }

    render() {
        return (
            <React.Fragment>
                <ReminderEditModal
                    show={ this.state.showEditModal }
                    reminder={ this.state.editModalReminder }
                    handleClose={ () => this.setState({ showEditModal: false }) }
                    reminderUpdated={ this.reminderUpdated }
                />
                <ReminderRedoModal
                    show={ this.state.showRedoModal }
                    reminder={ this.state.redoModalReminder }
                    handleClose={ () => this.setState({ showRedoModal: false }) }
                    reminderAdded={ this.reminderAdded }
                />
                <AuthenticatedUserContext.Consumer>
                    { value => (
                        <ReminderNewModal
                            { ...this.props }
                            show={ this.state.showNewModal }
                            defaultAssignedUserId={ value.user.id }
                            handleClose={ () => this.setState({ showNewModal: false }) }
                            reminderAdded={ this.reminderAdded }
                        />
                    )}
                </AuthenticatedUserContext.Consumer>

                { this.state.error !== null ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : this.state.reminders === null ? (
                    <React.Fragment>
                        {[...Array(20)].map((value, index) => (
                            <div className="mb-3" key={ index }>
                                <Skeleton height={ 122 }/>
                            </div>
                        ))}
                    </React.Fragment>
                ) : this.state.reminders.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-alarm-clock"/></h1>
                        <h3>Geen herinneringen</h3>
                        { this.getEmptyStateDescription() }
                        <button className="btn btn-primary btn-sm" onClick={ () => this.setState({ showNewModal: true}) }>
                            <i className="fas fa-plus mr-2"/>
                            Nieuwe herinnering
                        </button>
                    </div>
                ) : (
                    <React.Fragment>
                        <ParentSummary { ...this.props }/>
                        <button className="btn btn-primary btn-sm mb-3" onClick={ () => this.setState({ showNewModal: true}) }>
                            <i className="fas fa-plus mr-2"/>
                            Nieuwe herinnering
                        </button>
                        { this.state.reminders.map(reminder => (
                            <ReminderCard
                                key={reminder.id}
                                reminder={reminder}
                                handleEditModalOpen={ this.openReminderEditModal }
                                handleRedoModalOpen={ this.openReminderRedoModal }
                                reminderUpdated={ this.reminderUpdated }
                            />
                        ))}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default DetailReminders;
