import React from "react";
import {
    Route,
    Switch
} from "react-router-dom";

import VATRefundOverview from "./VATRefundOverview";
import VATRefundAdd from "./VATRefundAdd";

function VATRefundSwitch(props) {
    return (
        <Switch>
            <Route path={`${props.match.path}/add/:installationId`} component={VATRefundAdd}/>
            <Route path={`${props.match.path}/:pageType`} component={VATRefundOverview} />
            <Route path={`${props.match.path}/`} component={VATRefundOverview} />
        </Switch>
    )
}

export default React.memo(VATRefundSwitch);
