import React from "react";
import axios from "axios";
import {
    Button,
    FormControl,
    InputGroup,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import numberFormatter from "../../../../components/formatters/NumberFormatter";

class ProductQuickActionSetAmount extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            amount: 0,
            saving: false
        }
        this.onSave = this.onSave.bind(this);
        this.onEditStart = this.onEditStart.bind(this);
        this.onEditCancel = this.onEditCancel.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onSave() {
        const amount = parseInt(this.state.amount);
        if(isNaN(amount) || amount % 1 !== 0 || amount < 0) {
            this.setState({ error: "Ongeldig aantal. Vul een positief nummer zonder decimalen in." });
            return;
        }
        this.setState({ saving: true });
        this.props.onError(null);
        axios.post("/setInventoryProductAmount", {
            productId: this.props.product.id,
            amount
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.setProduct(response.data.product);
                    this.setState({ editing: false });
                } else {
                    this.props.onError("Er ging iets fout bij het opslaan van de locatie. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                this.props.onError("Er ging iets fout bij het opslaan van de locatie.");
                console.error(error);
            })
            .finally(() => {
                this.setState({ saving: false });
            })
    }

    onEditStart() {
        this.setState({ editing: true, amount: this.props.product.amount.toString() })
    }

    onEditCancel() {
        this.setState({ editing: false });
        this.props.onError(null);
    }

    onChange(event) {
        this.setState({ amount: event.target.value });
    }

    render() {
        const product = this.props.product;
        return (
            <React.Fragment>
                <span className="d-none d-print-block">
                    { numberFormatter({ number: product.amount }) }
                </span>
                <div className="d-print-none">
                    { !this.state.editing ? (
                        <OverlayTrigger overlay={
                            <Tooltip id="edit">Aantal wijzigen</Tooltip>
                        }>
                            <Button
                                variant="editable-blue"
                                onClick={ this.onEditStart }
                                className="py-1 px-2"
                            >
                                { numberFormatter({ number: product.amount }) }
                            </Button>
                        </OverlayTrigger>
                    ) : (
                        <InputGroup style={{ width: "200px" }} className="mb-0">
                            <FormControl
                                type="number"
                                value={ this.state.amount }
                                onChange={ this.onChange }
                                placeholder="Aantal"
                                disabled={ this.state.saving }
                            />
                            <InputGroup.Append>
                                <OverlayTrigger overlay={
                                    <Tooltip id="save">Opslaan</Tooltip>
                                }>
                                    <Button variant="primary" onClick={ this.onSave } disabled={ this.state.saving }>
                                        <i className="fas fa-save"/>
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={
                                    <Tooltip id="cancel">Annuleer</Tooltip>
                                }>
                                    <Button variant="secondary" onClick={ this.onEditCancel } disabled={ this.state.saving }>
                                        <i className="fas fa-trash-alt"/>
                                    </Button>
                                </OverlayTrigger>
                            </InputGroup.Append>
                        </InputGroup>
                    )}
                </div>
            </React.Fragment>
        );
    }

}

export default ProductQuickActionSetAmount;
