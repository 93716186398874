import React from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import PaginationBar from "../../../components/PaginationBar";
import InventoryProductHistoryItemCard from "./InventoryProductHistoryItemCard";

class InventoryHistoryContent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            history: null,
            totalAmount: null,
            error: null,

            page: 0,
            amountPerPage: 20
        };
        this.onPageChange = this.setPage.bind(this);
    }

    componentDidMount() {
        this.setPage(1);
    }

    setPage(page) {
        this.setState({ page: page - 1 });
        this.getHistory(page - 1);
    }

    getHistory(page) {
        if(page < 0) {
            return;
        }
        this.setState({ history: null, error: null });
        let limit = this.state.amountPerPage;
        let offset = page * limit;
        axios.post("/getInventoryProductHistory", { productId: this.props.productId, limit, offset })
            .then((response) => {
                if(response.data.valid) {
                    if(page === this.state.page) {
                        const history = response.data.data;
                        const totalAmount = response.data.totalAmount;
                        this.setState({ history, totalAmount });
                    }
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    getPaginationBar(marginBottom) {
        if(this.state.totalAmount === null) {
            return (
                <div className={ marginBottom ? "mb-2" : "mb-0" }>
                    <Skeleton height={ 38 }/>
                </div>
            )
        }
        if(this.state.totalAmount > this.state.amountPerPage) {
            return (
                <PaginationBar
                    page={ this.state.page + 1 }
                    pagesAmount={ Math.ceil(this.state.totalAmount / this.state.amountPerPage) }
                    onPageChange={ this.onPageChange }
                    style={{}}
                    className={ marginBottom ? "mb-2" : "mb-0" }
                />
            )
        }
        return null;
    }

    render() {
        return (
            <React.Fragment>
                { this.getPaginationBar(true) }
                { this.state.error ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : !this.state.history ? (
                    <React.Fragment>
                        {[...Array(this.state.amountPerPage)].map((value, index) => (
                            <div className="mb-2" key={ index }>
                                <Skeleton height={ 64 }/>
                            </div>
                        ))}
                    </React.Fragment>
                ) : this.state.history.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-history"/></h1>
                        <h3>Geen geschiedenis</h3>
                        <p className="mb-0">Er zijn nog geen wijzigingen in de voorraad geweest voor dit product.</p>
                    </div>
                ) : this.state.history.map((historyItem) => (
                    <InventoryProductHistoryItemCard
                        key={ historyItem.id }
                        historyItem={ historyItem }
                    />
                ))}
                { this.getPaginationBar(false) }
            </React.Fragment>
        );
    }

}

export default InventoryHistoryContent;
