import React from "react";
import { createRoot } from 'react-dom/client';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import ReactGA from "react-ga";

import moment from "moment";
import "moment/locale/nl";
import axios from "axios";
import { stringify } from "qs";

import App from "./App";
import AnimatedLogo from "./components/AnimatedLogo";

import * as serviceWorker from "./serviceWorker";

import "react-dates/initialize";

// Start Sentry if not development environment.
const development = process.env.REACT_APP_ENVIRONMENT === "development";
if(development) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://b69f1940df0343a7a3d50cc651bb842e@sentry1.zsnode.com/8",
        beforeSend(event, hint) {
            if (event.exception) {
                Sentry.showReportDialog({
                    eventId: event.event_id,
                    title: "Er is een fout opgetreden.",
                    subtitle: "De foutmelding is verstuurd.",
                    subtitle2: "Vul hieronder in wat je deed vlak voor deze melding is verschenen.",
                    labelName: "Naam",
                    labelEmail: "Email",
                    labelComments: "Wat gebeurde er?",
                    labelClose: "Sluiten",
                    labelSubmit: "Versturen",
                    errorGeneric: "Er ging iets fout bij het versturen van je feedback. Probeer het opnieuw.",
                    errorFormEntry: "Sommige velden zijn ongeldig. Controleer de velden en probeer het opnieuw.",
                    successMessage: "Je feedback is verstuurd. Bedankt!"
                });
            }
            return event;
        },
        autoSessionTracking: true,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        // We recommend adjusting this value in production, or using tracesSampler for finer control
        tracesSampleRate: 1.0,
    });
}

moment.locale('nl');

ReactGA.initialize(
    [
        { trackingId: "UA-65727447-12", gaOptions: { name: "default" } }
    ],
    { debug: false, alwaysSendToDefaultTracker: false }
);
ReactGA.pageview(window.location.pathname, ["default"]);

if(development) {
    axios.defaults.baseURL = "/v2/";
} else {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL ? "https://" + process.env.REACT_APP_API_URL + "/v2/" : "https://dev.api.intranet.smithsolar.nl/v2/";
}
axios.defaults.withCredentials = true;

axios.defaults.transformRequest = [function (data) {
    data = stringify(data);
    return data;
}];

axios.interceptors.request.use((config) => {
    AnimatedLogo.pushNetworkActivity();
    return config;
}, (error) => {
    AnimatedLogo.popNetworkActivity();
    return Promise.reject(error);
});
axios.interceptors.response.use((response) => {
    AnimatedLogo.popNetworkActivity();
    return response;
}, (error) => {
    AnimatedLogo.popNetworkActivity();
    return Promise.reject(error);
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
