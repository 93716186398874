import React, {
    useContext, useEffect
} from "react";
import {
    AppleMaps
} from "@zandor300/react-apple-mapkitjs";
import {
    Alert
} from "react-bootstrap";

import AppleMapsContext from "./AppleMapsManager";
import Loading from "../Loading";

function AppleMapsMap({ children, ...props }) {
    const appleMapsContext = useContext(AppleMapsContext);

    useEffect(() => {
        appleMapsContext.refreshTokenIfNeeded();
    }, [appleMapsContext]);

    if(appleMapsContext.error) {
        return (
            <Alert variant="danger">{ appleMapsContext.error }</Alert>
        )
    }
    if(!appleMapsContext.token) {
        return (
            <Loading/>
        )
    }
    return (
        <AppleMaps
            { ...props }
            token={ appleMapsContext.token }
        >
            { children }
        </AppleMaps>
    )
}

export default React.memo(AppleMapsMap);
