import React from "react";

import TagPill from "../../../components/tagPill";
import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";

function PlanningItemListItem({ planningItem }) {
    const address = planningItem.street + " " + planningItem.houseNumber + ", " + planningItem.city;

    let status = null;
    if(planningItem.lead) {
        status = planningItem.lead.status;
    } else if(planningItem.outage) {
        status = planningItem.outage.status;
    } else if(planningItem.installation) {
        status = planningItem.installation.status;
    }

    let description = planningItem.description;
    if(planningItem.descriptionSuffix) {
        description += "\n\n" + planningItem.descriptionSuffix;
    }

    return (
        <div>
            <div className="d-flex">
                <div className="flex-grow-1">
                    <TagPill color={ planningItem.type.color }>
                        { planningItem.type.name }
                    </TagPill>
                </div>
                { status && (
                    <div>
                        <TagPill color={ status.color }>
                            { status.name }
                        </TagPill>
                    </div>
                )}
            </div>
            <div className="mb-1">
                { planningItem.name ? (
                    <b>{ planningItem.name }</b>
                ) : (
                    <b className="text-danger">Onbekend</b>
                )}
                <br/>
                <span className="text-muted">
                    { address }
                </span>
            </div>
            <div style={{ fontSize: "0.9rem" }}>
                { description.trim().length > 0 && (
                    <ReactMarkdownBreaks message={ description }/>
                )}
            </div>
        </div>
    )
}

export default React.memo(PlanningItemListItem);
