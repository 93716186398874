import React, {
    useMemo,
    useState
} from "react";
import copy from "copy-to-clipboard";
import {
    Button
} from "react-bootstrap";
import {
    CSSTransition
} from "react-transition-group";

function CopyButton({ text, onClick, children, ...props }) {
    const [copied, setCopied] = useState(false);

    const onClickCopy = useMemo(() => {
        return () => {
            copy(text, { format: "text/plain" });
            if(onClick) {
                onClick();
            }
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        }
    }, [text, onClick]);

    return (
        <React.Fragment>
            <Button { ...props } onClick={ onClickCopy }>
                { children }
            </Button>
            <CSSTransition in={ copied } timeout={200} classNames="copy-fade" unmountOnExit>
                <small className="text-primary mx-2">
                    Gekopieërd
                </small>
            </CSSTransition>
        </React.Fragment>
    )
}

export default React.memo(CopyButton);
