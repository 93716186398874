import React from "react";
import {
    Title
} from "@zandor300/backoffice-framework";

import login from "./img/login.png";
import leads from "./img/leads.png";

function AppHelpCard({ title, image, children }) {
    return (
        <div className="card mb-3">
            <div className="card-body">
                { title && (
                    <h5 className="card-title">{ title }</h5>
                )}
                { image ? (
                    <div className="row">
                        <div className="col">
                            { children }
                        </div>
                        <div className="col-md-3 col-lg-2 mt-3 mt-md-0">
                            <img src={ image } className="img-fluid border-primary border rounded" alt={ title }/>
                        </div>
                    </div>
                ) : children }
            </div>
        </div>
    )
}

function SalesApp() {
    return (
        <React.Fragment>
            <Title preTitle="Help">
                Sales App
            </Title>

            <AppHelpCard>
                <p className="card-text">
                    De Sales app is een toevoeging op het Intranet systeem. De app geeft de mogelijkheid informatie in het
                    intranet bij te werken op afstand. Het hoofddoel van de app is het gebruik door verkoop (Sales)
                    medewerkers als zij bij klanten thuis op afspraak zijn. Daarbij kunnen ze notities toevoegen aan de lead
                    en foto's uploaden die gemaakt zijn tijdens de opname van de installatie. Deze zijn dan direct
                    beschikbaar via het Intranet online.
                </p>
            </AppHelpCard>

            <AppHelpCard title="Login" image={ login }>
                <p className="card-text">
                    Inloggen in de app gebeurt met dezelfde logingegevens die gebruikt worden om in te loggen op het online
                    Intranet. Bij het opnenen van de app krijg je een inlogscherm te zien waar je je email en wachtwoord van
                    het Intranet kunt invullen.
                </p>
            </AppHelpCard>

            <AppHelpCard title="Leads" image={ leads }>
                <p className="card-text">
                    Het leads tabblad in de app levert de functionaliteit voor verkoop medewerkers om de lead uit het
                    Intranet bij te werken als zij op afspraak zijn bij de klant thuis. Door een lead aan te tikken kun
                    je de info van de lead bekijken, de status en toegewezen werknemer aanpassen, notities plaatsen en
                    foto's uploaden.
                </p>
                <p className="card-text">
                    In het overzicht staat er rechtsbovenin een QR Code knop. Door hierop te tikken opent een camera
                    scherm waarmee het mogelijk is de QR Code uit het online Intranet te scannen om de lead direct te
                    openen in de app.
                </p>
            </AppHelpCard>

            <AppHelpCard>
                <p className="card-text">
                    In het geval bovenstaande informatie niet je vraag heeft beantwoord, neem dan contact op door te
                    mailen naar <a href="mailto:zandor@smithtechniek.nl">zandor@smithtechniek.nl</a>.
                </p>
            </AppHelpCard>

        </React.Fragment>
    )
}

export default React.memo(SalesApp);
