import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";
import {
    Responsive
} from "react-grid-layout";

import ReactGridWidthProvider from "./components/ReactGridWidthProvider";
import numberFormatter from "../../components/formatters/NumberFormatter";
import Helmet from "../../components/Helmet";
import TagPill from "../../components/tagPill";
import DashboardCard from "./DashboardCard";
import DashboardPlanningWidget from "./widgets/DashboardPlanningWidget";
import DashboardWarningsWidget from "./widgets/DashboardWarningsWidget";
import BirthdayWidget from "./widgets/BirthdayWidget";

const ResponsiveGridLayout = ReactGridWidthProvider(Responsive);

class Dashboard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            error: null,

            breakpoint: "lg",
            layouts: {
                lg: this.getLayout("lg"),
                md: this.getLayout("md"),
                sm: this.getLayout("sm"),
                xs: this.getLayout("xs")
            }
        }
        this.currentBreakpoint = "lg";
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.onBreakpointChange = this.onBreakpointChange.bind(this);
    }

    componentDidMount() {
        this.getDashboardData();
    }

    getDashboardData() {
        this.setState({ data: null, error: null });
        axios.get("/getDashboardData", )
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ data: response.data });
                } else {
                    this.setState({ data: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ data: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    getLocalStorageKey(breakpoint) {
        return "dashboardLayout-" + breakpoint;
    }

    getLayout(breakpoint) {
        const localStorageLayout = localStorage.getItem(this.getLocalStorageKey(breakpoint));
        if(localStorageLayout !== null) {
            return JSON.parse(localStorageLayout);
        }
        return this.getDefaultLayout();
    }

    getDefaultLayoutForItem(key) {
        return this.getDefaultLayout().find((layout) => layout.i === key);
    }

    saveLayout(layout) {
        localStorage.setItem(this.getLocalStorageKey(this.currentBreakpoint), JSON.stringify(layout));
    }

    resetLayout() {
        ["lg", "md", "sm", "xs"].forEach((breakpoint) => {
            localStorage.removeItem(this.getLocalStorageKey(breakpoint));
        });
        this.setState({ layouts: {} });
    }

    onLayoutChange(layout, layouts) {
        this.setState({ layouts });
        this.saveLayout(layout);
    }

    onBreakpointChange(newBreakpoint) {
        this.currentBreakpoint = newBreakpoint;
        this.setState({ breakpoint: newBreakpoint });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet title="Dashboard"/>
                <Title
                    preTitle="Overzicht"
                    preChildren={
                        <div className="float-right mt-2">
                            <OverlayTrigger overlay={
                                <Tooltip id="reset">Reset layout</Tooltip>
                            }>
                                <button className="btn btn-secondary" onClick={ this.resetLayout.bind(this) }>
                                    <i className="fas fa-undo-alt"/>
                                </button>
                            </OverlayTrigger>
                        </div>
                    }
                >
                    Dashboard
                </Title>

                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}

                <BirthdayWidget/>

                <ResponsiveGridLayout
                    cols={{lg: 3, md: 3, sm: 2, xs: 1}}
                    breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480}}
                    layouts={ this.state.layouts }
                    isBounded={ true }
                    onLayoutChange={ this.onLayoutChange }
                    onBreakpointChange={ this.onBreakpointChange }
                    draggableCancel=".no-drag"
                >
                    <DashboardCard key="openReminders" data-grid={ this.getDefaultLayoutForItem("openReminders") }>
                        <DashboardCard.BodyHighlight
                            title="Openstaande herinneringen"
                            content={ this.state.data ? numberFormatter({ number: this.state.data.openReminders }) : "" }
                            subline={
                                <Link to="/reminders">
                                    Bekijken
                                </Link>
                            }
                            loading={ !this.state.data }
                            error={ this.state.error }
                        />
                    </DashboardCard>
                    <DashboardCard key="finishedInstallations" data-grid={ this.getDefaultLayoutForItem("finishedInstallations") }>
                        <DashboardCard.BodyHighlight
                            title="Planning"
                            content={ this.state.data ? numberFormatter({ number: this.state.data.finishedInstallations }) + " installaties" : "" }
                            subline={
                                <React.Fragment>
                                    <span>die (mogelijk) als </span>
                                    <TagPill color="#1c8200">Afgerond</TagPill>
                                    <span> kunnen worden gemarkeerd.</span>
                                    <br/>
                                    <Link to="/planning/installations/planned-in">
                                        Bekijken
                                    </Link>
                                </React.Fragment>
                            }
                            loading={ !this.state.data }
                            error={ this.state.error }
                        />
                    </DashboardCard>
                    <DashboardCard key="newLeads" data-grid={ this.getDefaultLayoutForItem("newLeads") }>
                        <DashboardCard.BodyHighlight
                            title="Nieuwe leads"
                            content={ this.state.data ? numberFormatter({ number: this.state.data.newLeads }) : "" }
                            subline={
                                <Link to={ "/leads?search=" + encodeURIComponent("status=nieuw") }>
                                    Bekijken
                                </Link>
                            }
                            loading={ !this.state.data }
                            error={ this.state.error }
                        />
                    </DashboardCard>
                    <DashboardCard key="newOutages" data-grid={ this.getDefaultLayoutForItem("newOutages") }>
                        <DashboardCard.BodyHighlight
                            title="Nieuwe storingen"
                            content={ this.state.data ? numberFormatter({ number: this.state.data.newOutages }) : "" }
                            loading={ !this.state.data }
                            error={ this.state.error }
                        />
                    </DashboardCard>
                    <DashboardCard key="toPlanInstallations" data-grid={ this.getDefaultLayoutForItem("toPlanInstallations") }>
                        <DashboardCard.BodyHighlight
                            title="Planning"
                            content={ this.state.data ? numberFormatter({ number: this.state.data.toPlanInstallations }) + " installaties" : "" }
                            subline={
                                <React.Fragment>
                                    <span>die ingepland kunnen worden.</span>
                                    <br/>
                                    <Link to="/planning/installations/to-plan">
                                        Bekijken
                                    </Link>
                                </React.Fragment>
                            }
                            loading={ !this.state.data }
                            error={ this.state.error }
                        />
                    </DashboardCard>
                    <DashboardWarningsWidget key="warnings" title="Waarschuwingen" data-grid={ this.getDefaultLayoutForItem("warnings") }/>
                    <DashboardPlanningWidget key="planningLeadsToday" title="Installaties vandaag" leadOnly installationOnly data-grid={ this.getDefaultLayoutForItem("planningLeadsToday") }/>
                    <DashboardPlanningWidget key="planningOutagesToday" title="Storingen vandaag" outageOnly data-grid={ this.getDefaultLayoutForItem("planningOutagesToday") }/>
                </ResponsiveGridLayout>
            </React.Fragment>
        )
    }

    getDefaultLayout() {
        return [
            { w: 1, h: 1, x: 0, y: 0, i: "openReminders" },
            { w: 1, h: 1, x: 0, y: 3, i: "finishedInstallations" },
            { w: 1, h: 1, x: 2, y: 0, i: "warnings" },
            { w: 1, h: 1, x: 0, y: 1, i: "newLeads" },
            { w: 1, h: 1, x: 0, y: 2, i: "newOutages" },
            { w: 1, h: 1, x: 1, y: 0, i: "toPlanInstallations" },
            { w: 1, h: 3, x: 1, y: 1, i: "planningLeadsToday", minH: 2 },
            { w: 1, h: 3, x: 2, y: 1, i: "planningOutagesToday", minH: 2 }
        ];
    }
}

export default Dashboard;
