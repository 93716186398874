import React from "react";
import moment from "moment";

import AbsenceUserItem from "./AbsenceUserItem.js";

function AbsenceUserRow(props) {
    // Make sure we don't crash when there are no absence items given
    if('absenceItems' in props && props.absenceItems.length <= 0)
        return null;

    // Sort the absence items by start date
    let absenceItems = props.absenceItems.sort((a, b) => {
        if(a.startDate < b.startDate)
            return -1;
        if(a.startDate > b.startDate)
            return 1;
        if(a.description < b.description)
            return -1;
        if(a.description > b.description)
            return 1;
        return 0;
    });

    let returnItems = [];
    let rowIndex = 0;

    while(absenceItems.length > 0) {
        let itemsForNextRow = [];
        let lastDay = 0;
        let prevEndDay = 0;

        returnItems.push(
            <tr key={rowIndex}>
                { absenceItems.map((absenceItem, index) => {
                    let moreOnPrevWeek = false;
                    let moreOnNextWeek = false;

                    const momentStartDate = moment(absenceItem.startDate, "YYYY-MM-DD");
                    const momentEndDate = moment(absenceItem.endDate, "YYYY-MM-DD");

                    // Get the start day of the absence item, starting with 0. If the event starts before this week, set the start day to 0
                    let startDay = momentStartDate.isoWeekday() - 1;
                    if(momentStartDate.isoWeek() < props.currentWeekStartMoment.isoWeek() || momentStartDate.isoWeekYear() < props.currentWeekStartMoment.isoWeekYear()) {
                        startDay = 0;
                        moreOnPrevWeek = true;
                    }
                    // Get the end day from the absence item. If the absence item ends after this week, the end day is the last day of the week
                    let endDay = momentEndDate.isoWeekday();
                    if(momentEndDate.isoWeek() > props.currentWeekStartMoment.isoWeek() || momentEndDate.isoWeekYear() > props.currentWeekStartMoment.isoWeekYear()) {
                        endDay = 5;
                        moreOnNextWeek = true;
                    }

                    // Calculate the amount of days the start day is from the previous absence item end day
                    const amountOfDaysFromLastItem =
                        index === 0 ? startDay : startDay - prevEndDay;

                    // If the amount of days from the previous absence item is less than 0, it is overlapping another absence item. Add it for the next row.
                    if(amountOfDaysFromLastItem < 0) {
                        itemsForNextRow.push(absenceItem);
                        return null;
                    }

                    // Update the actual latest day that has an absence item so we can add extra td's for after the last absence item
                    if(endDay > lastDay) {
                        lastDay = endDay;
                    }

                    prevEndDay = endDay;
                    return (
                        <React.Fragment key={absenceItem.id}>
                            { Array(amountOfDaysFromLastItem).fill(undefined).map((val, index) => {
                                return <td key={index} />
                            }) }

                            <AbsenceUserItem absenceItem={absenceItem}
                                startDay={startDay} endDay={endDay}
                                moreOnPrevWeek={moreOnPrevWeek} moreOnNextWeek={moreOnNextWeek}
                                onClick={() => props.onClick(absenceItem)}
                            />

                        </React.Fragment>
                    )
                })}

                { Array(Math.max(5 - lastDay, 0)).fill(undefined).map((val, index) => {
                    return <td key={index} />
                }) }
            </tr>
        );

        absenceItems = itemsForNextRow;
        rowIndex++;
    }

    return returnItems;
}

export default React.memo(AbsenceUserRow);
