import React from "react";

function SendEmailButton({ email, subject, body, children, className, size = "md", variant = "primary" }) {
    let classNames = ["btn", "btn-" + size, "btn-" + variant]
    if(className) {
        classNames.push(className);
    }

    let mailtoSuffix = [];
    if(subject) {
        mailtoSuffix.push("subject=" + subject);
    }
    if(body) {
        mailtoSuffix.push("body=" + body);
    }

    return (
        <a
            href={ "mailto:" + email + (mailtoSuffix.length > 0 ? "?" + mailtoSuffix.join("&") : "") }
            className={ classNames.join(" ") }
            target="_blank"
            rel="noopener noreferrer"
        >
            { children }
        </a>
    )
}

export default React.memo(SendEmailButton);
