import React from "react";
import axios from "axios";
import {
    Alert,
    Table
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import RestockOrderRow from "../../restock-orders/RestockOrderRow";

class ProductDetailRestockOrders extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            restockOrders: null,
            error: null
        }
    }

    componentDidMount() {
        this.getRestockOrders();
    }

    getRestockOrders() {
        axios.post("/getRestockOrdersForProduct", { productId: this.props.productId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ restockOrders: response.data.data });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    render() {
        return (
            <React.Fragment>
                <Table hover>
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <th className="text-center">
                                #
                            </th>
                            <th className="text-left">
                                Afgeleverd
                            </th>
                            <th>
                                Leverancier
                            </th>
                            <th>
                                Ordernummer
                            </th>
                            <th>
                                Beschrijving
                            </th>
                            <th>
                                Besteldatum
                            </th>
                            <th>
                                Verwachte leverdatum
                            </th>
                            <th>
                                Besteller
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.error ? (
                            <tr>
                                <td colSpan={ 8 }>
                                    <Alert variant="danger">{ this.state.error }</Alert>
                                </td>
                            </tr>
                        ) : !this.state.restockOrders ? (
                            <tr>
                                <td colSpan={ 8 }>
                                    <Loading/>
                                </td>
                            </tr>
                        ) : this.state.restockOrders.length === 0 ? (
                            <tr>
                                <td colSpan={ 8 } className="text-center">
                                    <h1><i className="fas fa-inventory"/></h1>
                                    <h3>Geen bestellingen</h3>
                                    <p>Er zijn geen bestellingen gevonden voor het huidige product.</p>
                                </td>
                            </tr>
                        ) : this.state.restockOrders.map((restockOrder) => (
                            <RestockOrderRow
                                key={ restockOrder.id }
                                restockOrder={ restockOrder }
                                to={ "/order/" + restockOrder.id }
                            />
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

}

export default ProductDetailRestockOrders;
