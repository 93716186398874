import React from "react";

import DetailOverviewRow from "./detail/DetailOverviewRow";

function ParentSummary({ lead, outage, installation, vatRefund }) {
    if(!lead && !outage && !installation && !vatRefund) {
        return null;
    }

    let parent = null;
    let name = null;
    let address = null;
    let postalCode = null;

    if(lead) {
        parent = "Lead";
        name = lead.name;
        address = lead.street + " " + lead.houseNumber + ", " + lead.city;
        postalCode = lead.postalCode;
    } else if(outage) {
        parent = "Storing";
        name = outage.name;
        address = outage.street + " " + outage.houseNumber + ", " + outage.city;
        postalCode = outage.postalCode;
    } else if(installation) {
        parent = "Installatie";
        name = installation.lead ? installation.lead.name : installation.outage.name;
        address = installation.street + " " + installation.houseNumber + ", " + installation.city;
        postalCode = installation.postalCode;
    } else if(vatRefund) {
        parent = "BTW Terugvraag";
        name = vatRefund.initials.split("").join(".") + ". " + vatRefund.lastName;
        address = vatRefund.street + " " + vatRefund.houseNumber + ", " + vatRefund.city;
        postalCode = vatRefund.postalCode;
    }

    return (
        <div className="card d-none d-print-block mb-3">
            <div className="card-body">
                <h4 className="card-title">
                    { parent }
                </h4>
                <table className="table table-borderless mb-0">
                    <tbody>
                    <DetailOverviewRow title="Naam" small>
                        { name }
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Adres" small>
                        { address }
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Postcode" small>
                        { postalCode }
                    </DetailOverviewRow>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default React.memo(ParentSummary);
