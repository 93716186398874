import React from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {
    Link
} from "react-router-dom";

function ReactMarkdownGFM({ message }) {
    return (
        <ReactMarkdown
            plugins={[gfm]}
            components={{
                a: ({ node, href, children, ...linkProps }) => {
                    const currentUrl = new URL(window.location.href);
                    const url = new URL(href);

                    const localUrl = currentUrl.protocol === url.protocol && currentUrl.hostname === url.hostname;
                    if(localUrl) {
                        return (
                            <Link to={ url.pathname }>
                                { children }
                            </Link>
                        )
                    } else {
                        return (
                            <a
                                href={ href }
                                target="_blank"
                                rel="noopener noreferrer"
                                { ...linkProps }
                            >
                                { children }
                            </a>
                        )
                    }
                },
                table: ({ node, children, ...tableProps }) => (
                    <table className="table" { ...tableProps }>
                        { children }
                    </table>
                )
            }}
        >
            { message }
        </ReactMarkdown>
    )
}

export default React.memo(ReactMarkdownGFM);
