import React from "react";
import {
    Modal,
    Form,
    Button,
    Alert,
    Spinner
} from "react-bootstrap";

import Loading from "../../Loading";
import DetailStatusExplanationModal from "./DetailStatusExplanationModal";

class DetailSetStatusModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            statuses: null,
            hasDeprecatedStatuses: false,
            error: null,
            errorSave: null,
            loading: false,

            showExplanation: false,

            selectedStatus: null,

            reason: ""
        };
        this.onShow = this.onShow.bind(this);
        this.onStatusSelected = this.onStatusSelected.bind(this);
        this.onChangeReason = this.onChangeReason.bind(this);
        this.didClickSave = this.didClickSave.bind(this);
        this.didClickShowExplanation = this.didClickShowExplanation.bind(this);
        this.handleCloseExplanationModal = this.handleCloseExplanationModal.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.statuses !== this.state.statuses) {
            if(!this.state.statuses) {
                this.setState({ hasDeprecatedStatuses: false });
            } else {
                const deprecatedStatuses = this.state.statuses.filter(status => status.deprecated);
                this.setState({ hasDeprecatedStatuses: deprecatedStatuses.length > 0 });
            }
        }
    }

    getStatuses() {
        throw new Error("getStatuses() not implemented!");
    }

    setStatus(onSuccess, onError) {
        throw new Error("setStatus() not implemented!");
    }

    addReasonComment(onSuccess, onError) {
        throw new Error("addComment() not implemented!");
    }

    getInitialStatus() {
        throw new Error("getInitialStatus() not implemented!");
    }

    shouldEnterReason() {
        return false;
    }

    didClickSave() {
        const reasonRequired = this.shouldEnterReason();
        if(reasonRequired) {
            if(this.state.reason.trim().length === 0) {
                this.setState({ errorSave: "Voor de huidige geselecteerde status is het opgeven van een reden vereist!" });
                return;
            }
        }
        this.setState({ loading: true, errorSave: null });
        this.setStatus((callbackStatus) => {
            if(reasonRequired) {
                this.addReasonComment((callbackComment) => {
                    if(callbackStatus) {
                        callbackStatus();
                    }
                    if(callbackComment) {
                        callbackComment();
                    }
                }, (error) => {
                    this.setState({ loading: false, errorSave: error });
                });
            } else {
                if(callbackStatus) {
                    callbackStatus();
                }
            }
        }, (error) => {
            this.setState({ loading: false, errorSave: error });
        });
    }

    onShow() {
        this.setState({ selectedStatus: this.getInitialStatus(), reason: "", statuses: null, errorSave: null, loading: false });
        this.getStatuses();
    }

    onStatusSelected(event) {
        this.setState({ selectedStatus: event.target.value });
    }

    onChangeReason(event) {
        this.setState({ reason: event.target.value });
    }

    explanationAvailable() {
        if(this.state.statuses === null) {
            return false;
        }
        const statusIndex = this.state.statuses.findIndex((status) => !!status.description);
        return statusIndex !== -1;
    }

    didClickShowExplanation() {
        this.setState({ showExplanation: true });
    }

    handleCloseExplanationModal() {
        this.setState({ showExplanation: false });
    }

    render() {
        const showReasonField = this.shouldEnterReason();
        const initialLoading = this.state.statuses === null;
        const saving = this.state.loading;
        const loading = initialLoading || saving;
        return (
            <React.Fragment>
                <DetailStatusExplanationModal
                    show={ this.state.showExplanation }
                    statuses={ this.state.statuses }
                    handleClose={ this.handleCloseExplanationModal }
                />
                <Modal show={ this.props.show && !this.state.showExplanation } onHide={ this.props.handleClose } onShow={ this.onShow }>
                    <Modal.Header closeButton>
                        <Modal.Title>Status veranderen</Modal.Title>
                        { this.explanationAvailable() && (
                            <Button
                                variant="link"
                                className="float-right"
                                onClick={ this.didClickShowExplanation }
                            >
                                <i className="fas fa-question-circle"/>
                            </Button>
                        )}
                    </Modal.Header>
                    <form className="mb-0">
                        <Modal.Body>
                            { this.state.error ? (
                                <Alert variant="danger">{ this.state.error }</Alert>
                            ) : initialLoading ? (
                                <Loading/>
                            ) : (
                                <React.Fragment>
                                    { this.state.errorSave && (
                                        <Alert variant="danger">{ this.state.errorSave }</Alert>
                                    )}
                                    { this.state.selectedStatus && this.state.selectedStatus.deprecated && (
                                        <Alert variant="warning">
                                            De geselecteerde status is gemarkeerd als verouderd. Gebruik bij voorkeur
                                            deze status niet.
                                        </Alert>
                                    )}
                                    <Form.Group controlId="assignedUser" className={ showReasonField ? "" : "mb-0" }>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={ this.state.selectedStatus }
                                            onChange={ this.onStatusSelected }
                                            disabled={ loading }
                                        >
                                            { this.state.hasDeprecatedStatuses ? (
                                                <React.Fragment>
                                                    <optgroup label="Statussen">
                                                        { this.state.statuses.filter(status => !status.deprecated).map( status => (
                                                            <option key={ status.id } value={ status.id } style={{ backgroundColor: status.color, color: "white" }}>
                                                                { status.name }
                                                            </option>
                                                        ))}
                                                    </optgroup>
                                                    <optgroup label="Verouderde statussen">
                                                        { this.state.statuses.filter(status => status.deprecated).map( status => (
                                                            <option key={ status.id } value={ status.id } style={{ backgroundColor: status.color, color: "white" }}>
                                                                { status.name }
                                                            </option>
                                                        ))}
                                                    </optgroup>
                                                </React.Fragment>
                                            ) : (
                                                this.state.statuses.map( status => (
                                                    <option key={ status.id } value={ status.id } style={{ backgroundColor: status.color, color: "white" }}>
                                                        { status.name }
                                                    </option>
                                                ))
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                    { showReasonField && (
                                        <Form.Group controlId="reason" className="mb-0">
                                            <Form.Label>Reden</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                value={this.state.reason}
                                                onChange={ this.onChangeReason }
                                                disabled={ loading }
                                            />
                                        </Form.Group>
                                    )}
                                </React.Fragment>
                            ) }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose } disabled={ loading }>
                                Annuleer
                            </Button>
                            <Button variant="primary" onClick={ this.didClickSave } disabled={ loading }>
                                { saving && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Opslaan
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
        )
    }
}

export default DetailSetStatusModal;
