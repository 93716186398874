import React from "react";
import {
    Pagination
} from "react-bootstrap";

function PaginationItem(props) {
    return (
        <Pagination.Item active={ props.currentPage === props.page } onClick={ () => props.onPageChange(props.page) }>
            { props.page }
        </Pagination.Item>
    )
}

function PaginationBar({
    pagesAmount,
    page,
    onPageChange,
    style = { marginBottom: "24px" },
    className
}) {
    const maxPages = 7;

    let maxBefore = Math.ceil(maxPages / 2);
    let maxAfter = Math.ceil(maxPages / 2);
    if(page < maxBefore) {
        maxAfter += maxBefore - page;
    } else if(pagesAmount - page < maxAfter) {
        maxBefore += maxAfter - (pagesAmount - page);
    }

    let firstPage = pagesAmount > maxPages ? page - maxBefore : 1;
    if(firstPage < 2) firstPage = 2;
    let lastPage = pagesAmount > maxPages ? page + maxAfter : pagesAmount;
    if(lastPage > pagesAmount - 1) lastPage = pagesAmount - 1;

    let pages = [];
    for(let i = firstPage; i <= lastPage; i++) {
        pages.push(i);
    }

    return (
        <Pagination style={ style } className={ className }>
            <Pagination.First onClick={ () => onPageChange(1) } disabled={ page <= 1 } />
            <Pagination.Prev onClick={ () => onPageChange(page - 1) } disabled={ page <= 1 } />
            <PaginationItem currentPage={ page } page={ 1 } onPageChange={ onPageChange } />
            { firstPage !== 2 && (
                <Pagination.Ellipsis disabled />
            )}

            { pages.map( value => ( <PaginationItem key={ value } currentPage={ page } page={ value } onPageChange={ onPageChange } /> )) }

            { lastPage !== pagesAmount - 1 && (
                <Pagination.Ellipsis disabled />
            )}

            { pagesAmount > 1 && (
                <PaginationItem currentPage={ page } page={ pagesAmount } onPageChange={ onPageChange } />
            )}
            <Pagination.Next onClick={ () => onPageChange(page + 1) } disabled={ page >= pagesAmount } />
            <Pagination.Last onClick={ () => onPageChange(pagesAmount) } disabled={ page >= pagesAmount } />
        </Pagination>
    )
}

export default React.memo(PaginationBar);
