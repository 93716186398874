import React from "react";
import axios from "axios";
import {
    Modal,
    Form,
    Button,
    Alert,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../components/Loading";

class OutageDetailSetPriorityModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            priorities: null,
            error: null,
            errorSave: null,

            selectedPriority: null,
            loading: false
        };
        this.onShow = this.onShow.bind(this);
        this.onPrioritySelected = this.onPrioritySelected.bind(this);
        this.setPriority = this.setPriority.bind(this);
    }

    getPriorities() {
        this.setState({ priorities: null, error: null });
        axios.get("/getOutagePriorities")
            .then((response) => {
                if(response.data.valid) {
                    let priorities = response.data.data;
                    this.setState((prevState) => {
                        let selectedPriority = prevState.selectedPriority;
                        if(selectedPriority === null) {
                            selectedPriority = priorities[0].id;
                        }
                        return { priorities, selectedPriority };
                    })
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    setPriority() {
        const priorityId = this.state.selectedPriority ? parseInt(this.state.selectedPriority) : null;
        if(priorityId === null || priorityId === 0) {
            this.setState({ errorSave: "Selecteer een prioriteit." });
            return;
        }
        this.setState({ loading: true, errorSave: null });
        axios.post("/setOutagePriority", { outageId: this.props.outage.id, priorityId })
            .then((response) => {
                if(response.data.valid) {
                    this.props.handlePriorityChanged(response.data.priority);
                } else {
                    this.setState({ loading: false, errorSave: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loading: false, errorSave: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    onShow() {
        this.setState({
            selectedPriority: this.props.outage.priority ? this.props.outage.priority.id : 0,
            priorities: null,
            errorSave: null,
            loading: false
        });
        this.getPriorities();
    }

    onPrioritySelected(event) {
        this.setState({ selectedPriority: event.target.value });
    }

    render() {
        const initialLoading = this.state.priorities === null || this.state.selectedPriority === null;
        const saving = this.state.loading;
        const loading = initialLoading || saving;
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                <Modal.Header closeButton>
                    <Modal.Title>Prioriteit veranderen</Modal.Title>
                </Modal.Header>
                <form className="mb-0">
                    <Modal.Body>
                        { this.state.error ? (
                            <Alert variant="danger">{ this.state.error }</Alert>
                        ) : initialLoading ? (
                            <Loading/>
                        ) : (
                            <React.Fragment>
                                { this.state.errorSave && (
                                    <Alert variant="danger">{ this.state.errorSave }</Alert>
                                )}
                                <Form.Group controlId="priority" className="mb-0">
                                    <Form.Label>Prioriteit</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={ this.state.selectedPriority }
                                        onChange={ this.onPrioritySelected }
                                        disabled={ loading }
                                    >
                                        { this.props.outage && this.props.outage.priority === null && (
                                            <option value={ 0 }/>
                                        )}
                                        { this.state.priorities.map( priority => (
                                            <option key={ priority.id } value={ priority.id } style={{ backgroundColor: priority.color, color: "white" }}>
                                                { priority.name }
                                            </option>
                                        ) )}
                                    </Form.Control>
                                </Form.Group>
                            </React.Fragment>
                        ) }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ this.props.handleClose } disabled={ loading }>
                            Annuleer
                        </Button>
                        <Button variant="primary" onClick={ this.setPriority } disabled={ loading }>
                            { saving && (
                                <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                            )}
                            Opslaan
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}

export default OutageDetailSetPriorityModal;
