import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import TagPill from "../../../components/tagPill";
import ParentSummary from "../../../components/ParentSummary";

class InstallationDetailVATRefunds extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            vatRefunds: null,
            error: null
        };
    }

    componentDidMount() {
        this.getVATRefunds();
    }

    getVATRefunds() {
        this.setState({ vatRefunds: null, error: null });
        axios.post("/getVATRefundsForInstallation", { installationId: this.props.installationId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ vatRefunds: response.data.data });
                } else {
                    this.setState({ vatRefunds: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ vatRefunds: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    render() {
        if(this.state.error !== null) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        if(this.state.vatRefunds === null || this.props.installation === null) {
            return (
                <React.Fragment>
                    {[...Array(20)].map((value, index) => (
                        <div className="mb-3" key={ index }>
                            <Skeleton height={ 100 }/>
                        </div>
                    ))}
                </React.Fragment>
            )
        }

        if(this.state.vatRefunds.length === 0) {
            return (
                <div className="text-center">
                    <h1><i className="fas fa-bolt"/></h1>
                    <h3>Geen BTW Terugvraag</h3>
                    <p>Er is geen BTW Terugvraag bekend bij deze installatie.</p>
                    <Link to={ "/vat-refunds/add/" + this.props.installation.id } className="btn btn-primary btn-sm">
                        <i className="fas fa-plus mr-2"/>
                        Nieuwe BTW Terugvraag
                    </Link>
                </div>
            )
        }
        return (
            <React.Fragment>
                <ParentSummary
                    installation={ this.props.installation }
                />
                <Link to={ "/vat-refunds/add/" + this.props.installation.id } className="btn btn-primary btn-sm mb-3">
                    <i className="fas fa-plus mr-2"/>
                    Nieuwe BTW Terugvraag
                </Link>
                { this.state.vatRefunds.map(vatRefund => (
                    <div className="card mb-3" key={ vatRefund.id }>
                        <div className="card-body">
                            <h5 className="card-title">
                                BTW Terugvraag #{ vatRefund.id }
                                <div className="float-right">
                                    <small>
                                        <TagPill className="ml-2" color={ vatRefund.status.color }>{ vatRefund.status.name }</TagPill>
                                    </small>
                                </div>
                            </h5>
                            { vatRefund.street + " " + vatRefund.houseNumber + ", " + vatRefund.city }
                            <Link to={ "/vat-refund/" + vatRefund.id } className="d-hidden stretched-link"/>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}

export default InstallationDetailVATRefunds;
