import React from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";

import Loading from "../../../components/Loading";

class OutageDetailEdit extends React.PureComponent {
    state = {
        error: null,
        success: false,

        outage: null,
        types: null
    };

    componentDidMount() {
        if(this.props.outage !== null) {
            this.setState({ outage: this.props.outage });
        }
        this.getTypes();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.outage !== prevProps.outage) {
            this.setState({ outage: this.props.outage });
        }
    }

    setOutageState(state) {
        this.setState((prevState) => {
            return { outage: { ...prevState.outage, ...state } }
        })
    }

    getTypes() {
        this.setState({ types: null, error: null });
        axios.get("/getOutageTypes")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ types: response.data.data });
                } else {
                    this.setState({ types: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ types: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    setOutage() {
        this.setState({ error: null, success: false });
        axios.post("/setOutage", {
            outageId: this.state.outage.id,

            typeId: this.state.outage.type === null ? null : this.state.outage.type.id,
            description: this.state.outage.description
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.setOutage(response.data.outage);
                    this.setState({ success: true });
                } else {
                    this.setState({ error: "Er was een fout bij het opslaan van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er was een fout bij het opslaan van de data." });
            })
            .finally(() => {
                window.scroll({ top: 0, behavior: "smooth" });
            });
    }

    render() {
        const outage = this.state.outage;
        return (
            <React.Fragment>
                { this.state.success && (
                    <Alert variant="success">Wijzigingen zijn opgeslagen.</Alert>
                )}
                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}
                { outage === null || this.state.types === null ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h4>Storingsdetails</h4>
                                <div className="form-group mb-0">
                                    <label htmlFor="type">Type</label>
                                    <select className="form-control" id="type" required
                                            value={outage.type === null ? 0 : outage.type.id}
                                            onChange={ (event) => {
                                                this.setOutageState({ type: parseInt(event.target.value) === 0 ? null : this.state.types.find((type) => type.id === parseInt(event.target.value)) });
                                            }}
                                    >
                                        <option value={0}>Onbepaald</option>
                                        { this.state.types && this.state.types.map((type) => (
                                            <option key={ type.id } value={ type.id } style={{ backgroundColor: type.color, color: "white" }}>
                                                { type.name }
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-body">
                                <h4>Beschrijving</h4>
                                <p className="text-muted">
                                    <b>
                                        Beschrijf hier de storing. Wat is er mis? Hoe is de klant hier achter gekomen?
                                        Welk materiaal zou de storing kunnen veroorzaken?
                                    </b>
                                </p>
                                <div className="form-group mb-0">
                                    <textarea
                                        className="form-control" id="description" rows={ 8 }
                                        value={outage.description}
                                        onChange={ (event) => this.setOutageState({ description: event.target.value })}
                                    />
                                </div>
                            </div>
                        </div>

                        <Button variant="primary" className="float-right" onClick={ this.setOutage.bind(this) }>
                            Opslaan
                        </Button>

                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default OutageDetailEdit;
