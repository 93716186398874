import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../../components/Helmet";
import Page404 from "../../Page404";

import OutageDetailOverview from "./OutageDetailOverview";
import OutageDetailReminders from "./OutageDetailReminders";
import OutageDetailPlanning from "./OutageDetailPlanning";
import OutageDetailImages from "./OutageDetailImages";
import OutageDetailEdit from "./OutageDetailEdit";

function OutageDetail(props) {
    const outageId = props.match.params.id;
    const urlPrefix = "/outage/" + outageId;

    const [outage, setOutage] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    const refreshOutage = useCallback(() => {
        setOutage(null);
        setError(null);
        setErrorCode(null);
        axios.post("/getOutage", { id: outageId })
            .then((response) => {
                if(response.data.valid) {
                    setOutage(response.data.data);
                } else {
                    setOutage(null);
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                    setErrorCode(response.data.error);
                }
            })
            .catch((error) => {
                console.error(error);
                setOutage(null);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, [outageId]);

    useEffect(() => {
        if(isNaN(parseInt(outageId))) {
            return;
        }
        refreshOutage();
    }, [outageId, refreshOutage]);

    const childProps = {
        id: outageId, // TODO: Replace usage with outageId.
        outageId,
        outage,
        error,
        setOutage,
        refreshOutage
    };

    if(isNaN(parseInt(outageId)) || outageId < 1 || errorCode === "DOESNT_EXIST") {
        return <Page404/>
    }

    const name = !outage ? null : (outage.lead ? outage.lead.name : outage.installation.lead.name);

    return (
        <React.Fragment>
            <Helmet title={ "Storing #" + outageId + (name ? ` - ${name}` : "") }/>

            <Title preTitle="Detail" noBottom={ error === null }>
                Storing <small className="text-muted">#{outageId}</small>
            </Title>

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : (
                <React.Fragment>
                    <TabBar>
                        <TabBarItem to={ urlPrefix }>
                            Overzicht
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/reminders" }>
                            Herinneringen
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/planning" }>
                            Planning
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/images" }>
                            Foto's
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/edit" } className="float-right">
                            Bewerken
                        </TabBarItem>
                    </TabBar>

                    <Switch>
                        <Route path={ urlPrefix } exact>
                            <OutageDetailOverview { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/reminders" }>
                            <OutageDetailReminders { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/planning" }>
                            <OutageDetailPlanning { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/images" }>
                            <OutageDetailImages { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/edit" }>
                            <OutageDetailEdit { ...childProps }/>
                        </Route>

                        <Route path="/">
                            <Redirect
                                to={{
                                    pathname: urlPrefix,
                                    state: {from: "/"}
                                }}
                            />
                        </Route>
                    </Switch>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default React.memo(OutageDetail);
