import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Link,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";
import TagPill from "../../components/tagPill";
import CurrentUserHighlight from "../../components/CurrentUserHighlight";
import PlanningItemNewModal from "./modal/PlanningItemNewModal";

class PlanningInstallationsToPlan extends React.Component {
    state = {
        installations: null,
        error: null,

        showNewModal: false,
        newModalInstallationId: null
    };

    componentDidMount() {
        this.getInstallations();
    }

    getInstallations() {
        axios.post("/getInstallations", { filter: "to-plan" })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ installations: response.data.data });
                } else {
                    this.setState({ installations: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ installations: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    planningItemAdded(planningItem) {
        this.setState({ showNewModal: false });
        this.props.history.push("/planning/week/" + moment(planningItem.date).format("gggg/WW"));
    }

    render() {
        const helmet = (
            <Helmet title={ "Planning - In te plannen" }/>
        );
        if(this.state.error !== null) {
            return (
                <React.Fragment>
                    { helmet }
                    <Alert variant="danger">{this.state.error}</Alert>
                </React.Fragment>
            )
        }
        if(this.state.installations === null) {
            return (
                <React.Fragment>
                    { helmet }
                    <Loading/>
                </React.Fragment>
            )
        }
        if(this.state.installations.length === 0) {
            return (
                <div className="text-center">
                    { helmet }
                    <h1><i className="fas fa-calendar"/></h1>
                    <h3>Geen installaties</h3>
                    <p>Er zijn geen installaties om in te plannen.</p>
                </div>
            )
        }
        return (
            <React.Fragment>
                { helmet }
                <PlanningItemNewModal
                    show={ this.state.showNewModal }
                    installationId={ this.state.newModalInstallationId }
                    handleClose={ () => this.setState({ showNewModal: false }) }
                    planningItemAdded={ this.planningItemAdded.bind(this) }
                />
                <table className="table results">
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <th className="text-center" style={{ width: "75px" }}>#</th>
                            <th className="text-left">Status</th>
                            <th className="text-left">Naam</th>
                            <th className="text-left">Adres</th>
                            <th className="text-left">Aangemaakt door</th>
                            <th className="text-left"/>
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.installations.map((installation) => (
                            <tr key={ installation.id } className={ installation.warnings.length > 0 ? "table-warning" : "" }>
                                <th className="text-center align-middle">
                                    { installation.id }
                                </th>
                                <td className="align-middle">
                                    <TagPill color={ installation.status.color }>
                                        { installation.status.name }
                                    </TagPill>
                                </td>
                                <td className="align-middle">
                                    { installation.lead.name }
                                </td>
                                <td className="align-middle">
                                    { `${installation.street} ${installation.houseNumber}, ${installation.city}` }
                                </td>
                                <td className="align-middle">
                                    <CurrentUserHighlight user={ installation.author } emptyState="Onbekend"/>
                                </td>
                                <td className="align-middle">
                                    <div className="btn-group float-right">
                                        <Link to={ "/installation/" + installation.id } className="btn btn-secondary btn-sm">
                                            Installatie openen
                                        </Link>
                                        <button className="btn btn-primary btn-sm" onClick={ () => this.setState({ showNewModal: true, newModalInstallationId: installation.id })}>
                                            Inplannen
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default withRouter(PlanningInstallationsToPlan);
