import React, {
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert,
    Overlay,
    Tooltip
} from "react-bootstrap";
import CalendarHeatmap from "react-calendar-heatmap";

import Loading from "../../../../components/Loading";

function UserActivityHeatmap({ user, startDate, endDate }) {
    const [userActivityCount, setUserActivityCount] = useState(null);
    const [userActivityMax, setUserActivityMax] = useState(null);
    const [error, setError] = useState(null);

    const hoverTarget = useRef(null);
    const [hoverData, setHoverData] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);

    const userId = useMemo(() => {
        if(!user) {
            return null;
        }
        return parseInt(user.id);
    }, [user]);

    useEffect(() => {
        if(!userId) {
            return;
        }
        setUserActivityCount(null);
        setError(null);
        setShowTooltip(false);
        axios.post("/getUserActivity", { userId, startDate, endDate })
            .then((response) => {
                if(response.data.valid) {
                    let activitiesPerDay = {};
                    let maxValue = 0;
                    response.data.data.forEach((activity) => {
                        const dateString = moment(activity.date).format("YYYY-MM-DD");
                        if(dateString in activitiesPerDay) {
                            activitiesPerDay[dateString]++;
                        } else {
                            activitiesPerDay[dateString] = 1;
                        }
                        if(activitiesPerDay[dateString] > maxValue) {
                            maxValue = activitiesPerDay[dateString];
                        }
                    });
                    setUserActivityCount(Object.entries(activitiesPerDay).map(([date, count]) => {
                        return { date, count };
                    }));
                    setUserActivityMax(maxValue);
                } else {
                    setUserActivityCount(null);
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setUserActivityCount(null);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, [userId, startDate, endDate]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!userActivityCount) {
        return (
            <Loading/>
        )
    }
    return (
        <React.Fragment>
            <CalendarHeatmap
                startDate={new Date(startDate)}
                endDate={new Date(endDate)}
                values={ userActivityCount }
                classForValue={(value) => {
                    if (!value) {
                        return 'color-empty';
                    }
                    return `color-scale-${Math.ceil(value.count / userActivityMax * 4)}`;
                }}
                onMouseOver={ (event, value) => {
                    hoverTarget.current = event.target;
                    setHoverData(value);
                    setShowTooltip(true);
                }}
                onMouseLeave={ () => {
                    setShowTooltip(false);
                }}
                monthLabels={["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"]}
                weekdayLabels={["zo", "ma", "di", "wo", "do", "vr", "za"]}
                showWeekdayLabels
                gutterSize={ 2 }
            />
            <Overlay target={ hoverTarget.current } show={ showTooltip } placement="top">
                {(props) => (
                    <Tooltip id="heatmap-tooltip" {...props}>
                        { hoverData && (
                            <React.Fragment>
                                <span>
                                    { hoverData.count } activiteiten
                                </span>
                                <br/>
                                <span className="text-muted">
                                    { moment(hoverData.date).format("LLLL").replace("00:00", "").trim() }
                                </span>
                            </React.Fragment>
                        )}
                    </Tooltip>
                )}
            </Overlay>
        </React.Fragment>
    );
}

export default React.memo(UserActivityHeatmap);
