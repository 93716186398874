import React, {
    useState
} from "react";

import PlanningItemCalendarCard from "./PlanningItemCalendarCard";
import PlanningDayCalendarAssignedUserCheckbox from "./PlanningDayCalendarAssignedUserCheckbox";

function PlanningDayCalendarRow({ onClickPlanningItem, planningItem, warningUserIds, users, onPlanningItemUpdated, onError }) {
    const [loading, setLoading] = useState(false);

    let classNames = [];
    if(loading) {
        classNames.push("bg-disabled");
    } else if(planningItem.assignedUsers.length === 0) {
        classNames.push("table-warning");
    }

    return (
        <tr className={ classNames.join(" ") }>
            <td>
                <PlanningItemCalendarCard
                    noBottomMargin
                    noDescription
                    planningItem={ planningItem }
                    className={ loading ? "pointer-disabled" : ""}
                    onClick={ () => { if(!loading) { onClickPlanningItem(planningItem) } } }
                />
            </td>
            { users.map((user) => {
                const warning = warningUserIds.findIndex((userId) => userId === user.id) !== -1;
                return (
                    <td key={ user.id } className={ warning ? "table-warning" : ""}>
                        <PlanningDayCalendarAssignedUserCheckbox
                            loading={ loading }
                            setLoading={ setLoading }
                            planningItem={ planningItem }
                            user={ user }
                            onPlanningItemUpdated={ onPlanningItemUpdated }
                            onError={ onError }
                        />
                    </td>
                )
            })}
        </tr>
    )
}

export default React.memo(PlanningDayCalendarRow);
