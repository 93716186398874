import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import SimpleMDE from "react-simplemde-editor";

class ReminderBaseModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            users: null,
            types: null,
            error: null,
            loading: false,

            assignedUserId: null,
            typeId: null,
            date: null,
            message: null
        }
        this.onSave = this.save.bind(this);
        this.onShow = this.show.bind(this);
        this.onEditorChange = this.editorChange.bind(this);
        this.simpleMdeOptions = {
            spellChecker: false
        }
    }

    getModalTitle() {
        throw Error("getModalTitle not implemented!");
    }

    resetState() {
        throw Error("resetState not implemented!");
    }

    save() {
        throw Error("save not implemented!");
    }

    show() {
        this.resetState();
        this.getUsers();
        this.getTypes();
    }

    getUsers() {
        axios.get("/getUsers")
            .then((response) => {
                if(response.data.valid) {
                    let users = response.data.data.filter(user => {
                        return user.enabled && user.intranet;
                    });
                    users = users.sort((user1, user2) => {
                        if(user1.name < user2.name) return -1;
                        if(user1.name > user2.name) return 1;
                        return 0;
                    });
                    this.setState({ users: users });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    getTypes() {
        axios.get("/getReminderTypes")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ types: response.data.data });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    editorChange(value) {
        this.setState({ message: value });
    }

    render() {
        return (
            <Modal
                size="xl"
                show={ this.props.show }
                onHide={ this.props.handleClose }
                onShow={ this.onShow }
            >
                <Modal.Header closeButton>
                    <Modal.Title>{ this.getModalTitle() }</Modal.Title>
                </Modal.Header>
                <form className="mb-0">
                    <Modal.Body>
                        { this.state.error && (
                            <Alert variant="danger">{ this.state.error }</Alert>
                        )}
                        { this.state.users !== null ? (this.props.reminder !== null && (
                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Group controlId="assignedUser">
                                        <Form.Label>Toegewezen werknemer</Form.Label>
                                        <Form.Control as="select" value={ this.state.assignedUserId } disabled={ this.state.loading }
                                                      onChange={ (event) => this.setState({ assignedUserId: event.target.value })}>
                                            <option value={ -1 }>Iedereen</option>
                                            { this.state.users != null && this.state.users.map((user) => (
                                                <option key={ user.id } value={ user.id }>
                                                    { user.name }
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="type">
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control as="select" value={ this.state.typeId } disabled={ this.state.loading }
                                                      onChange={ (event) => this.setState({ typeId: event.target.value })}>
                                            { this.state.types != null && this.state.types.map((type) => (
                                                <option
                                                    key={ type.id }
                                                    value={ type.id }
                                                    style={{ backgroundColor: type.color, color: "white" }}
                                                >
                                                    { type.name }
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="date">
                                        <Form.Label>Datum</Form.Label>
                                        <Form.Control type="date" value={ this.state.date } disabled={ this.state.loading }
                                                      onChange={ (event) => this.setState({ date: event.target.value })}/>
                                    </Form.Group>
                                </div>
                                <div className="col">
                                    <p className="mb-2">Beschrijving</p>
                                    <SimpleMDE
                                        value={ this.state.message }
                                        onChange={ this.onEditorChange }
                                        options={ this.simpleMdeOptions }
                                    />
                                </div>
                            </div>
                        )) : (
                            <Loading/>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                            Annuleer
                        </Button>
                        <Button variant="primary" onClick={ this.onSave } disabled={ this.state.loading }>
                            { this.state.loading && (
                                <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                            )}
                            Opslaan
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

export default ReminderBaseModal;
