import React from 'react';

import {
	Route,
	Redirect
} from "react-router-dom";

function PrivateRoute({ children, authenticated, target = "/login", component,  ...rest }) {
	const ComponentObject = component;
	let renderRoute = children;
	if(component)
		renderRoute = <ComponentObject/>;

	return (
		<Route
			{...rest}
			render={({ location }) =>
				authenticated ? (
					renderRoute
				) : (
					<Redirect
						to={{
							pathname: target,
							state: { from: location }
						}}
					/>
				)
			}
		/>
	);
}

export default PrivateRoute;
