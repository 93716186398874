import React from "react";
import {
    Badge,
    Button,
    ButtonGroup
} from "react-bootstrap";

function CommentStyleSwitcher({ style, setStyle }) {
    return (
        <div className="d-flex align-items-center d-print-none">
            <div className="mr-2">
                <b>Style</b>
            </div>
            <ButtonGroup size="sm">
                <Button variant="outline-secondary" active={ style === "original" } onClick={ () => setStyle("original") }>
                    Origineel
                </Button>
                <Button variant="outline-secondary" active={ style === "compact" } onClick={ () => setStyle("compact") }>
                    Compact <Badge variant="dark" className="ml-1">Beta</Badge>
                </Button>
            </ButtonGroup>
        </div>
    )
}

export default React.memo(CommentStyleSwitcher);
