import axios from "axios";

import DetailSetAssignedUserModal from "../../../../components/detail/modal/DetailSetAssignedUserModal";

class LeadDetailSetAssignedUserModal extends DetailSetAssignedUserModal {
    getInitialAssignedUser() {
        return this.props.lead.assignedUser ? this.props.lead.assignedUser.id : null;
    }

    setAssignedUser(assignedUserId) {
        this.setState({ loading: true, errorSave: null });
        axios.post("/setLeadAssignedUser", { userId: assignedUserId, leadId: this.props.lead.id })
            .then((response) => {
                if(response.data.valid) {
                    this.props.handleAssignedUserChanged(response.data.user);
                } else {
                    this.setState({ loading: false, errorSave: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loading: false, errorSave: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }
}

export default LeadDetailSetAssignedUserModal;
