import React from "react";
import axios from "axios";

import DetailPlanning from "../../../components/detail/DetailPlanning";

class InstallationDetailPlanning extends DetailPlanning {
    getPlanningItems() {
        this.setState({ planningItems: null, error: null });
        axios.post("/getPlanningItemsForInstallation", { installationId: this.props.installationId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ planningItems: this.sortPlanningItems(response.data.data) });
                } else {
                    this.setState({ planningItems: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ planningItems: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    onPlanningItemAddedDeleted() {
        if(this.props.refreshInstallation) {
            this.props.refreshInstallation();
        }
    }

    getEmptyStateDescription() {
        return (
            <p>Er zijn nog geen planning items voor deze installatie.</p>
        )
    }
}

export default InstallationDetailPlanning;
