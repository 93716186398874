import React from "react";
import axios from "axios";
import {
    Switch,
    Route,
    Link
} from "react-router-dom";
import {
    Alert,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import OutageRow from "./OutageRow";
import TagPill from "../../components/tagPill";
import Loading from "../../components/Loading";
import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import OverviewSortTableHeader from "../../components/overview/OverviewSortTableHeader";
import Page404 from "../Page404";
import OutageAddStep1 from "./OutageAddStep1";
import OutageAddStep2 from "./OutageAddStep2";
import numberFormatter from "../../components/formatters/NumberFormatter";
import OutageAddInstallation from "./OutageAddInstallation";
import OutagesMap from "./OutagesMap";

class OutagesPage extends React.PureComponent {
    state = {
        outagesData: null,
        outagesSearch: "",
        lastSortKey: "",
        error: null
    };

    componentDidMount() {
        this.LoadOutagesData();

        if(window.location.search.startsWith("?search=")) {
            const searchString = decodeURI(window.location.search.split("=")[1]);
            this.setState({ outagesSearch: searchString });
            this.setSearchKey(searchString);
            this.props.history.push(window.location.pathname)
        } else {
            this.setState({ outagesSearch: this.getSearchKey() });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.pageType !== this.props.match.params.pageType) {
            this.LoadOutagesData();
        }
    }

    getSearchKey() {
        return localStorage.getItem("outagesSearchKey") || "";
    }

    setSearchKey(value) {
        localStorage.setItem("outagesSearchKey", value);
    }

    getSortKey() {
        return localStorage.getItem("outagesSortKey") || "date";
    }

    setSortKey(value) {
        localStorage.setItem("outagesSortKey", value);
    }

    getSortAscending() {
        return (localStorage.getItem("outagesSortAscending") || "1") === "1";
    }

    setSortAscending(value) {
        localStorage.setItem("outagesSortAscending", value ? "1" : "0");
    }

    SearchOutages(e) {
        this.setState({ outagesSearch: e.target.value });
        this.setSearchKey(e.target.value);
    }

    SortOutagesData(outages, key, ascending = true) {
        this.setState({ lastSortKey: !ascending ? "p" + key : key });
        this.setSortKey(key);
        this.setSortAscending(ascending);
        if(!outages) {
            return outages;
        }
        return outages.sort(((a, b) => {
            let aValue = a[key] && (typeof a[key] === "object" ? ("name" in a ? a[key]['name'] : a[key]['id']) : a[key]);
            aValue = typeof aValue === 'string'? aValue.toLowerCase() : aValue;
            let bValue = b[key] && (typeof b[key] === "object" ? ("name" in b ? b[key]['name'] : b[key]['id']) : b[key]);
            bValue = typeof bValue === 'string'? bValue.toLowerCase() : bValue;

            if(aValue && bValue) {
                if(aValue["weight"] && bValue["weight"]) {
                    if(aValue.weight < bValue.weight) {
                        return ascending ? 1 : -1;
                    } else if(aValue.weight > bValue.weight) {
                        return ascending ? -1 : 1;
                    }
                } else if(aValue["name"] && bValue["name"]) {
                    if(aValue.name < bValue.name) {
                        return ascending ? 1 : -1;
                    } else if(aValue.name > bValue.name) {
                        return ascending ? -1 : 1;
                    }
                } else if(aValue["id"] && bValue["id"]) {
                    if(aValue.id < bValue.id) {
                        return ascending ? 1 : -1;
                    } else if(aValue.id > bValue.id) {
                        return ascending ? -1 : 1;
                    }
                } else if(aValue < bValue) {
                    return ascending ? 1 : -1;
                } else if(aValue > bValue) {
                    return ascending ? -1 : 1;
                }
            } else if(aValue && !bValue) {
                return ascending? 1 : -1;
            } else if(!aValue && bValue) {
                return ascending? -1 : 1;
            } else if(a.id < b.id) {
                return ascending? 1 : -1;
            }
            return ascending? -1 : 1;
        }))
    }

    LoadOutagesData() {
        this.setState({ outagesData: null, error: null });
        axios.post("/getOutages", { page: this.getPageType() })
            .then((response) => {
                if(response.data.valid) {
                    if(response.data.page !== undefined && response.data.page !== this.getPageType()) {
                        return;
                    }
                    this.setState({ outagesData: this.SortOutagesData(response.data.data, this.getSortKey(), this.getSortAscending()) });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    SortOutages(key = "date", ascending = null) {
        if(typeof ascending !== 'boolean') {
            ascending = this.state.lastSortKey !== key;
        }
        this.setState((state) => {
            return {
                outagesData: this.SortOutagesData(state.outagesData, key, ascending)
            }
        });
    }

    ResetSortingAndSearching() {
        this.setState({ outagesSearch: "" });
        this.setSearchKey("");
        this.SortOutages("date", true);
    }

    getOutagesTypeTitle() {
        switch(this.getPageType()) {
            case "all":
                return "Alle storingen";
            default:
            case "running":
                return "Lopende storingen";
            case "finished":
                return "Opgeloste storingen";
        }
    }

    getPageType() {
        switch(this.props.match.params.pageType) {
            case "all":
                return "all";
            case undefined:
            case "running":
                return "running";
            case "finished":
                return "finished";
            default:
                return undefined;
        }
    }

    render() {
        if(this.getPageType() === undefined) {
            return (
                <Page404/>
            )
        }
        return (
            <React.Fragment>
                <Helmet title={ this.getOutagesTypeTitle() }/>

                <Title
                    preTitle="Overzicht"
                    preChildren={
                        <div className="float-right mt-2">
                            <OverlayTrigger overlay={
                                <Tooltip id="reset">Nieuwe storing</Tooltip>
                            }>
                                <Link to="/outages/add/lead" className="btn btn-primary">
                                    <i className="fas fa-plus fa-fw"/>
                                </Link>
                            </OverlayTrigger>
                        </div>
                    }
                    noBottom
                >
                    { this.getOutagesTypeTitle() }
                    <small className='ml-2'>
                        <TagPill>
                            { this.state.outagesData ? numberFormatter({ number: this.state.outagesData.length }) : 0 }
                        </TagPill>
                    </small>
                </Title>

                <TabBar noBottom>
                    <TabBarItem to="/outages">
                        Lopend
                    </TabBarItem>
                    <TabBarItem to="/outages/finished">
                        Opgelost
                    </TabBarItem>
                    <TabBarItem to="/outages/all">
                        Alle
                    </TabBarItem>
                    <TabBarItem to="/outages/map">
                        Kaart
                    </TabBarItem>

                    <div className="ml-auto align-self-center">
                        <div className="input-group float-right" style={{maxWidth: 300}}>
                            <input type="search" className="search form-control"
                                   placeholder="Zoeken" value={this.state.outagesSearch} onChange={this.SearchOutages.bind(this)} />
                            <div className="input-group-append">
                                <OverlayTrigger overlay={
                                    <Tooltip id="reset">Reset</Tooltip>
                                }>
                                    <button className="btn btn-secondary" onClick={this.ResetSortingAndSearching.bind(this)}>
                                        <i className="fas fa-undo"/>
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </TabBar>

                <Table hover striped size="sm" className="results">
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <OverviewSortTableHeader
                                title="#"
                                sortKey="id"
                                className="text-center"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortOutages.bind(this) }
                                minWidth="60px"
                            />
                            <OverviewSortTableHeader
                                title="Status"
                                sortKey="status"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortOutages.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Type"
                                sortKey="type"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortOutages.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Service instelling"
                                sortKey="serviceSetting"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortOutages.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Prioriteit"
                                sortKey="priority"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortOutages.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Naam"
                                sortKey="name"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortOutages.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Adres"
                                sortKey="street"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortOutages.bind(this) }
                            />
                            <OverviewSortTableHeader
                                title="Datum"
                                sortKey="date"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortOutages.bind(this) }
                                minWidth="150px"
                            />
                            <OverviewSortTableHeader
                                title="Toegewezen werknemer"
                                sortKey="assignedUser"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.SortOutages.bind(this) }
                            />
                        </tr>
                    </thead>

                    <tbody>
                        { this.state.error ? (
                            <tr className="loading-row">
                                <td colSpan={ 9 }>
                                    <Alert variant="danger">{ this.state.error }</Alert>
                                </td>
                            </tr>
                        ) : this.state.outagesData == null ? (
                            <tr className="loading-row">
                                <td colSpan={ 9 } className="py-4 text-center">
                                    <Loading/>
                                </td>
                            </tr>
                        ) : this.state.outagesData.length <= 0 ? (
                            <tr className="warning no-result">
                                <td colSpan={ 9 }>
                                    <i className="fa fa-warning"/>
                                    Geen resultaten gevonden
                                </td>
                            </tr>
                        ) : (
                            <AuthenticatedUserContext.Consumer>
                                { context => this.state.outagesData.map(outage => (
                                    <OutageRow
                                        key={ outage.id }
                                        outage={ outage }
                                        pageType={ this.getPageType() }
                                        currentUserId={ context.user.id }
                                        searchQuery={ this.state.outagesSearch }
                                    />
                                ))}
                            </AuthenticatedUserContext.Consumer>
                        )}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}

function OutagesSwitch(props) {
    return (
        <Switch>
            <Route path={`${props.match.path}/add/installation/:installationId`} component={OutageAddInstallation} />
            <Route path={`${props.match.path}/add/lead/:leadId`} component={OutageAddStep2} />
            <Route path={`${props.match.path}/add/lead`} component={OutageAddStep1} />
            <Route path={`${props.match.path}/map`} component={OutagesMap} />
            <Route path={`${props.match.path}/:pageType`} component={OutagesPage} />
            <Route path={`${props.match.path}/`} component={OutagesPage} />
        </Switch>
    )
}

export default React.memo(OutagesSwitch);
