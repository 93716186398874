import React from "react";
import {
    Route,
    Switch
} from "react-router-dom";
import RemindersPage from "./RemindersPage";

function RemindersSwitch(props) {
    return (
        <Switch>
            <Route path={[`${props.match.path}/all/:date`, `${props.match.path}/all`]} exact>
                <RemindersPage showAll={ true }/>
            </Route>
            <Route path={[`${props.match.path}/:date`, `${props.match.path}/`]} exact>
                <RemindersPage/>
            </Route>
        </Switch>
    )
}

export default RemindersSwitch;
