import React from "react";
import Spinner from "react-spinner-material";

function Loading() {
	return (
		<div className="d-flex w-100 h-100 justify-content-center align-content-center mt-2 mb-2">
			<Spinner radius={ 40 } color="#1f2326" stroke={ 5 } visible={ true }/>
		</div>
	);
}

export default React.memo(Loading);
