import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

class ImageRemoveModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null
        }
        this.onShow = this.onShow.bind(this);
        this.removeImage = this.removeImage.bind(this);
    }

    onShow() {
        this.setState({ loading: false });
    }

    removeImage() {
        this.setState({ loading: true });
        axios.post("/removeImage", { imageId: this.props.image.id })
            .then((response) => {
                if(response.data.valid) {
                    this.props.onImageRemoved();
                } else {
                    this.setState({ loading: false, error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loading: false, error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    render() {
        return (
            <Modal
                show={ this.props.show }
                onHide={ this.props.handleClose }
                onShow={ this.onShow }
                backdropClassName="modal-image-remove-backdrop"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">Foto verwijderen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.state.error && (
                        <Alert variant="danger">{ this.state.error }</Alert>
                    )}
                    <p className="text-danger mb-0">
                        Weet je zeker dat je deze foto wilt verwijderen?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                        Annuleer
                    </Button>
                    <Button variant="danger" onClick={ this.removeImage } disabled={ this.state.loading }>
                        { this.state.loading && (
                            <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                        )}
                        Verwijderen
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ImageRemoveModal;
