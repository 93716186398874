import React, {
    useMemo
} from "react";
import GitInfo from "react-git-info/macro";
import {
    PageContainer,
    PageContent,
    PageFooter
} from "@zandor300/backoffice-framework";

function IntranetPageContainer({ children }) {
    const commitHash = useMemo(() => {
        return GitInfo().commit.hash;
    }, []);
    const commitHashShort = useMemo(() => {
        return GitInfo().commit.shortHash;
    }, []);

    const url = useMemo(() => {
        return "https://git.zsinfo.nl/JKSmithHolding/SolarIntranet/-/tree/" + commitHash;
    }, [commitHash]);

    return (
        <PageContainer>
            <PageContent>
                { children }
            </PageContent>
            <PageFooter>
                Intranet { process.env.REACT_APP_VERSION || "0.0.0" }
                {" "}
                (<a href={ url } target="_blank" rel="noreferrer noopener">{ commitHashShort }</a>)
            </PageFooter>
        </PageContainer>
    )
}

export default React.memo(IntranetPageContainer);
