import React from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";

import Loading from "../../../components/Loading";

class LeadDetailEdit extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            success: false,

            lead: null,
            types: null,
            sources: null
        };
    }

    componentDidMount() {
        if(this.props.lead !== null) {
            this.setState({ lead: this.props.lead });
        }
        this.getTypes();
        this.getSources();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.lead !== prevProps.lead) {
            this.setState({ lead: this.props.lead });
        }
    }

    setLeadState(state) {
        this.setState((prevState) => {
            return { lead: { ...prevState.lead, ...state } }
        })
    }

    getTypes() {
        this.setState({ types: null, error: null });
        axios.get("/getLeadTypes")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ types: response.data.data });
                } else {
                    this.setState({ types: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ types: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    getSources() {
        this.setState({ sources: null, error: null });
        axios.get("/getLeadSources")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ sources: response.data.data });
                } else {
                    this.setState({ sources: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ sources: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    setLead() {
        this.setState({ error: null, success: false });
        axios.post("/setLead", {
            leadId: this.state.lead.id,

            name: this.state.lead.name,
            email: this.state.lead.email,
            phoneNumber: this.state.lead.phoneNumber,
            typeId: this.state.lead.type === null ? 0 : this.state.lead.type.id,
            sourceId: this.state.lead.source.id,
            date: this.state.lead.date.split(" ")[0],

            street: this.state.lead.street,
            houseNumber: this.state.lead.houseNumber,
            postalCode: this.state.lead.postalCode,
            city: this.state.lead.city,

            message: this.state.lead.message
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.setLead(response.data.lead);
                    this.setState({ success: true });
                } else {
                    this.setState({ error: "Er was een fout bij het opslaan van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er was een fout bij het opslaan van de data." });
            })
            .finally(() => {
                window.scroll({ top: 0, behavior: "smooth" });
            });
    }

    render() {
        const lead = this.state.lead;
        return (
            <React.Fragment>
                { this.state.success && (
                    <Alert variant="success">Wijzigingen zijn opgeslagen.</Alert>
                )}
                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}
                { lead === null || this.state.types === null || this.state.sources === null ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h4>Contact informatie</h4>
                                <div className="form-group">
                                    <label htmlFor="name">Naam klant</label>
                                    <input type="text" className="form-control" id="name" required
                                           value={lead.name} onChange={ (event) => this.setLeadState({ name: event.target.value })}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control" id="email" required
                                           value={lead.email} onChange={ (event) => this.setLeadState({ email: event.target.value })}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phoneNumber">Telefoonnummer</label>
                                    <input type="text" className="form-control" id="phoneNumber" required
                                           value={lead.phoneNumber}
                                           onChange={ (event) => {
                                               this.setLeadState({ phoneNumber: event.target.value.replace(/[a-zA-Z]*/g,"") });
                                           }}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="type">Type</label>
                                    <select className="form-control" id="type" required
                                            value={lead.type === null ? 0 : lead.type.id}
                                            onChange={ (event) => {
                                                this.setLeadState({ type: parseInt(event.target.value) === 0 ? null : this.state.types.find((type) => type.id === parseInt(event.target.value)) });
                                            }}
                                    >
                                        <option value={0}>Onbepaald</option>
                                        { this.state.types && this.state.types.map((type) => (
                                            <option key={ type.id } value={ type.id } style={{ backgroundColor: type.color, color: "white" }}>
                                                { type.name }
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="source">Bron</label>
                                    <select className="form-control" id="source" required
                                            disabled={ !lead.source.manualInput }
                                            value={lead.source.id}
                                            onChange={ (event) => {
                                                this.setLeadState({ source: this.state.sources.find((source) => source.id === parseInt(event.target.value)) })
                                            }}
                                    >
                                        { this.state.sources && this.state.sources.filter((source) => !lead.source.manualInput || source.manualInput).map((source) => (
                                            <option key={ source.id } value={ source.id } style={{ backgroundColor: source.color, color: "white" }}>
                                                { source.name }
                                            </option>
                                        ))}
                                    </select>
                                    { !lead.source.manualInput && (
                                        <span className="text-muted">Deze lead is automatisch toegevoegd. Dit is vastgesteld en kan niet veranderd worden.</span>
                                    )}
                                </div>

                                <div className="form-group mb-0">
                                    <label htmlFor="date">Datum ontvangen</label>
                                    <input type="date" className="form-control" id="date" required
                                           disabled={ !lead.source.manualInput }
                                           value={lead.date.split(" ")[0]} onChange={ (event) => this.setLeadState({ date: event.target.value })}
                                    />
                                    { !lead.source.manualInput && (
                                        <span className="text-muted">Deze lead is automatisch toegevoegd. Dit is vastgesteld en kan niet veranderd worden.</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-body">
                                <h4>Adres</h4>
                                <div className="row">
                                    <div className="col-sm-8">
                                        <div className="form-group">
                                            <label htmlFor="street">Straat</label>
                                            <input type="text" className="form-control" id="street" required
                                                   value={lead.street} onChange={ (event) => this.setLeadState({ street: event.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="houseNumber">Huisnummer + Toevoeging</label>
                                            <input type="text" className="form-control" id="houseNumber" required
                                                   value={lead.houseNumber} onChange={ (event) => this.setLeadState({ houseNumber: event.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group mb-0">
                                            <label htmlFor="postalCode">Postcode</label>
                                            <input type="text" className="form-control" id="postalCode" required
                                                   value={lead.postalCode} onChange={ (event) => this.setLeadState({ postalCode: event.target.value })}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group mb-0">
                                            <label htmlFor="city">Stad</label>
                                            <input type="text" className="form-control" id="city" required
                                                   value={lead.city} onChange={ (event) => this.setLeadState({ city: event.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-body">
                                <h4>Opmerkingen</h4>
                                <div className="form-group mb-0">
                                    <textarea
                                        className="form-control" id="message" rows={ 8 }
                                        value={lead.message}
                                        onChange={ (event) => this.setLeadState({ message: event.target.value })}
                                    />
                                </div>
                            </div>
                        </div>

                        <Button variant="primary" className="float-right" onClick={ this.setLead.bind(this) }>
                            Opslaan
                        </Button>

                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default LeadDetailEdit;
