import React from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga";
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import {
    CSSTransition
} from "react-transition-group";

import "./scss/intranet.scss";

import AuthenticatedUserContext from "./context/AuthenticatedUserContext";

import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/login/Login";
import IntranetRouter from "./IntranetRouter";
import {
    PublicHelpSwitch
} from "./pages/help/HelpSwitch";
import AnimatedLogo from "./components/AnimatedLogo";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showWhiteOverlayOverride: false,
            showInitialLoadingIndicator: false,
            authenticatedUser: { user: undefined, loginFunction: this.login.bind(this), logoutFunction: this.logout.bind(this) }
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.state.authenticatedUser.user === undefined) {
                this.setState({ showInitialLoadingIndicator: true });
            }
        }, 100);
        this.getSession();
    }

    setUser(user) {
        if(user) {
            console.log("Logged in as user " + user.name + ".");
            Sentry.setUser({
                id: user.id,
                name: user.name,
                email: user.email
            });
            ReactGA.set({ userId: user.id }, ["default"]);
        }
        this.setState((previousState) => {
            return { authenticatedUser: { ...previousState.authenticatedUser, user: user } }
        });
    }

    showWhiteOverlayOverridden(value, stateCheck = () => { return false }) {
        this.setState({ showWhiteOverlayOverride: value, showInitialLoadingIndicator: false });
        if(value) {
            setTimeout(() => {
                if(stateCheck()) {
                    this.setState({ showInitialLoadingIndicator: true });
                }
            }, 100);
        }
    }

    getErrorMessage(errorCode) {
        switch(errorCode) {
            case "INVALID_CREDENTIALS":
                return "Foutieve gebruikersnaam of wachtwoord.";
            case "ACCOUNT_BLOCKED":
                return "Te veel foutieve inlogpogingen. Account is geblokkeerd voor 15 minuten.";
            default:
                return "Er ging iets fout. Probeer het later opnieuw.";
        }
    }

    login(state, onErrorOccurred) {
        let email = state.email;
        let password = state.password;

        this.showWhiteOverlayOverridden(true, () => { return this.state.authenticatedUser.user === null });
        if(email.length === 0 || password.length === 0) {
            setTimeout(() => {
                onErrorOccurred(this.getErrorMessage("INVALID_CREDENTIALS"));
                this.showWhiteOverlayOverridden(false);
            }, 1000);
            return;
        }
        axios.post("/login", { email: email, password: password, useCookie: true, deviceType: "desktop" })
            .then((response) => {
                setTimeout(() => {
                    if(response.data.valid) {
                        this.setUser(response.data.user);
                    } else {
                        onErrorOccurred(this.getErrorMessage(response.data.error));
                    }
                }, 1000);
            })
            .catch((error) => {
                setTimeout(() => {
                    onErrorOccurred(this.getErrorMessage());
                }, 1000);
            })
            .finally(() => {
                setTimeout(() => {
                    this.showWhiteOverlayOverridden(false);
                }, 1000);
            });
    }

    getSession() {
        axios.get("/checkSession")
            .then((response) => {
                if(response.data.valid) {
                    this.setUser(response.data.user);
                } else {
                    this.setUser(null);
                }
            })
            .catch((error) => {
                this.setUser(null);
            })
    }

    logout() {
        this.showWhiteOverlayOverridden(true, () => { return this.state.authenticatedUser.user !== null });
        axios.get("/logout")
            .then((response) => {
                setTimeout(() => {
                    if(response.data.valid) {
                        this.setUser(null);
                    } else {
                        // TODO: Display pop-over error message.
                    }
                }, 500);
            })
            .catch((error) => {
                // TODO: Display pop-over error message.
            })
            .finally(() => {
                setTimeout(() => {
                    this.showWhiteOverlayOverridden(false);
                }, 500);
            });
    }

    render() {
        return (
            <React.Fragment>

                <CSSTransition in={ this.state.authenticatedUser.user === undefined || this.state.showWhiteOverlayOverride }
                               timeout={500} classNames="initial-load-background" unmountOnExit>
                    <div style={{
                        position: "absolute",
                        top: 0,
                        height: "100vh",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#F9FBFD",
                        zIndex: 1100
                    }}>
                        <CSSTransition in={ this.state.showInitialLoadingIndicator } timeout={500} classNames="initial-load-logo" appear={true} mountOnEnter>
                            <AnimatedLogo color="#FF5000" animating={ true } className="initial-load-logo-enter"
                                          style={{ height: "125px", width: "100%" }}/>
                        </CSSTransition>
                    </div>
                </CSSTransition>

                { this.state.authenticatedUser.user !== undefined && (
                    <AuthenticatedUserContext.Provider value={ this.state.authenticatedUser }>
                        <Router>
                            <Switch>

                                { this.state.authenticatedUser.user === null && (
                                    <Route path="/help" component={PublicHelpSwitch}/>
                                )}

                                <PrivateRoute authenticated={ this.state.authenticatedUser.user === null }
                                              path="/login" target="/dashboard"
                                              component={Login}
                                />

                                <PrivateRoute authenticated={ this.state.authenticatedUser.user !== null }
                                              path="/" target="/login"
                                              component={IntranetRouter}
                                />

                            </Switch>
                        </Router>
                    </AuthenticatedUserContext.Provider>
                )}

            </React.Fragment>
        );
    }
}

export default App;
