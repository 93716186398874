import React from "react";
import {
    Route,
    Switch
} from "react-router-dom";
import SalesApp from "./SalesApp";

function HelpSwitch(props) {
    return (
        <Switch>
            <Route path={`${props.match.path}/sales-app`} component={SalesApp} />
        </Switch>
    )
}

export function PublicHelpSwitch(props) {
    return (
        <div className="container">
            <div className="pt-4 pb-5">
                <HelpSwitch {...props}/>
            </div>
        </div>
    )
}

export default React.memo(HelpSwitch);
