import React, {
    useMemo,
    useState
} from "react";
import axios from "axios";

function PlanningDayCalendarAssignedUserCheckbox({ planningItem, user, onPlanningItemUpdated, loading, setLoading, onError }) {
    const [selfLoading, setSelfLoading] = useState(false);
    const checked = planningItem.assignedUsers.findIndex((userSearch) => userSearch.id === user.id ) !== -1;

    const onClick = useMemo(() => {
        return () => {
            if(loading) {
                return;
            }
            let assignedUsers = planningItem.assignedUsers ? planningItem.assignedUsers.map((user) => user.id) : [];
            const wasChecked = assignedUsers.findIndex((userId) => userId === user.id ) !== -1;

            if(wasChecked) {
                assignedUsers = assignedUsers.filter((assignedUserId) => {
                    return assignedUserId !== user.id;
                });
            } else {
                assignedUsers.push(user.id);
            }

            setLoading(true);
            setSelfLoading(true);
            axios.post("/setPlanningItem", {
                planningItemId: planningItem.id,
                date: planningItem.date,
                description: planningItem.description,
                assignedUsers: assignedUsers.join(","),
                time: planningItem.time
            })
                .then((response) => {
                    if(response.data.valid) {
                        if(onPlanningItemUpdated) {
                            onPlanningItemUpdated(response.data.planningItem);
                        }
                    } else {
                        onError("Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    onError("Er ging iets fout. Probeer het later opnieuw.");
                })
                .finally(() => {
                    setLoading(false);
                    setSelfLoading(false);
                });
        }
    }, [loading, planningItem, setLoading, onPlanningItemUpdated, onError, user.id]);

    let classNames = ["card mb-0 w-100"];
    classNames.push(loading ? "pointer-disabled" : "pointer-cursor");
    if(selfLoading) {
        classNames.push("bg-secondary border-secondary text-white");
    } else if(checked) {
        classNames.push("bg-primary border-primary text-white");
    }

    return (
        <div className={ classNames.join(" ") } onClick={ onClick }>
            <div className="card-body text-center py-3 px-0">
                { selfLoading ? (
                    <i className="far fa-spinner-third fa-spin"/>
                ) : checked ? (
                    <i className="fas fa-check"/>
                ) : (
                    <i className="fas"/>
                )}
            </div>
        </div>
    )
}

export default React.memo(PlanningDayCalendarAssignedUserCheckbox);
