import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import PlanningItemCard from "../components/PlanningItemCard";

class PlanningItemDeleteModal extends React.PureComponent {
    state = {
        error: null,
        loading: false
    }

    onShow() {
        this.setState({
            error: null
        });
    }

    deletePlanningItem() {
        const planningItemId = this.props.planningItem.id;
        this.setState({ loading: true });
        axios.post('/deletePlanningItem', {
            planningItemId: planningItemId
        })
            .then((response) => {
                if(response.data.valid) {
                    if(this.props.planningItemDeleted) {
                        this.props.planningItemDeleted();
                    }
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow.bind(this) }>
                <Modal.Header closeButton>
                    <Modal.Title>Planning item verwijderen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.state.error !== null && (
                        <Alert variant="danger">{ this.state.error }</Alert>
                    )}
                    { this.props.planningItem !== null ? (
                        <React.Fragment>
                            <p className="text-danger">
                                Weet je zeker dat je het plannings item wilt verwijderen? Dit kan niet ongedaan worden
                                gemaakt.
                            </p>
                            <PlanningItemCard planningItem={ this.props.planningItem } className="mb-0"/>
                        </React.Fragment>
                    ) : (
                        <Loading/>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                        Annuleer
                    </Button>
                    <Button variant="danger" onClick={ this.deletePlanningItem.bind(this) } disabled={ this.state.loading }>
                        { this.state.loading && (
                            <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                        )}
                        Verwijderen
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PlanningItemDeleteModal;
