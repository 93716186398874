import React from "react";
import {
    withRouter
} from "react-router-dom";

import LeadDetailOverviewCard from "./components/LeadDetailOverviewCard";
import DetailOverviewQRCode from "../../../components/detail/DetailOverviewQRCode";
import LeadDetailPrintForm from "./components/LeadDetailPrintForm";

class LeadDetailPrint extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            qrCodeLoaded: false
        }
        this.onQRCodeLoaded = this.onQRCodeLoaded.bind(this);
        this.onPrintDialogClose = this.onPrintDialogClose.bind(this);
    }

    componentDidMount() {
        this.openPrintDialogIfAble();
        window.onafterprint = this.onPrintDialogClose;
    }

    componentWillUnmount() {
        window.onafterprint = undefined;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.lead && this.props.lead) {
            this.openPrintDialogIfAble();
        }
        if(prevProps.lead && prevProps.lead !== this.props.lead) {
            this.setState({ qrCodeLoaded: false });
        }
    }

    onQRCodeLoaded() {
        this.setState({ qrCodeLoaded: true }, () => {
            this.openPrintDialogIfAble();
        });
    }

    openPrintDialogIfAble() {
        if(!this.props.lead || !this.state.qrCodeLoaded) {
            return;
        }
        this.openPrintDialog();
    }

    openPrintDialog() {
        window.print();
    }

    onPrintDialogClose() {
        const { lead } = this.props;
        if(!lead) {
            return;
        }
        this.props.history.push("/lead/" + lead.id);
    }

    render() {
        const { lead } = this.props;
        return (
            <React.Fragment>
                <DetailOverviewQRCode
                    content={ lead ? `lead=${lead.id}` : null }
                    caption="Scan deze code in de Sales app om deze lead direct te openen op je telefoon."
                    onQRCodeLoaded={ this.onQRCodeLoaded }
                />
                <LeadDetailOverviewCard
                    lead={ lead }
                />
                <div style={{ pageBreakBefore: "always" }}/>
                <div className="mt-3">
                    <LeadDetailPrintForm/>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(LeadDetailPrint);
