function isValidBSN(bsn) {
    if(typeof bsn !== "string") {
        bsn = bsn.toString();
    }
    bsn = bsn.trim();
    if(bsn.length !== 9) {
        return false;
    }

    const numbers = bsn.split("");

    let sum = 0;
    for(let i = 9; i > 0; i--) {
        if(i === 1) {
            sum -= numbers[9 - i] * i;
        } else {
            sum += numbers[9 - i] * i;
        }
    }

    return sum % 11 === 0;
}

export default isValidBSN;
