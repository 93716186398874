import React from "react";
import axios from "axios";
import {
    Alert,
    Table
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import TagPill from "../../../components/tagPill";
import NumberFormatter from "../../../components/formatters/NumberFormatter";

class ProductDetailRestockOrderSuppliers extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            supplierAmounts: null,
            totalAmount: null,
            error: null
        }
    }

    componentDidMount() {
        this.getSupplierAmounts();
    }

    getSupplierAmounts() {
        axios.post("/getRestockOrdersForProductPerSupplier", { productId: this.props.productId })
            .then((response) => {
                if(response.data.valid) {
                    const supplierAmounts = response.data.data;
                    const totalAmount = supplierAmounts.reduce((prevSum, supplierAmount) => {
                        const { orderAmount } = supplierAmount;
                        return prevSum + orderAmount;
                    }, 0);
                    this.setState({ supplierAmounts, totalAmount });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    render() {
        return (
            <React.Fragment>
                <Table hover>
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <th className="text-center">
                                #
                            </th>
                            <th>
                                Leverancier
                            </th>
                            <th className="text-right">
                                Totaal besteld
                            </th>
                            <th className="d-none d-lg-block d-xl-none" style={{ minHeight: "58px" }}>
                                <div style={{ minWidth: "200px" }}/>
                            </th>
                            <th className="d-none d-xl-block" style={{ minHeight: "58px" }}>
                                <div style={{ minWidth: "500px" }}/>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.error ? (
                            <tr>
                                <td colSpan={ 4 }>
                                    <Alert variant="danger">{ this.state.error }</Alert>
                                </td>
                            </tr>
                        ) : !this.state.supplierAmounts ? (
                            <tr>
                                <td colSpan={ 4 }>
                                    <Loading/>
                                </td>
                            </tr>
                        ) : this.state.supplierAmounts.length === 0 ? (
                            <tr>
                                <td colSpan={ 4 } className="text-center">
                                    <h1><i className="fas fa-inventory"/></h1>
                                    <h3>Geen bestellingen</h3>
                                    <p>Er zijn geen bestellingen gevonden voor het huidige product.</p>
                                </td>
                            </tr>
                        ) : this.state.supplierAmounts.map((supplierAmount) => {
                            const { supplier, orderAmount } = supplierAmount;
                            return (
                                <tr key={ supplier.id }>
                                    <th className="text-center">
                                        { supplier.id }
                                    </th>
                                    <td>
                                        <TagPill color={ supplier.color }>
                                            { supplier.name }
                                        </TagPill>
                                    </td>
                                    <td className="text-right">
                                        <NumberFormatter number={ orderAmount }/>
                                    </td>
                                    <td className="d-none d-lg-block"/>
                                </tr>
                            );
                        })}
                        { this.state.totalAmount && (
                            <tr>
                                <th className="text-center"/>
                                <td>
                                    <b>Totaal</b>
                                </td>
                                <td className="text-right">
                                    <b>
                                        <NumberFormatter number={ this.state.totalAmount }/>
                                    </b>
                                </td>
                                <td className="d-none d-lg-block"/>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

}

export default ProductDetailRestockOrderSuppliers;
