import React from "react";
import axios from "axios";
import {
    Alert,
    Table
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import InstallationRow from "../../installations/InstallationRow";

class ProductDetailInstallationsReserved extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            installations: null,
            error: null
        }
    }

    componentDidMount() {
        this.getInstallations();
    }

    getInstallations() {
        axios.post("/getInstallationsWithReservedProduct", { productId: this.props.productId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ installations: response.data.data });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    render() {
        const { installations, error } = this.state;
        return (
            <React.Fragment>
                <Table hover striped size="sm">
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <th>
                                #
                            </th>
                            <th className="text-center">
                                <i className="fas fa-exclamation-triangle"/>
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Naam
                            </th>
                            <th>
                                Adres
                            </th>
                            <th>
                                BTW Terugvraag
                            </th>
                            <th>
                                Aangemaakt door
                            </th>
                            <th>
                                Datum
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        { error ? (
                            <tr>
                                <td colSpan={ 8 }>
                                    <Alert variant="danger">{ error }</Alert>
                                </td>
                            </tr>
                        ) : !installations ? (
                            <tr>
                                <td colSpan={ 8 }>
                                    <Loading/>
                                </td>
                            </tr>
                        ) : installations.length === 0 ? (
                            <tr>
                                <td colSpan={ 8 } className="text-center">
                                    <h1><i className="fas fa-inventory"/></h1>
                                    <h3>Niet gereserveerd</h3>
                                    <p>Dit product is momenteel niet gereserveerd in een installatie.</p>
                                </td>
                            </tr>
                        ) : installations.map((installation) => (
                            <InstallationRow
                                key={ installation.id }
                                installation={ installation }
                                to={ "/installation/" + installation.id }
                            />
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

}

export default ProductDetailInstallationsReserved;
