import React from "react";
import {
    Link
} from "react-router-dom";

import TagPill from "../../components/tagPill";
import DateFormatter from "../../components/formatters/DateFormatter";
import CurrentUserHighlight from "../../components/CurrentUserHighlight";

class RestockOrderRow extends React.PureComponent {
    searchActive() {
        const restockOrder = this.props.restockOrder;
        if(this.props.searchQuery) {
            const splitSearchQuery = this.props.searchQuery.split(" ");
            // Loop through every search query item
            for(let searchQueryIndex = 0; searchQueryIndex < splitSearchQuery.length; searchQueryIndex++) {
                const searchQuery = (splitSearchQuery[searchQueryIndex] || "").toLowerCase();
                if(searchQuery.indexOf("=") !== -1) {
                    let [searchKey, searchValue] = searchQuery.split("=");
                    switch(searchKey) {
                        default:
                        case "id":
                            if(parseInt(restockOrder.id) !== parseInt(searchValue))
                                return false;
                            break;
                        case "auteur":
                        case "author":
                        case "besteller":
                        case "orderer":
                            if((restockOrder.author ? restockOrder.author.name.replace(/\s/g,"") : "automatisch").toLowerCase().indexOf(searchValue) === -1
                                && (restockOrder.author ? restockOrder.author.name.replace(/\s/g,"") : "automatic").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "supplier":
                        case "leverancier":
                            if((restockOrder.supplier ? restockOrder.supplier.name.replace(/\s/g,"") : "geen").toLowerCase().indexOf(searchValue) === -1
                                && (restockOrder.supplier ? restockOrder.supplier.name.replace(/\s/g,"") : "none").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "supplierordernumber":
                        case "ordernummer":
                            if((restockOrder.supplierOrderNumber ? restockOrder.supplierOrderNumber.replace(/\s/g,"") : "leeg").toLowerCase().indexOf(searchValue) === -1
                                && (restockOrder.supplierOrderNumber ? restockOrder.supplierOrderNumber.replace(/\s/g,"") : "empty").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                    }
                }
                else {
                    // Id
                    if(parseInt(restockOrder.id) === parseInt(searchQuery))
                        continue;

                    // Author
                    if((restockOrder.author ? restockOrder.author.name.replace(/\s/g,"") : "automatisch").toLowerCase().indexOf(searchQuery) !== -1
                        || (restockOrder.author ? restockOrder.author.name.replace(/\s/g,"") : "automatic").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Supplier
                    if((restockOrder.supplier ? restockOrder.supplier.name.replace(/\s/g,"") : "geen").toLowerCase().indexOf(searchQuery) !== -1
                        || (restockOrder.supplier ? restockOrder.supplier.name.replace(/\s/g,"") : "none").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Supplier orderNumber
                    if((restockOrder.supplierOrderNumber ? restockOrder.supplierOrderNumber.replace(/\s/g,"") : "leeg").toLowerCase().indexOf(searchQuery) !== -1
                        || (restockOrder.supplierOrderNumber ? restockOrder.supplierOrderNumber.replace(/\s/g,"") : "empty").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Supplier orderNumber
                    if((restockOrder.description ? restockOrder.description.replace(/\s/g,"") : "leeg").toLowerCase().indexOf(searchQuery) !== -1
                        || (restockOrder.description ? restockOrder.description.replace(/\s/g,"") : "empty").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Delivered
                    if((restockOrder.delivered ? "delivered" : "expected").toLowerCase().indexOf(searchQuery) !== -1
                        || (restockOrder.delivered ? "afgeleverd" : "verwacht").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    return false;
                }
            }
        }
        return true;
    }

    render() {
        if(!this.searchActive())
            return null;

        const {
            restockOrder,
            pageType
        } = this.props;

        return (
            <tr className={"table-clickable-row" + (restockOrder.warnings && restockOrder.warnings.length > 0 ? " table-warning" : "")}>
                <th scope="row" className="align-middle text-center">
                    <Link to={ this.props.to }>
                        { restockOrder.id }
                    </Link>
                </th>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        { restockOrder.delivered ? (
                            <TagPill variant="success">
                                Geleverd
                            </TagPill>
                        ) : (
                            <TagPill variant="danger">
                                In afwachting
                            </TagPill>
                        )}
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        <TagPill color={ restockOrder.supplier.color }>
                            { restockOrder.supplier.name }
                        </TagPill>
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        { restockOrder.supplierOrderNumber.trim().length === 0 ? (
                            <i className="text-muted">Leeg</i>
                        ) : (
                            restockOrder.supplierOrderNumber
                        )}
                    </Link>
                </td>
                <td className="align-middle truncate" style={{ maxWidth: "360px" }}>
                    <Link to={ this.props.to }>
                        { restockOrder.description.trim().length === 0 ? (
                            <i className="text-muted">Leeg</i>
                        ) : (
                            restockOrder.description
                        )}
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        <DateFormatter
                            date={ restockOrder.date }
                            format={ "DD-MM-YYYY HH:MM" }
                        />
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        <DateFormatter
                            date={ pageType === "delivered" ? restockOrder.deliveryDate : restockOrder.expectedDeliveryDate }
                            format={ "DD-MM-YYYY" }
                        />
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        <CurrentUserHighlight user={ restockOrder.author }/>
                    </Link>
                </td>

            </tr>
        )
    }
}

export default RestockOrderRow;
