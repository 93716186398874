import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Modal,
    Form
} from "react-bootstrap";

import {
    withUsersContext
} from "../../../context/UsersContext";
import DateFormatter from "../../../components/formatters/DateFormatter";

class AbsenceUpdateModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modalState: 0,
            modalError: null,

            id: -1,
            absenceUserId: 0,
            startDate: "",
            endDate: "",
            description: "",
            type: 0,
        };
        this.onShow = this.onShow.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if(props.show && state.modalState === 0) {
            return {
                modalState: 1,
                modalError: null,
                ...props.absenceData,
                type: props.absenceData.type.id,
                absenceUserId: props.absenceData.user.id,
            }
        } else if(!props.show && state.modalState > 0) {
            return {
                modalState: 0
            }
        }

        return null;
    }

    onShow() {
        this.props.usersContext.refreshUsers();
    }

    CanCreateAbsence() {
        return this.state.absenceUserId > 0
            && this.state.type > 0
            && moment(this.state.startDate).isValid()
            && moment(this.state.endDate).isValid();
    }

    RemoveAbsenceItemModal() {
        this.setState({modalState: 4});
    }
    HideAbsenceRemoveModal() {
        this.setState({modalState: 1});
    }

    RemoveAbsenceItem() {
        this.setState({modalState: 2});
        // Make a request for a user with a given ID
        axios.post('/deleteAbsenceItem', { id: this.state.id })
            .then((response) => {
                if(response.data.valid) {
                    // Update current week state
                    this.props.updateCurrentWeek();
                    // Remove modal
                    this.props.onHide();
                } else {
                    // Set modal to error mode.
                    this.setState({modalState: 3, modalError: response.data.error});
                }
            })
            .catch((error) => {
                this.setState({modalState: 3, modalError: "Er was een probleem tijdens het versturen van de informatie, probeer het later opnieuw!"});
                // handle error
                console.error(error);
            });
    }

    UpdateAbsenceItem() {
        this.setState({modalState: 2});
        // Make a request for a user with a given ID
        axios.post('/setAbsenceItem', {
            id: this.state.id,
            userId: this.state.absenceUserId,
            typeId: this.state.type,
            description: this.state.description,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        })
            .then((response) => {
                if(response.data.valid) {
                    // Update current week state
                    this.props.updateCurrentWeek();
                    // Remove modal
                    this.props.onHide();
                } else {
                    // Set modal to error mode.
                    this.setState({modalState: 3, modalError: response.data.error});
                }
            })
            .catch((error) => {
                this.setState({modalState: 3, modalError: "Er was een probleem tijdens het versturen van de informatie, probeer het later opnieuw!"});
                // handle error
                console.error(error);
            });
    }

    render() {
        const {
            absenceData
        } = this.props;
        return (
            <React.Fragment>
                <Modal
                    show={this.state.modalState > 0 && this.state.modalState !== 4}
                    size="md"
                    aria-labelledby="contained-modal-title"
                    centered
                    onHide={this.props.onHide}
                    onShow={this.onShow}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title">
                            Absentie updaten
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.modalError != null && (
                            <div className="alert alert-danger text-left mb-3 mt-0" role="alert">
                                Er was een probleem tijdens het updaten van de absentie.<br/>
                                Error: {this.state.modalError}
                            </div>
                        )}

                        { absenceData && (
                            <div className="card mb-3">
                                <div className="card-body p-3">
                                    <p className="card-text">
                                        Oorspronkelijk aangemaakt door { absenceData.author ? absenceData.author.name : "onbekend" }
                                        {" "}
                                        op <DateFormatter date={ absenceData.createdDate }/>.
                                    </p>
                                </div>
                            </div>
                        )}

                        <div className="form-group">
                            <label htmlFor="absenceEmployee">Werknemer</label>
                            <Form.Control as="select" id="absenceEmployee" className="custom-select" name="absenceemployee"
                                          disabled={this.state.modalState === 2}
                                          onChange={(e) => this.setState({absenceUserId: e.target.value})}
                                          value={this.state.absenceUserId}
                            >
                                <option value={-1}>
                                    Werknemer
                                </option>
                                {this.props.usersContext.users && this.props.usersContext.users.map((user) => {
                                    if('enabled' in user && user.enabled) {
                                        return (
                                            <option key={user.id} value={user.id}>
                                                {user.name}
                                            </option>
                                        );
                                    }
                                    return null;
                                })}
                            </Form.Control>
                        </div>

                        <div className="form-group">
                            <label htmlFor="startdate">Datums</label>
                            <div className="row">
                                <div className="col-6">
                                    <input type="date" className="form-control" id="startdate" name="start-date" placeholder="Datum"
                                           title="Start Datum"
                                           disabled={this.state.modalState === 2} required
                                           value={this.state.startDate} onChange={(e) => this.setState({startDate: e.target.value})}
                                    />
                                </div>
                                <div className="col-6">
                                    <input type="date" className="form-control" id="enddate" name="end-date" placeholder="Datum"
                                           title="Eind Datum"
                                           disabled={this.state.modalState === 2} required
                                           value={this.state.endDate} onChange={(e) => this.setState({endDate: e.target.value})}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="type">Type</label>
                            <Form.Control as="select" id="type" className="custom-select" name="type"
                                          disabled={this.state.modalState === 2}
                                          value={this.state.type} onChange={(e) => this.setState({type: e.target.value})}
                            >
                                {this.props.absenceTypes && this.props.absenceTypes.map((type) => {
                                    return <option key={type.id}
                                        style={{backgroundColor: type.color, color: "white"}}
                                        value={type.id}
                                    >
                                        {type.name}
                                    </option>
                                })}
                            </Form.Control>
                        </div>

                        <div className="form-group mb-0">
                            <label htmlFor="description">Beschrijving <small className="text-muted">(optioneel)</small></label>
                            <textarea className="form-control" id="description"
                                      disabled={this.state.modalState === 2}
                                      name="description" style={{height: 100}}
                                      value={this.state.description} onChange={(e) => this.setState({description: e.target.value})}
                            />
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-secondary" onClick={this.props.onHide}>Sluiten</button>
                        <button className="btn btn-danger" onClick={this.RemoveAbsenceItemModal.bind(this)}>Verwijderen</button>
                        <button className="btn btn-primary" onClick={this.UpdateAbsenceItem.bind(this)}
                                disabled={this.state.modalState === 2 || !this.CanCreateAbsence()}>
                            Updaten
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.modalState === 4}
                    size="md"
                    aria-labelledby="contained-modal-title"
                    centered
                    onHide={this.HideAbsenceRemoveModal.bind(this)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title">
                            Absentie verwijderen
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Weet je zeker dat je deze absentie wilt verwijderen?
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-secondary" onClick={this.HideAbsenceRemoveModal.bind(this)}>Cancel</button>
                        <button className="btn btn-danger" onClick={this.RemoveAbsenceItem.bind(this)}>Verwijderen</button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withUsersContext(AbsenceUpdateModal);
