function decimalSeparatorLocale() {
    const n = 1.1;
    return n.toLocaleString().substring(1, 2);
}

function inputDecimalParser(event) {
    const decimalSeparator = decimalSeparatorLocale();

    let rawValue = event.target.value;
    if(rawValue.length === 0) {
        return rawValue;
    }

    rawValue = rawValue.replace(decimalSeparator, ".");

    return parseFloat(rawValue);
}

export default inputDecimalParser;
