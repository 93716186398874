import React from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import RestockOrderProductCard from "./components/RestockOrderProductCard";
import RestockOrderDeliveredProductCard from "./components/RestockOrderDeliveredProductCard";
import RestockOrderDetailAddProductModal from "./modal/RestockOrderDetailAddProductModal";
import RestockOrderDetailRemoveProductModal from "./modal/RestockOrderDetailRemoveProductModal";
import RestockOrderDetailDeliverProductModal from "./modal/RestockOrderDetailDeliverProductModal";
import RestockOrderDetailUndoDeliveredProductModal from "./modal/RestockOrderDetailUndoDeliveredProductModal";
import RestockOrderDetailDeliverBulkProductModal from "./modal/RestockOrderDetailDeliverBulkProductModal";

class RestockOrderDetailProducts extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            expectedProducts: null,
            deliveredProducts: null,
            error: null,

            showAddProductsModal: false,

            showRemoveProductsModal: false,
            removeProductModalProduct: null,

            showDeliverProductsModal: false,
            deliverProductModalProduct: null,

            showDeliverBulkProductsModal: false,

            showUndoDeliveredProductsModal: false,
            undoDeliveredProductModalProduct: null
        };
        this.setProductsState = this.setProductsState.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.showAddProductModal = this.showAddProductModal.bind(this);
    }

    componentDidMount() {
        this.getProducts();
        document.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);
    }

    onKeyDown(event) {
        if(event.keyCode === 78) { // N key
            if(this.showAddProductModal()) {
                event.preventDefault();
            }
        }
    }

    showAddProductModal() {
        if(this.state.error) {
            return false;
        }
        if(!this.state.expectedProducts || !this.state.deliveredProducts) {
            return false;
        }
        if(this.state.showAddProductsModal) {
            return false;
        }
        this.setState({ showAddProductsModal: true });
        return true;
    }

    getProducts() {
        this.setState({ error: null });
        axios.post("/getRestockOrderProducts", { restockOrderId: this.props.restockOrderId })
            .then((response) => {
                if(response.data.valid) {
                    this.setProductsState(response.data.expected, response.data.delivered);
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    setProductsState(expected, delivered) {
        this.setState({
            expectedProducts: expected
        });

        let groupedProducts = {};
        for(const deliveredProduct of delivered) {
            if(!(deliveredProduct.id in groupedProducts)) {
                groupedProducts[deliveredProduct.id] = [];
            }
            groupedProducts[deliveredProduct.id].push(deliveredProduct);
        }
        this.setState({
            deliveredProducts: groupedProducts
        });
    }

    onChange(expected, delivered) {
        this.setProductsState(expected, delivered);
        this.props.onChange();
    }

    render() {
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            );
        }
        if(this.state.expectedProducts === null || this.state.deliveredProducts === null) {
            return (
                <React.Fragment>
                    {[...Array(20)].map((value, index) => (
                        <div className="mb-3" key={ index }>
                            <Skeleton height={ 80 }/>
                        </div>
                    ))}
                </React.Fragment>
            )
        }

        const deliveredProducts = Object.values(this.state.deliveredProducts);

        return (
            <React.Fragment>
                <RestockOrderDetailAddProductModal
                    show={ this.state.showAddProductsModal }
                    existingProducts={ this.state.expectedProducts }
                    handleClose={ () => this.setState({ showAddProductsModal: false }) }
                    onSave={ this.onChange }
                    restockOrderId={ this.props.restockOrderId }
                />
                <RestockOrderDetailRemoveProductModal
                    show={ this.state.showRemoveProductsModal }
                    product={ this.state.removeProductModalProduct }
                    restockOrderId={ this.props.restockOrderId }
                    handleClose={ () => this.setState({ showRemoveProductsModal: false }) }
                    onRemove={ this.onChange }
                />
                <RestockOrderDetailDeliverProductModal
                    show={ this.state.showDeliverProductsModal }
                    product={ this.state.deliverProductModalProduct }
                    restockOrderId={ this.props.restockOrderId }
                    handleClose={ () => this.setState({ showDeliverProductsModal: false }) }
                    onDelivered={ this.onChange }
                />
                <RestockOrderDetailDeliverBulkProductModal
                    show={ this.state.showDeliverBulkProductsModal }
                    restockOrderId={ this.props.restockOrderId }
                    handleClose={ () => this.setState({ showDeliverBulkProductsModal: false }) }
                    onDelivered={ this.onChange }
                />
                <RestockOrderDetailUndoDeliveredProductModal
                    show={ this.state.showUndoDeliveredProductsModal }
                    product={ this.state.undoDeliveredProductModalProduct }
                    handleClose={ () => this.setState({ showUndoDeliveredProductsModal: false }) }
                    onUndo={ this.onChange }
                />

                { this.state.expectedProducts.length === 0 && Object.keys(this.state.deliveredProducts).length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-warehouse"/></h1>
                        <h3>Geen producten</h3>
                        <p>Er is nog geen producten toegevoegd aan deze bestelling.</p>
                        <p className="d-none d-lg-block">
                            <b>Tip!</b> Gebruik
                            <kbd className="mx-2">
                                N
                            </kbd>
                            om snel een product toe te voegen.
                        </p>
                        <Button
                            variant="primary"
                            className="mb-3"
                            size="sm"
                            onClick={ this.showAddProductModal }
                        >
                            <i className="fas fa-plus mr-2"/>
                            Product toevoegen
                        </Button>
                    </div>
                ) : (
                    <React.Fragment>
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={ this.showAddProductModal }
                        >
                            <i className="fas fa-plus mr-2"/>
                            Product toevoegen
                        </Button>
                        { this.state.expectedProducts.length > 0 && (
                            <Button
                                variant="secondary"
                                size="sm"
                                className="ml-2"
                                onClick={ () => this.setState({ showDeliverBulkProductsModal: true }) }
                            >
                                <i className="fas fa-check mr-2"/>
                                Alles afgeleverd markeren
                            </Button>
                        )}
                        { this.state.expectedProducts.length > 0 && (
                            <h4 className="mt-3">Verwachte producten</h4>
                        )}
                        { this.state.expectedProducts.map((product) => (
                            <RestockOrderProductCard
                                key={ product.id }
                                product={ product }
                                restockOrderId={ this.props.restockOrderId }
                                onSave={ this.onChange }
                                onRemove={ () => this.setState({ showRemoveProductsModal: true, removeProductModalProduct: product }) }
                                openDeliverProductModal={ () => this.setState({ showDeliverProductsModal: true, deliverProductModalProduct: product }) }
                            />
                        ))}
                        { deliveredProducts.length > 0 && (
                            <h4 className="mt-3">Afgeleverde producten</h4>
                        )}
                        { deliveredProducts.map((products) => (
                            <RestockOrderDeliveredProductCard
                                key={ products[0].id }
                                product={ products[0] }
                                products={ products }
                                onUndo={ (takenProduct) => this.setState({ showUndoDeliveredProductsModal: true, undoDeliveredProductModalProduct: takenProduct }) }
                            />
                        ))}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }


}

export default RestockOrderDetailProducts;
