import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import InstallationForm from "./components/InstallationForm";

class InstallationDetailEdit extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            success: false,
            saving: false,

            installation: null
        };
        this.setInstallationState = this.setInstallationState.bind(this);
        this.setInstallation = this.setInstallation.bind(this);
    }

    componentDidMount() {
        if(this.props.installation !== null) {
            this.setState({ installation: this.props.installation });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.installation !== prevProps.installation) {
            this.setState({ installation: this.props.installation });
        }
    }

    setInstallationState(state) {
        this.setState((prevState) => {
            return { installation: { ...prevState.installation, ...state } }
        })
    }

    setInstallation() {
        this.setState({ error: null, success: false, saving: true });
        axios.post("/setInstallation", {
            installationId: this.state.installation.id,

            useLeadAddress: this.state.installation.useLeadAddress ? 1 : 0,
            street: this.state.installation.street,
            houseNumber: this.state.installation.houseNumber,
            postalCode: this.state.installation.postalCode,
            city: this.state.installation.city,

            hasVATRefund: this.state.installation.hasVATRefund ? 1 : 0,

            description: this.state.installation.description
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.setInstallation(response.data.installation);
                    this.setState({ success: true });
                } else {
                    this.setState({ error: "Er was een fout bij het opslaan van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er was een fout bij het opslaan van de data." });
            })
            .finally(() => {
                this.setState({ saving: false });
                window.scroll({ top: 0, behavior: "smooth" });
            });
    }

    render() {
        const { saving, installation } = this.state;
        return (
            <React.Fragment>
                { this.state.success && (
                    <Alert variant="success">Wijzigingen zijn opgeslagen.</Alert>
                )}
                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}
                { installation === null ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        <InstallationForm
                            fields={ installation }
                            setField={ this.setInstallationState }
                            lead={ installation.lead }
                            saving={ saving }
                        />
                        <Button variant="primary" className="float-right" onClick={ this.setInstallation } disabled={ saving }>
                            { saving && (
                                <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                            )}
                            Opslaan
                        </Button>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default InstallationDetailEdit;
