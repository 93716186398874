import React, {
    useMemo
} from "react";

function Copyright() {
    const year = useMemo(() => {
        return new Date().getFullYear();
    }, []);
    return (
        <React.Fragment>
            Copyright &copy; 2019 - { year } Smith Solar B.V. All rights reserved.
        </React.Fragment>
    )
}

export default React.memo(Copyright);
