import axios from "axios";

import DetailSetStatusModal from "../../../../components/detail/modal/DetailSetStatusModal";

class VATRefundDetailSetStatusModal extends DetailSetStatusModal {
    getInitialStatus() {
        return this.props.vatRefund.status.id;
    }

    shouldEnterReason() {
        if(!this.state.statuses) {
            return false;
        }
        const selectedStatus = this.state.statuses.find((status) => {
            return status.id === parseInt(this.state.selectedStatus);
        });
        return !this.props.vatRefund.status.expired && selectedStatus.expired;
    }

    getStatuses() {
        this.setState({ statuses: null, error: null });
        axios.get("/getVATRefundStatuses")
            .then((response) => {
                if(response.data.valid) {
                    let statuses = response.data.data;
                    statuses.sort((status1, status2) => {
                        if(status1.priority > status2.priority) return -1;
                        if(status1.priority < status2.priority) return 1;
                        return 0;
                    });
                    this.setState({ statuses });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    setStatus(onSuccess, onError) {
        axios.post("/setVATRefundStatus", { vatRefundId: this.props.vatRefund.id, statusId: this.state.selectedStatus })
            .then((response) => {
                if(response.data.valid) {
                    onSuccess(() => {
                        this.props.handleStatusChanged(response.data.status);
                    });
                } else {
                    if(response.data.error === "PRODUCTS_NOT_AVAILABLE") {
                        onError("Er is niet genoeg voorraad beschikbaar om de gereserveerde producten af te nemen!");
                    } else {
                        onError("Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")");
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                onError("Er ging iets fout. Probeer het later opnieuw.");
            });
    }

    addReasonComment(onSuccess, onError) {
        const message = "## Reden voor geannuleerd\n" + this.state.reason;
        axios.post("/addVATRefundComment", { vatRefundId: this.props.vatRefund.id, message })
            .then((response) => {
                if(response.data.valid) {
                    onSuccess(() => {
                        this.props.handleCommentAdded();
                    });
                } else {
                    onError("Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                onError("Er ging iets fout. Probeer het later opnieuw.");
            });
    }

}

export default VATRefundDetailSetStatusModal;
