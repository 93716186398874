import React from "react";

class AnimatedLogo extends React.PureComponent {
    static pushNetworkActivity() {
        if(AnimatedLogo.instance) {
            AnimatedLogo.instance.pushNetworkActivity();
        }
    }
    static popNetworkActivity() {
        if(AnimatedLogo.instance) {
            AnimatedLogo.instance.popNetworkActivity();
        }
    }

    state = {
        animating: false
    };

    constructor(props) {
        super(props);
        if(props.activityIndicator) {
            AnimatedLogo.instance = this;
        }
    }

    componentDidMount() {
        this.networkActivities = 0;
        this.timer = null;
    }

    componentWillUnmount() {
        if(AnimatedLogo.instance === this) {
            AnimatedLogo.instance = null;
        }
    }

    pushNetworkActivity() {
        this.networkActivities++;
        console.log("[AnimatedLogo] Current network activities ongoing: " + this.networkActivities);
        this.shouldAnimate = true;
        if(!this.state.animating) {
            this.setState({ animating: true });
            if(this.timer === null) {
                this.timer = setInterval(() => {
                    if(this.shouldAnimate !== this.state.animating) {
                        this.setState({ animating: this.shouldAnimate });
                    }
                    if(!this.shouldAnimate) {
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            }
        }
    }

    popNetworkActivity() {
        if(this.networkActivities <= 0) {
            console.error("Tried popping network activity while there were none. (AnimatedLogo)");
            return;
        }
        this.networkActivities--;
        console.log("[AnimatedLogo] Current network activities ongoing: " + this.networkActivities);
        if(this.networkActivities === 0) {
            this.shouldAnimate = false;
        }
    }

    render() {
        return (
            <svg
                className={
                    "smithsolar-logo"
                    + ((this.props.animating || this.state.animating) ? " animate" : "")
                    + (this.props.className ? " " + this.props.className : "")
                }
                version="1.1" id="animated-logo"
                xmlns="http://www.w3.org/2000/svg"
                x="0px" y="0px"
                viewBox="0 0 680 1006"
                style={ this.props.style }>
                <path className="sidebar-logo-path top"
                      d="M0.2,0v307.1h397.9C330.1,144.7,180.3,25.2,0.2,0"
                      style={{ fill: this.props.color ? this.props.color : "white" }}/>
                <path className="sidebar-logo-path top-slat"
                      d="M524.5,335.1H54.9v164.5h498C552.6,443.6,543,388,524.5,335.1"
                      style={{ fill: this.props.color ? this.props.color : "white" }}/>
                <path className="sidebar-logo-path bottom-slat"
                      d="M176.6,693.8h466.6c21.5-52.9,33.9-109.1,36.6-166.2H176.6L176.6,693.8z"
                      style={{ fill: this.props.color ? this.props.color : "white" }}/>
                <path className="sidebar-logo-path bottom"
                      d="M0.2,1006c171.5-24,315.7-133.5,387.6-284.3H0.2L0.2,1006z"
                      style={{ fill: this.props.color ? this.props.color : "white" }}/>
            </svg>
        );
    }
}

export default AnimatedLogo;
