import axios from "axios";

import DetailSetAssignedUserModal from "../../../../components/detail/modal/DetailSetAssignedUserModal";

class OutageDetailSetAssignedUserModal extends DetailSetAssignedUserModal {
    getInitialAssignedUser() {
        return this.props.outage.assignedUser ? this.props.outage.assignedUser.id : null;
    }

    setAssignedUser(assignedUserId) {
        this.setState({ loading: true, errorSave: null });
        axios.post("/setOutageAssignedUser", { userId: assignedUserId, outageId: this.props.outage.id })
            .then((response) => {
                if(response.data.valid) {
                    this.props.handleAssignedUserChanged(response.data.user);
                } else {
                    this.setState({ loading: false, errorSave: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loading: false, errorSave: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }
}

export default OutageDetailSetAssignedUserModal;
