import React from "react";
import axios from "axios";
import moment from "moment";

const UsersContext = React.createContext(null);

export class UsersManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: null,
            error: null,

            refreshUsers: this.refreshUsers.bind(this)
        };
        this.previousUserRequestTime = null;
    }

    componentDidMount() {
        this.getUsers();
    }

    refreshUsers() {
        if(this.previousUserRequestTime && this.previousUserRequestTime.diff(moment(), "seconds") > 3600) {
            this.getUsers();
        }
    }

    getUsers() {
        this.previousUserRequestTime = moment();
        axios.get("/getUsers")
            .then((response) => {
                if(response.data.valid) {
                    let users = response.data.data.filter((user) => {
                        return user.enabled;
                    })
                    users.sort((user1, user2) => {
                        if(user1.name < user2.name) return -1;
                        if(user1.name > user2.name) return 1;
                        return 0;
                    });
                    this.setState({ users });
                } else {
                    this.setState({ users: null, error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ users: null, error: "Er ging iets fout. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    render() {
        return (
            <UsersContext.Provider value={ this.state }>
                { this.props.children }
            </UsersContext.Provider>
        )
    }
}

export function withUsersContext(Component) {
    return function contextComponent(props) {
        return (
            <UsersContext.Consumer>
                {context => <Component {...props} usersContext={context} />}
            </UsersContext.Consumer>
        )
    }
}

export default UsersContext;
