import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import * as Sentry from "@sentry/react";

import Loading from "../Loading";
import Comment from "./Comment";

class CommentDeleteModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false
        }
    }

    onShow() {
        this.setState({ error: null });
    }

    getApiEndpoint() {
        const comment = this.props.comment;
        if(comment.leadId) {
            return "/deleteLeadComment";
        } else if(comment.outageId) {
            return "/deleteOutageComment";
        } else if(comment.installationId) {
            return "/deleteInstallationComment";
        } else if(comment.vatRefundId) {
            return "/deleteVATRefundComment";
        } else {
            Sentry.captureMessage("CommentDeleteModal.getApiEndpoint: Unimplemented comment parent!");
        }
    }

    getApiParameters() {
        const comment = this.props.comment;
        if(comment.leadId) {
            return { leadCommentId: comment.id };
        } else if(comment.outageId) {
            return { outageCommentId: comment.id };
        } else if(comment.installationId) {
            return { installationCommentId: comment.id };
        } else if(comment.vatRefundId) {
            return { vatRefundCommentId: comment.id };
        } else {
            Sentry.captureMessage("CommentDeleteModal.getApiParameters: Unimplemented comment parent!");
        }
    }

    deleteComment() {
        const url = this.getApiEndpoint();
        const parameters = this.getApiParameters();
        this.setState({ loading: true });
        axios.post(url, parameters)
            .then((response) => {
                if(response.data.valid) {
                    if(this.props.commentDeleted) {
                        this.props.commentDeleted();
                    }
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <React.Fragment>
                <Modal size="lg" show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow.bind(this) }>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-danger">Notitie verwijderen</Modal.Title>
                    </Modal.Header>
                    <form className="mb-0">
                        <Modal.Body>
                            { this.state.error !== null && (
                                <Alert variant="danger">{ this.state.error }</Alert>
                            )}
                            { this.props.comment !== null ? (
                                <React.Fragment>
                                    <p className="text-danger">
                                        Weet je zeker dat je deze notitie wilt verwijderen? Dit kan niet ongedaan worden
                                        gemaakt.
                                    </p>
                                    <Comment comment={ this.props.comment } noButtons={ true } className="mb-0"/>
                                </React.Fragment>
                            ) : (
                                <Loading/>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                                Annuleer
                            </Button>
                            <Button variant="danger" onClick={ this.deleteComment.bind(this) } disabled={ this.state.loading }>
                                { this.state.loading && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Verwijderen
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default CommentDeleteModal;
