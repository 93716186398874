import React from "react";
import moment from "moment";

import ChartCard from "../components/ChartCard";

import CompletedLeadsCountPerUserPerWeekChart from "../charts/CompletedLeadsCountPerUserPerWeekChart";
import CompletedLeadsCountPerUserPerMonthChart from "../charts/CompletedLeadsCountPerUserPerMonthChart";

function SalesStatistics() {
    return (
        <React.Fragment>
            <ChartCard title="Aantal leads verkocht per week">
                <CompletedLeadsCountPerUserPerWeekChart
                    startDate={ moment().subtract(1, "year").startOf("week") }
                    endDate={ moment() }
                />
            </ChartCard>
            <ChartCard title="Aantal leads verkocht per maand">
                <CompletedLeadsCountPerUserPerMonthChart
                    startDate={ moment().subtract(2, "year").startOf("month") }
                    endDate={ moment() }
                />
            </ChartCard>
        </React.Fragment>
    )
}

export default React.memo(SalesStatistics);
