import React from "react";

import Loading from "../../../components/Loading";

function ChartLoading() {
    return (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            <div>
                <Loading/>
            </div>
        </div>
    )
}

export default React.memo(ChartLoading);
